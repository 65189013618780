import React from "react";
import { Button, Typography } from "@mui/material";
import { FormatAlignCenter } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import styles from "../user-profile.module.scss";

interface Props {
  openModal: () => void;
}

export const AdviceForm: React.FC<Props> = ({ openModal }) => {
  const { t } = useTranslation();

  return (
    <Button className={`${styles.button} ${styles.row}`} onClick={openModal}>
      <FormatAlignCenter className={styles.icon} />
      <Typography variant="h5" color="text.primary" sx={{ textTransform: "none" }}>
        {t("advice.tellYourStory")}
      </Typography>
    </Button>
  );
};
