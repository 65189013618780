import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  AuthError,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FormTextField, ServiceCheckbox } from "components";
import { registerSchema, initialValues } from "./register-form.constants";
import { mapUserData } from "utils";
import { setToken, setUser, setUserDetails, resetUserStore } from "store";
import { RegisterData } from "./register-form.types";
import { FirebaseErrorType } from "pages/auth/auth.types";
import { FIREBASE_ERRORS } from "pages/auth/auth.constants";
import { ProviderLoginArguments } from "pages/auth/login/login-form/login-form.types";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { LANDING_PAGE, LOGIN_PAGE } from "constants/routes.constants";
import { auth } from "config/firebase.config";
import { providers } from "pages/auth/login/login-form/login-form.constants";
import { getUserDetails } from "firestore";
import { MAILCHIMP_URL } from "constants/app.constants";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import termsOfServiceDocument from "assets/docs/terms-of-service.pdf";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import privacyPolicyDocument from "assets/docs/privacy-policy.pdf";
import { ReactComponent as GoogleLogo } from "assets/icons/google-logo.svg";

import styles from "./register-form.module.scss";

interface Props {
  showVerifyScreen: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RegisterForm: React.FC<Props> = ({ showVerifyScreen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [termsOfService, setTermsOfService] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [newsletter, setNewsletter] = useState<boolean>(true);

  const handleRegister =
    (subscribe: (data: any) => void) =>
    (values: RegisterData, { setSubmitting }: FormikHelpers<RegisterData>) => {
      if (newsletter) {
        subscribe({ EMAIL: values.email, FNAME: values.name });
      }

      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then(({ user }) => {
          user
            .getIdToken()
            .then((idToken) => {
              const userData = mapUserData(user);
              dispatch(setToken(idToken));

              if (auth.currentUser) {
                updateProfile(auth.currentUser, { displayName: values.name })
                  .then(() => {
                    dispatch(setUser({ ...userData, displayName: values.name }));
                  })
                  .catch(() => {
                    dispatch(setUser(userData));
                  });
              }

              if (!user.emailVerified) {
                sendEmailVerification(user)
                  .then(() => {
                    signOut(auth).finally(() => {
                      showVerifyScreen();
                      dispatch(resetUserStore());
                      enqueueSnackbar(t("auth.register.linkSent"), { variant: "info" });
                    });
                  })
                  .catch(() => enqueueSnackbar(t("auth.register.linkError"), { variant: "error" }));
              } else {
                localStorage.removeItem(STORAGE_FIELDS.chatbot_email);
                localStorage.setItem(STORAGE_FIELDS.token, idToken);
                localStorage.setItem(STORAGE_FIELDS.refresh_token, user.refreshToken);

                navigate(LANDING_PAGE.path);
              }
            })
            .catch(() => enqueueSnackbar(t("auth.register.tokenError"), { variant: "error" }));
        })
        .catch((error: AuthError) => {
          const errorName = error.code as FirebaseErrorType;
          const message = FIREBASE_ERRORS(t)[errorName] || t("auth.register.error");

          enqueueSnackbar(message, { variant: "error" });
        });

      setSubmitting(false);
    };

  const handleProviderRegister =
    ({ authProvider, subscribe }: ProviderLoginArguments) =>
    () => {
      if (!termsOfService || !privacyPolicy) {
        enqueueSnackbar(t("common.acceptTermsOfService"), { variant: "warning" });
        return;
      }
      const provider = providers[authProvider];

      signInWithPopup(auth, provider)
        .then(({ user }) => {
          if (newsletter) {
            subscribe({ EMAIL: user.email, FNAME: user.displayName || "" });
          }

          user
            .getIdToken()
            .then((idToken) => {
              const userData = mapUserData(user);
              dispatch(setToken(idToken));
              dispatch(setUser(userData));

              getUserDetails(user.uid).then((details) => {
                const userDetails = details.data();

                if (userDetails) dispatch(setUserDetails(userDetails));
              });

              localStorage.setItem(STORAGE_FIELDS.token, idToken);
              localStorage.setItem(STORAGE_FIELDS.refresh_token, user.refreshToken);

              navigate(LANDING_PAGE.path);
            })
            .catch(() => {
              enqueueSnackbar(t("auth.register.tokenError"), { variant: "error" });
            });
        })
        .catch((error: AuthError) => {
          const errorName = error.code as FirebaseErrorType;
          const message = FIREBASE_ERRORS(t)[errorName] || t("auth.login.error");

          enqueueSnackbar(message, { variant: "error" });
        });
    };

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={({ subscribe }) => {
        return (
          <Formik
            initialValues={initialValues}
            onSubmit={handleRegister(subscribe)}
            validationSchema={registerSchema(t)}
          >
            {({ isSubmitting }) => (
              <Form className={styles.form}>
                <Typography
                  variant="h2"
                  fontWeight={600}
                  sx={{ textAlign: "center", mt: 8, mb: 3 }}
                  className={styles.title}
                >
                  {t("auth.register.title")}
                </Typography>
                <div className={styles.socialContainer}>
                  <button
                    onClick={handleProviderRegister({ authProvider: "google", subscribe })}
                    type="button"
                    disabled={isSubmitting}
                  >
                    <GoogleLogo className={styles.icon} />
                  </button>
                </div>
                <FormTextField name="name" label={t("auth.labels.name")} className={styles.input} />
                <FormTextField name="email" type="email" label={t("auth.labels.email")} className={styles.input} />
                <FormTextField
                  name="password"
                  label={t("auth.labels.password")}
                  type="password"
                  className={styles.input}
                />

                <ServiceCheckbox checked={termsOfService} onChange={setTermsOfService}>
                  <Typography variant="body2" sx={{ ml: -1, textAlign: "left" }}>
                    Oświadczam, że zapoznałam/em się z treścią{" "}
                    <strong>
                      <a
                        href={termsOfServiceDocument}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#000" }}
                      >
                        Regulaminu
                      </a>{" "}
                      i{" "}
                      <a
                        href={privacyPolicyDocument}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#000" }}
                      >
                        Polityki Prywatności
                      </a>
                    </strong>{" "}
                    oraz akceptuję ich postanowienia. <strong>*</strong>
                  </Typography>
                </ServiceCheckbox>
                <ServiceCheckbox checked={privacyPolicy} onChange={setPrivacyPolicy}>
                  <Typography variant="body2" sx={{ ml: -1, textAlign: "left" }}>
                    Wyrażam zgodę na przetwarzanie przez FemTechnology Group sp. z o.o. danych dotyczących zdrowia w
                    celu umożliwienia korzystania z usług dostępnych w ramach serwisu. <strong>*</strong>
                  </Typography>
                </ServiceCheckbox>
                <ServiceCheckbox checked={newsletter} onChange={setNewsletter}>
                  <Typography variant="body2" sx={{ ml: -1, textAlign: "left" }}>
                    Wyrażam zgodę na otrzymywanie na podany adres informacji handlowej od właściciela serwisu. Wyrażenie
                    zgody jest dobrowolne. Udzielona zgoda może być odwołana w każdym czasie. Szczegóły na temat
                    przetwarzania danych znajdują się w Polityce Prywatności.
                  </Typography>
                </ServiceCheckbox>
                <Typography variant="caption" sx={{ my: 2, textAlign: "left" }}>
                  Usługi i porady dostępne w naszej aplikacji, w tym korzystanie z artykułów i zasobów, nie mają na celu
                  zastąpienia porad udzielanych przez lekarza rodzinnego, lekarzy specjalistów, ani innej
                  profesjonalnych konsultacji medycznych. Nie należy podejmować lub powstrzymywać się od podejmowania
                  jakichkolwiek działań w oparciu o informacje zawarte w aplikacji bez uprzedniego zasięgnięcia porady
                  medycznej. W przypadku jakichkolwiek niepokojących objawów należy jak najszybciej skontaktować się z
                  lekarzem rodzinnym, lekarzem specjalistą, najbliższym szpitalem lub ze służbami ratunkowymi.
                </Typography>
                <div className={styles.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !privacyPolicy || !termsOfService}
                    className={styles.button}
                  >
                    {t("auth.register.button")}
                  </Button>
                  <Typography
                    className={styles.getStartedTitle}
                    color="text.secondary"
                    variant="body1"
                    fontWeight={300}
                  >
                    {t("auth.register.alreadyHaveAccount")}
                    <Link to={LOGIN_PAGE.path} className={styles.link}>
                      {t("auth.register.login")}
                    </Link>
                  </Typography>
                </div>
              </Form>
            )}
          </Formik>
        );
      }}
    />
  );
};
