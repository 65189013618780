import React, { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Typography, TextField, Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { Loader } from "components";
import { useFirebaseFetch, useShowContent, useUser } from "hooks";
import { getThreadReplies, postReply } from "firestore";
import { LOGIN_PAGE, LOGOUT_PAGE } from "constants/routes.constants";

import styles from "./replies.module.scss";

export const Replies: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, user } = useUser();
  const { threadId } = useParams<{ threadId: string }>();

  const [reply, setReply] = useState<string>("");

  const repliesData = useFirebaseFetch(() => getThreadReplies(threadId || ""));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(repliesData);
  const { data: replies, refresh } = repliesData;

  const sortedReplies = useMemo(() => {
    if (replies) {
      return replies.sort(
        (first, second) => new Date(second?.createdAt).getTime() - new Date(first?.createdAt).getTime(),
      );
    }

    return [];
  }, [replies]);

  const handleSubmit = async () => {
    if (reply && user && threadId) {
      try {
        await postReply({ user, text: reply, threadId });
        enqueueSnackbar(t("forum.replyAdded"), { variant: "success" });
        setReply("");
        refresh();
      } catch (error) {
        if (!reply) enqueueSnackbar(t("forum.fillReply"), { variant: "error" });
        if (!user) {
          enqueueSnackbar(t("forum.replyAuthError"), { variant: "error" });
          navigate(LOGOUT_PAGE.path);
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <Typography variant="body1" fontWeight={600} sx={{ mb: 3 }}>
        {t("forum.usersAnswers")}
      </Typography>

      {showLoader && <Loader />}
      {showNoContent && <Typography variant="caption">{t("forum.noContent")}</Typography>}
      {showError && <Typography variant="caption">{t("forum.fetchError")}</Typography>}

      {showContent && replies && (
        <section className={styles.content}>
          {sortedReplies.map(({ id, creator, text, createdAt }) => (
            <div key={id} className={styles.replyItem}>
              <Avatar src={creator?.photoUrl || ""} className={styles.avatar} />
              <div>
                <Typography variant="body1" fontSize={14} fontWeight={600}>
                  {creator?.displayName || `${creator?.name} ${creator.surname}`}
                </Typography>
                <Typography variant="body1">{text}</Typography>
              </div>
              <Typography variant="caption" className={styles.date}>
                {new Date(createdAt).toLocaleString()}
              </Typography>
            </div>
          ))}
        </section>
      )}

      {!showError && isAuthenticated && (
        <div className={styles.inputOuterContainer}>
          <Typography variant="caption">{t("forum.writeYourReply")}</Typography>
          <div className={styles.inputContainer}>
            <TextField
              name="text"
              fullWidth
              multiline
              minRows={6}
              maxRows={6}
              value={reply}
              InputProps={{ sx: { backgroundColor: "#fff" } }}
              onChange={(event) => setReply(event.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={!reply}
              sx={{ color: "#fff", height: "60px" }}
              onClick={handleSubmit}
            >
              {t("common.send")}
            </Button>
          </div>
        </div>
      )}

      {!showError && !isAuthenticated && (
        <div className={styles.inputOuterContainer}>
          <Typography variant="caption">{t("forum.logInToReply")}</Typography>
          <div className={styles.inputContainer}>
            <Link to={LOGIN_PAGE.path}>
              <Button type="submit" variant="contained" sx={{ color: "#fff", height: "60px" }} onClick={handleSubmit}>
                {t("auth.login.button")}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
