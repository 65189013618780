import React from "react";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getCategories } from "server";
import { useShowContent } from "hooks";
import { RootState } from "store";
import { Loader } from "components/client/loader/loader";
import { getLinkPath } from "utils";
import { BLOG_CATEGORY_PAGE } from "constants/routes.constants";
import { ArticlesCategoriesResultsProps } from "./articles-categories-results.types";

import styles from "../global-search.module.scss";

export const ArticlesCategoriesResults: React.FC<ArticlesCategoriesResultsProps> = ({ searchValue }) => {
  const { t } = useTranslation();
  const { locale } = useSelector((state: RootState) => state.app);

  const categoriesData = useFetch(
    getCategories.setQueryParams({ populate: "*", locale, "filters[name][$containsi]": searchValue || "" }),
    { dependencies: [locale] },
  );
  const { showContent, showLoader, showNoContent, showError } = useShowContent(categoriesData);
  const { data: categories } = categoriesData;

  return (
    <div>
      <Typography className={styles.title}>{t("common.categories")}</Typography>
      {showLoader && <Loader />}
      {showNoContent && <Typography className={styles.sectionParagraph}>{t("common.noResults")}</Typography>}
      {showError && <Typography className={styles.sectionParagraph}>{t("common.fetchError")}</Typography>}
      {showContent &&
        categories?.data.map((category) => (
          <div key={category.id} className={styles.sectionWrapper}>
            <Link to={getLinkPath(BLOG_CATEGORY_PAGE.path, { categoryId: category.id })} className={styles.link}>
              {category.attributes.name}
            </Link>
          </div>
        ))}
    </div>
  );
};
