import React, { useState } from "react";

type CalendarContextType = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

export const CalendarContext = React.createContext<CalendarContextType>({
  selectedDate: new Date(),
  setSelectedDate: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const CalendarContextProvider: React.FC<Props> = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values: CalendarContextType = {
    selectedDate,
    setSelectedDate,
  };

  return <CalendarContext.Provider value={values}>{children}</CalendarContext.Provider>;
};
