import { TFunction } from "i18next";
import { object, SchemaOf, string, ref } from "yup";

import { passwordValidation } from "utils";
import { ConfirmPasswordResetData } from "./confirm-password-reset.types";

export const resetPasswordValues: ConfirmPasswordResetData = {
  password: "",
  repeatedPassword: "",
};

export const resetPasswordSchema = (t: TFunction): SchemaOf<ConfirmPasswordResetData> =>
  object().shape({
    password: string()
      .test("password", t("auth.validation.passwordRules"), passwordValidation(t))
      .required(t("auth.validation.passwordRequired")),
    repeatedPassword: string()
      .required(t("auth.validation.passwordRequired"))
      .oneOf([ref("password"), null], t("auth.validation.passwordShouldMatch")),
  });
