import React from "react";

import { Stripe } from "./stripe/stripe";
import { PaymentProps } from "./payment.types";
import { STRIPE_GATEWAY } from "./payment.constants";

import styles from "./payment.module.scss";

export const Payment: React.FC<PaymentProps> = ({ paymentMethod, changeStep }) => {
  return (
    <div className={styles.container}>{paymentMethod === STRIPE_GATEWAY && <Stripe changeStep={changeStep} />}</div>
  );
};
