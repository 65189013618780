import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";

import { ConfirmModalTypes } from "./confirm-modal.types";

import styles from "./confirm-modal.module.scss";

export const ConfirmModal: React.FC<ConfirmModalTypes> = ({
  accept,
  cancel,
  isOpen,
  title,
  description,
  children,
  acceptButtonText,
}: ConfirmModalTypes) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={cancel} aria-describedby="alert-dialog-description">
      <div className={styles.container}>
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
          {title}
        </DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {children && <Grid container>{children}</Grid>}
          <Grid container justifyContent="flex-end">
            <Button onClick={cancel}>{t("common.close")}</Button>
            <Button onClick={accept} variant="contained" autoFocus sx={{ color: "#fff" }}>
              {acceptButtonText || t("common.confirm")}
            </Button>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
};
