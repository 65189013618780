import React, { useRef, useState } from "react";
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import { buttonSx, frameOfMind } from "./home-header.constants";
import { RootState } from "store";
import { MY_MENO_PAGE } from "constants/routes.constants";

import styles from "./home-header.module.scss";

export const HomeHeader: React.FC = () => {
  const scrollerRef = useRef<HTMLElement>(null);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);

  const [isMouseDown, setMouseDown] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (scrollerRef.current) {
      setStartX(event.pageX - scrollerRef.current.offsetLeft);
      setMouseDown(true);

      setScrollLeft(scrollerRef.current.scrollLeft);
    }
  };

  const onMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setMouseDown(false);
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (isMouseDown && scrollerRef.current) {
      const x = event.pageX - scrollerRef.current.offsetLeft;
      const scroll = x - startX;
      scrollerRef.current.scrollLeft = scrollLeft - scroll;
    }
  };

  const completeReport = () => {
    enqueueSnackbar(t("home.completeYourReport"), { variant: "info" });
  };

  const userName = user?.displayName?.split(" ")[0];

  if (!user) return null;

  return (
    <div className={styles.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <div>
          <Typography variant="h3" fontWeight={400} color="text.primary" className={styles.title}>
            {userName ? t("home.welcomeNameTitle", { name: userName }) : t("home.welcomeTitle")}
          </Typography>
          <Typography variant="h3" fontWeight={600} color="text.primary" className={styles.title}>
            {t("home.welcomeQuestion")}
          </Typography>
        </div>
        <div className={styles.menoRow}>
          <Typography variant="h6" color="#000">
            {t("home.myMeno")}
          </Typography>
          <Link to={MY_MENO_PAGE.path}>
            <IconButton sx={buttonSx}>
              <ArrowForward />
            </IconButton>
          </Link>
        </div>
      </Grid>
      <section
        aria-hidden
        className={styles.horizontalScroll}
        ref={scrollerRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
      >
        {frameOfMind(t).map(({ name, image }) => (
          <Link key={name} to={MY_MENO_PAGE.path} onClick={completeReport}>
            <Card className={styles.card}>
              <img src={image} alt="frame of mind" className={styles.image} />
              <Typography>{name}</Typography>
            </Card>
          </Link>
        ))}
      </section>
    </div>
  );
};
