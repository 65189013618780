import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { Content, Loader, NoContent } from "components";
import { Categories } from "./categories/categories";
import { CategoryArticles } from "./category-articles/category-articles";
import { NewArticles } from "./new-articles/new-articles";
import { RootState, setCategories } from "store";
import { getCategories } from "server";

import styles from "./post-list.module.scss";

export const PostListPage: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { locale } = useSelector((state: RootState) => state.app);
  const { categories } = useSelector((state: RootState) => state.categories);

  const [isLoading, setLoading] = useState<boolean>(false);

  const { submit, onSubmitRequestStart, onSubmitSuccess, onSubmitError } = useSubmit(
    getCategories.setQueryParams({ populate: "*", locale }),
  );
  onSubmitRequestStart(() => setLoading(true));
  onSubmitSuccess(({ data }) => {
    dispatch(setCategories(data));
    setLoading(false);
  });
  onSubmitError(() => setLoading(false));

  useDidUpdate(() => {
    if (!categories.length) {
      submit();
    }
  }, [categories]);

  const showLoader = Boolean(isLoading);
  const showNoContent = !isLoading && categories.length === 0;
  const showContent = !isLoading && categories.length > 0;

  return (
    <Content>
      {showLoader && <Loader height="100%" />}
      {showNoContent && (
        <>
          <Typography variant="h3" fontWeight={600} sx={{ mb: 3 }}>
            {t("blog.categories")}
          </Typography>
          <div className={styles.emptyStateWrapper}>
            <NoContent />
          </div>
        </>
      )}
      {showContent && (
        <>
          <Categories categories={categories} />
          <NewArticles />
          {categories.map((category) => (
            <CategoryArticles key={category.id} category={category} />
          ))}
        </>
      )}
    </Content>
  );
};
