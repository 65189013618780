import React, { SyntheticEvent, useState } from "react";
import classNames from "classnames";
import { Typography, IconButton } from "@mui/material";
import { PhoneOutlined, MailOutlined } from "@mui/icons-material";
import { useWindowSize } from "@better-typed/react-window-hooks";

import { DoctorDetails } from "../details/doctor-details";
import { DoctorItemProps } from "./doctor-item.types";

import styles from "./doctor-item.module.scss";

export const DoctorItem: React.FC<DoctorItemProps> = ({ doctor, showBackground }) => {
  const [width] = useWindowSize();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const handleDetailsOpen = (isOpen: boolean) => () => setShowDetails(isOpen);

  const name = (
    <Typography fontWeight={600} className={styles.name}>
      {doctor.attributes.name} {doctor.attributes.surname}
    </Typography>
  );

  const specialization = (
    <Typography variant="caption" color="text.secondary" className={styles.rightAlign}>
      {doctor.attributes.specialization}
    </Typography>
  );

  const address = (
    <Typography variant="body2" className={styles.rightAlign}>
      {doctor.attributes.address} {doctor.attributes.city} - {doctor.attributes.voivodeship}
    </Typography>
  );

  const handleIconClick = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  const icons = (
    <div className={styles.icons}>
      <a href={`mailto:${doctor.attributes.email}`} onClick={handleIconClick}>
        <IconButton>
          <MailOutlined className={styles.icon} sx={{ color: "text.disabled" }} />
        </IconButton>
      </a>
      <a href={`tel:${doctor.attributes.phone}`} onClick={handleIconClick}>
        <IconButton>
          <PhoneOutlined className={styles.icon} sx={{ color: "text.disabled" }} />
        </IconButton>
      </a>
    </div>
  );

  if (width < 576) {
    return (
      <div className={`${styles.mobileContainer} ${showBackground && styles.coloredBackground}`}>
        {name}
        {specialization}
        <Typography>
          {doctor.attributes.address} {doctor.attributes.city}
        </Typography>
        <Typography className={styles.rightAlign}>{doctor.attributes.voivodeship}</Typography>
        <div />
        {icons}
      </div>
    );
  }

  const containerClassname = classNames({
    [styles.largeMobileContainer]: width >= 576 && width < 768,
    [styles.tabletContainer]: width >= 768 && width < 1024,
    [styles.largeTabletContainer]: width >= 1024 && width < 1280,
    [styles.desktopContainer]: width >= 1280,
    [styles.coloredBackground]: showBackground,
  });

  return (
    <>
      <button type="button" className={containerClassname} onClick={handleDetailsOpen(true)}>
        {name}
        {specialization}
        {address}
        {icons}
      </button>
      <DoctorDetails isOpen={showDetails} handleClose={handleDetailsOpen(false)} doctor={doctor} />
    </>
  );
};
