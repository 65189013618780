import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos, CalendarToday } from "@mui/icons-material";

import { MonthCalendar } from "../month/month-calendar";
import { CalendarContext } from "../context/calendar.context";
import { getWeekDetails } from "./week-calendar.constants";
import { CalendarProps } from "./week-calendar.types";
import { useFirebaseFetch, useUser } from "hooks";
import { Day } from "../day/day";
import { weekdaysShort } from "constants/date.constants";
import { getUserReports, getDateFromReportId, getUserMenstruations } from "firestore";
import { checkIfReportCompleted, getMenstruationDetails } from "../calendar.constants";

import styles from "./week-calendar.module.scss";

export const WeekCalendar: React.FC<CalendarProps> = ({ weeklyReportsUpdater, weeklyMenstruationUpdater }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { selectedDate, setSelectedDate } = useContext(CalendarContext);

  const [offset, setOffset] = useState<number>(0);
  const [isFullCalendarOpen, setFullCalendarOpen] = useState<boolean>(false);

  const { weekStartDate, weekEndDate, weekOfYearNumber, weekDays } = useMemo(
    () => getWeekDetails(selectedDate, offset),
    [offset, selectedDate],
  );

  const { data: userReports } = useFirebaseFetch(() => getUserReports(user?.id || "", weekStartDate, weekEndDate), {
    dependencies: [weekStartDate, weekEndDate, weeklyReportsUpdater],
  });

  const { data: menstruations } = useFirebaseFetch(
    () => getUserMenstruations(user?.id || "", weekStartDate, weekEndDate),
    {
      dependencies: [weekStartDate, weekEndDate, weeklyMenstruationUpdater],
    },
  );

  // dates with completed reports
  const completedReportDates = useMemo(
    () => userReports?.map((report) => getDateFromReportId(report.id)) || [],
    [userReports],
  );

  const handleNextWeekClick = () => setOffset((previousOffset) => previousOffset + 1);
  const handlePreviousWeekClick = () => setOffset((previousOffset) => previousOffset - 1);

  const onDateSelect = () => setOffset(0);

  const handleCalendarOpen = (isOpen: boolean) => () => setFullCalendarOpen(isOpen);
  const onDateClick = (date: Date) => () => {
    setSelectedDate(date);
    setOffset(0);
  };

  const getMenstruation = (date: Date) => getMenstruationDetails(t, date, menstruations || []);
  const checkIsCompleted = (date: Date) => checkIfReportCompleted(date, completedReportDates);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
          {selectedDate.toLocaleDateString()}
        </Typography>
        <div className={styles.weekOfYear}>
          <Typography variant="caption" fontWeight={600}>
            {weekStartDate.toLocaleDateString()} - {weekEndDate.toLocaleDateString()},{"  "}
          </Typography>
          <Typography variant="caption">{t("myMeno.weekOfYear", { week: weekOfYearNumber })}</Typography>
        </div>
        <div>
          <IconButton onClick={handleCalendarOpen(true)} sx={{ mr: 1 }}>
            <CalendarToday sx={{ width: "30px" }} />
          </IconButton>
          <IconButton onClick={handlePreviousWeekClick}>
            <ArrowBackIos sx={{ width: "20px" }} />
          </IconButton>
          <IconButton onClick={handleNextWeekClick}>
            <ArrowForwardIos sx={{ width: "20px" }} />
          </IconButton>
        </div>
      </div>
      <div className={styles.days}>
        {weekdaysShort(t).map((day) => (
          <Typography variant="body1" fontSize={14} key={day} sx={{ textAlign: "center" }}>
            {day}
          </Typography>
        ))}
      </div>

      <div className={styles.container}>
        {weekDays.map((date) => (
          <Day
            key={date.toLocaleString()}
            date={date}
            onDayClick={onDateClick}
            menstruationDetails={getMenstruation(date)}
            isReportCompleted={checkIsCompleted(date)}
          />
        ))}
      </div>
      <MonthCalendar isOpen={isFullCalendarOpen} handleClose={handleCalendarOpen(false)} onDateSelect={onDateSelect} />
    </div>
  );
};
