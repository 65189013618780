import React from "react";
import { useSelector } from "react-redux";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Loader } from "components";
import { getArticles } from "server";
import { getLinkPath, getPaginationParams } from "utils";
import { useShowContent } from "hooks";
import { RootState } from "store";
import { ArticlesResultsProps } from "./articles-results.types";
import { BLOG_POST_PAGE } from "constants/routes.constants";

import styles from "../global-search.module.scss";

export const ArticlesResults: React.FC<ArticlesResultsProps> = ({ searchValue, resetState }) => {
  const { t } = useTranslation();
  const { locale } = useSelector((state: RootState) => state.app);

  const articlesData = useFetch(
    getArticles.setQueryParams({
      "filters[title][$containsi]": searchValue || "",
      populate: "*",
      sort: "createdAt:desc",
      locale,
      ...getPaginationParams({ page: 1, pageSize: 25 }),
    }),
    {
      dependencies: [searchValue, locale],
    },
  );
  const { showContent, showNoContent, showError, showLoader } = useShowContent(articlesData);
  const { data: articles } = articlesData;

  return (
    <div>
      <Typography className={styles.title}>{t("common.articles")}</Typography>
      {showLoader && <Loader height="50px" loaderSize="24px" />}
      {showNoContent && <Typography className={styles.sectionParagraph}>{t("common.noResults")}</Typography>}
      {showError && <Typography className={styles.sectionParagraph}>{t("common.fetchError")}</Typography>}
      {showContent &&
        articles?.data.map((article) => (
          <div key={article.id} className={styles.sectionWrapper}>
            <Link
              to={getLinkPath(BLOG_POST_PAGE.path, { articleId: article.id })}
              onClick={resetState}
              className={styles.link}
            >
              {article.attributes.title}
            </Link>
          </div>
        ))}
    </div>
  );
};
