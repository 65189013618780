import { TFunction } from "i18next";
import { eachDayOfInterval, endOfMonth, startOfMonth, getDay } from "date-fns";

import { SelectOption } from "components/form/select/select.types";

export const JANUARY_MONTH_INDEX = 0;
export const DECEMBER_MONTH_INDEX = 11;

export const getMonthDetails = (date: Date) => {
  const monthStartDate = startOfMonth(date);
  const monthEndDate = endOfMonth(date);

  const daysOfMonth = eachDayOfInterval({ start: monthStartDate, end: monthEndDate });

  return { monthStartDate, monthEndDate, daysOfMonth };
};

export const getWeekDayIndex = (date: Date) => {
  const dayIndex = getDay(date);

  /* day of the week is returned 0 -> sunday, 6 -> saturday
   * we want 1 to be monday, and 7 to be sunday */

  if (dayIndex === 0) {
    return 7;
  }
  return dayIndex;
};

export const getMonthsOptions = (t: TFunction): SelectOption[] => [
  { name: t("common.months.january"), value: 0 },
  { name: t("common.months.february"), value: 1 },
  { name: t("common.months.march"), value: 2 },
  { name: t("common.months.april"), value: 3 },
  { name: t("common.months.may"), value: 4 },
  { name: t("common.months.june"), value: 5 },
  { name: t("common.months.july"), value: 6 },
  { name: t("common.months.august"), value: 7 },
  { name: t("common.months.september"), value: 8 },
  { name: t("common.months.october"), value: 9 },
  { name: t("common.months.november"), value: 10 },
  { name: t("common.months.december"), value: 11 },
];

const currentYear = new Date().getFullYear();

export const yearOptions: SelectOption[] = [
  { name: currentYear.toString(), value: currentYear },
  { name: (currentYear - 1).toString(), value: currentYear - 1 },
  { name: (currentYear - 2).toString(), value: currentYear - 2 },
  { name: (currentYear - 3).toString(), value: currentYear - 3 },
];
