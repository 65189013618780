import React from "react";
import { Box, IconButton, Modal as ModalComponent } from "@mui/material";
import { Close } from "@mui/icons-material";

import { ModalProps } from "./modal.types";
import { style } from "./modal.constants";

import styles from "./modal.module.scss";

export const Modal: React.FC<ModalProps> = ({ isOpen, handleClose, children }) => {
  return (
    <ModalComponent
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} className={styles.box}>
        <IconButton onClick={handleClose} className={styles.close}>
          <Close className={styles.icon} sx={{ color: "text.primary" }} />
        </IconButton>
        {children}
      </Box>
    </ModalComponent>
  );
};
