import React, { useMemo, useRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Card, Grid, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";

import { StoreCardProps } from "./single-card.types";
import { environment } from "config";

import styles from "./single-card.module.scss";

export const SingleCard: React.FC<StoreCardProps> = ({ path, title, image, imageClassName, text, className }) => {
  const titleRef = useRef<HTMLDivElement>(null);

  const PADDING_WITH_GAP_HEIGHT = 36;

  const titleHeight = useMemo(() => {
    if (titleRef.current) return titleRef.current.clientHeight + PADDING_WITH_GAP_HEIGHT;
    return 42 + PADDING_WITH_GAP_HEIGHT;
  }, [titleRef.current]);

  const transformLinkUrl = (url: string) => {
    const urlPattern = /^((http|https|ftp):\/\/)/;

    if (urlPattern.test(url)) {
      return url;
    }

    return `${environment.serverUrl}${url}`;
  };

  const titleItem = (
    <Grid container alignItems="center" sx={{ mb: 2, mt: 0, gap: "12px" }} ref={titleRef}>
      <Typography variant="h5" fontWeight={600} className={styles.title}>
        {title}
      </Typography>
      <ArrowForward sx={{ ml: 1 }} />
    </Grid>
  );

  return (
    <Link to={path} className={classNames(styles.wrapper, className)}>
      <Card className={styles.container}>
        {titleItem}
        <div className={styles.content} style={{ height: `calc(100% - ${titleHeight}px)` }}>
          {image && <img src={image} alt="card" className={classNames(styles.image, imageClassName)} />}
          <Grid container>
            <div className={styles.textContainer}>
              <ReactMarkdown transformLinkUri={transformLinkUrl}>{text}</ReactMarkdown>
            </div>
          </Grid>
        </div>
        <div className={styles.bottomBar} />
      </Card>
    </Link>
  );
};
