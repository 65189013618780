import React from "react";
import classNames from "classnames";

import { Navbar } from "components";
import { ContentProps } from "./content.types";

import styles from "./content.module.scss";

export const Content: React.FC<ContentProps> = ({ children, showSearch = true, className }) => {
  return (
    <div className={styles.wrapperWithSearch}>
      <Navbar showSearch={showSearch} />
      <main className={classNames(styles.containerWithSearch, className)}>{children}</main>
    </div>
  );
};
