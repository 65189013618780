import React, { useContext } from "react";
import { useFormikContext } from "formik";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormTextField } from "components";
import { CheckoutAddressValues } from "../address.types";
import { addressInitialValues, inputStyles } from "../address.constants";
import { ShippingAddressProps } from "./shipping-address.types";
import { CheckoutContext } from "context";

import styles from "../address.module.scss";

export const ShippingAddress: React.FC<ShippingAddressProps> = ({ checkout }) => {
  const { t } = useTranslation();
  const { useBillingAsShipping, setUseBillingAsShipping } = useContext(CheckoutContext);
  const { values, setFieldValue } = useFormikContext<CheckoutAddressValues>();

  const copyBillingDetails = () => {
    const billingDetails = values.billingAddress;

    setFieldValue("shippingAddress", billingDetails);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, selected: boolean) => {
    if (selected) {
      copyBillingDetails();
    } else {
      setFieldValue("shippingAddress", addressInitialValues(checkout?.shippingAddress));
    }
    setUseBillingAsShipping(selected);
  };

  return (
    <div className={styles.column}>
      <Typography variant="h4" fontWeight={700} sx={{ mt: 4, mb: 1 }}>
        {t("checkout.shippingDetailsTitle")}
      </Typography>
      <FormControlLabel
        sx={{ mb: 2 }}
        control={<Checkbox checked={useBillingAsShipping} onChange={handleCheckboxChange} />}
        label={<Typography variant="body1">{t("checkout.useBillingDetails")}</Typography>}
      />
      {useBillingAsShipping && <Typography variant="body1">{t("checkout.sameAsBilling")}</Typography>}
      {!useBillingAsShipping && (
        <>
          <div className={styles.grid}>
            <FormTextField
              name="shippingAddress.firstName"
              label={t("checkout.labels.firstName")}
              fullWidth
              disabled={useBillingAsShipping}
              inputProps={{ style: inputStyles }}
            />
            <FormTextField
              name="shippingAddress.lastName"
              label={t("checkout.labels.lastName")}
              fullWidth
              disabled={useBillingAsShipping}
              inputProps={{ style: inputStyles }}
            />
          </div>
          <FormTextField
            name="shippingAddress.companyName"
            label={t("checkout.labels.companyName")}
            disabled={useBillingAsShipping}
            inputProps={{ style: inputStyles }}
          />
          <FormTextField
            name="shippingAddress.phone"
            label={t("checkout.labels.phone")}
            disabled={useBillingAsShipping}
            inputProps={{ style: inputStyles }}
          />
          <FormTextField
            name="shippingAddress.streetAddress1"
            label={t("checkout.labels.address1")}
            disabled={useBillingAsShipping}
            inputProps={{ style: inputStyles }}
          />
          <FormTextField
            name="shippingAddress.streetAddress2"
            label={t("checkout.labels.address2")}
            disabled={useBillingAsShipping}
            inputProps={{ style: inputStyles }}
          />
          <div className={styles.grid}>
            <FormTextField
              name="shippingAddress.city"
              label={t("checkout.labels.city")}
              disabled={useBillingAsShipping}
              inputProps={{ style: inputStyles }}
            />
            <FormTextField
              name="shippingAddress.postalCode"
              label={t("checkout.labels.postalCode")}
              disabled={useBillingAsShipping}
              inputProps={{ style: inputStyles }}
            />
          </div>
        </>
      )}
    </div>
  );
};
