import React from "react";
import { Avatar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ThreadModel } from "models";
import { getLinkPath } from "utils";
import { FORUM_THREAD_DETAILS_PAGE } from "constants/routes.constants";

import styles from "./thread-item.module.scss";

interface Props {
  thread: ThreadModel;
}

export const ThreadItem: React.FC<Props> = ({ thread }) => {
  const { t } = useTranslation();

  const { creator, title, description, createdAt, category } = thread;
  const name = creator?.displayName || `${creator?.name} ${creator?.surname}`;

  return (
    <Link to={getLinkPath(FORUM_THREAD_DETAILS_PAGE.path, { threadId: thread.id })}>
      <div className={styles.container}>
        <Avatar src={creator?.photoUrl || ""} className={styles.avatar} />
        <div className={styles.column}>
          <Typography variant="h5" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary" fontSize={14} className={styles.text}>
            {description}
          </Typography>
          <Typography variant="caption" fontWeight={500}>
            {t("forum.category")}: <span>{category}</span>
          </Typography>
          <div className={styles.row}>
            <Typography variant="caption" fontWeight={600}>
              {name}
            </Typography>
            <Typography variant="caption">{new Date(createdAt).toLocaleString()}</Typography>
          </div>
        </div>
      </div>
    </Link>
  );
};
