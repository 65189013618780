export const forum = {
  availableSoon: "Form will be available soon!",

  createThreadSuccess: "New thread has been created",
  createThreadError: "Could not create a new forum thread",
  createThreadAuthError: "You are not logged in or your data could not be read. Please log in again.",

  forum: "Forum",
  createThreadTitle: "Create a new forum thread",
  threadTitle: "Title",
  threadContent: "Thread content",

  goBack: "Go back to forum",

  category: "Category",

  replyAdded: "Reply has been added",
  replyAuthError: "Could not get user details. Please log again.",
  fillReply: "Fill in your reply field",
  usersAnswers: "User's answers",
  noContent: "There are no replies yet.",
  fetchError: "Could not load replies of this thread.",
  writeYourReply: "Write your reply",

  logInToReply: "Log in to reply",

  deleteTitle: "Do you really want to delete this thread?",
  deleteDescription:
    "This thread with all replies will be deleted and you will not be able to undo this. Do you want to continue?",
};
