import React from "react";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ArticleCard, Loader, Content, NoContent, FetchingError } from "components";
import { prepareStrapiFilters } from "utils";
import { useShowContent } from "hooks";
import { RootState } from "store";
import { getArticles } from "server";

import styles from "./favourite.module.scss";

export const FavouritePage: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useSelector((state: RootState) => state.app);
  const { posts } = useSelector((state: RootState) => state.favourites);

  const articlesData = useFetch(
    getArticles.setQueryParams(prepareStrapiFilters(posts, { populate: "*", locale, sort: "createdAt:desc" })),
  );
  const { showContent, showNoContent, showError, showLoader } = useShowContent(articlesData);
  const { data: articles } = articlesData;

  const noContent = showNoContent || !posts.length;
  const displayContent = showContent && Boolean(posts.length);

  return (
    <Content>
      <Typography variant="h3" fontWeight={600} sx={{ mb: 4 }}>
        {t("favourites.title")}
      </Typography>
      {noContent && (
        <div className={styles.emptyStateWrapper}>
          <NoContent title={t("favourites.noFavourites")} />
        </div>
      )}
      {showError && (
        <div className={styles.emptyStateWrapper}>
          <FetchingError title={t("favourites.fetchingError")} />
        </div>
      )}
      {showLoader && <Loader />}

      {displayContent && (
        <section className={styles.container}>
          {articles?.data.map((article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </section>
      )}
    </Content>
  );
};
