import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LOGIN_PAGE } from "constants/routes.constants";

import styles from "../register-form/register-form.module.scss";

export const RegisterVerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onButtonClick = () => navigate(LOGIN_PAGE.path);

  return (
    <div className={styles.verifyContainer}>
      <div className={styles.verifyContent}>
        <Typography variant="h2" fontWeight={600} sx={{ textAlign: "center", mt: 8, mb: 3 }}>
          {t("auth.register.verifyYourEmail")}
        </Typography>
        <Typography variant="body1">{t("auth.register.verifyEmailDescription")}</Typography>
        <Button type="button" variant="contained" onClick={onButtonClick} sx={{ color: "#fff", mt: 4 }}>
          {t("auth.register.goToLogin")}
        </Button>
      </div>
    </div>
  );
};
