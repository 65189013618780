import { isSameDay } from "date-fns";
import { TFunction } from "i18next";

import { MenstruationResponse } from "firestore";
import { MenstruationDetails } from "./calendar.types";
import { colorOptions, intensityOptions } from "pages/user/my-meno/menstruation/menstruation-form.constants";

export const checkIfReportCompleted = (date: Date, completedReportDates: Date[]): boolean => {
  const completedReportFromCurrentDate = completedReportDates.find((completedDate) => isSameDay(date, completedDate));

  return Boolean(completedReportFromCurrentDate);
};

export const getMenstruationDetails = (
  t: TFunction,
  date: Date,
  menstruations: MenstruationResponse[],
): MenstruationDetails | null => {
  const foundMenstruation = menstruations.find((menstruation) => {
    const menstruationDate = new Date(menstruation.date.seconds * 1000);

    return isSameDay(menstruationDate, date);
  });

  if (!foundMenstruation) return null;

  const foundIntensity = intensityOptions(t).find((intensity) => intensity.id === foundMenstruation?.intensity);

  if (!foundIntensity) return null;

  const foundColor =
    colorOptions(t).find((color) => color.value === foundMenstruation.color)?.label || foundMenstruation.color;

  return {
    intensityName: foundIntensity.name,
    intensityId: foundIntensity.id,
    color: foundColor,
  };
};
