import { builder } from "server/builder";
import { AdviceModel } from "models";

export const createAdviceForm = builder.createCommand<
  unknown,
  { data: AdviceModel & { publishedAt: string | null; email: string } }
>()({
  method: "POST",
  endpoint: "/api/forms",
});
