export const auth = {
  support: "wspieramy",
  in: "w",
  change: "zmianie",
  login: {
    title: "Witaj ponownie!",
    signUp: "Zarejestruj się",
    button: "Zaloguj się",
    pageDescription: "Nie masz konta?",
    register: "Zarejestruj się",
    googleLogin: "Zaloguj się z Google",
    error: "Nie udało się zalogować",
    tokenError: "Nie udało się zweryfikować użytkownika",
    forgotPassword: "Zapomniałaś hasła?",
    reset: "Zresetuj!",
    google: "Zaloguj się kontem Google",
    verifyYourEmail: "Aktywuj swoje konto",
    verifyEmailDescription:
      "Na twoją skrzynkę pocztową wysłaliśmy email z aktywacją twojego konta. Postępuj zgodnie z instrukcjami aby aktywować swoje konto",
    tryAgain: "Spróbuj ponownie",
  },
  register: {
    title: "Stwórz konto",
    login: "Zaloguj się!",
    alreadyHaveAccount: "Posiadasz już konto?",
    button: "Zarejestruj się",
    error: "Nie udało się zalogować",
    tokenError: "Nie udało się zweryfikować użytkownika",
    verifyYourEmail: "Zweryfikuj swój adres email",
    verifyEmailDescription:
      "Na twój adres email wysłaliśmy link z aktywacją do twojego konta. Postępuj zgodnie z instrukcjami w mailu aby aktywować swoje konto.",
    goToLogin: "Przejdź do logowania",
    linkSent: "Link aktywacyjny został wysłany",
    linkError: "Wystąpił problem z wysłaniem linku aktywacyjnego",
  },
  resetPassword: {
    title: "Zresetuj swoje hasło",
    description: "Email zostanie wysłany na twoją skrzynkę pocztową",
    send: "Wyślij!",
    rememberPassword: "Pamiętasz swoje hasło?",
    logIn: "Zaloguj się!",

    resetSuccess: "Email został wysłany na podany adres",
    resetError: "Nie udało się wysłać wiadomości na podany adres",
  },
  labels: {
    email: "Email",
    password: "Hasło",
    name: "Imię",
    surname: "Nazwisko",
  },
  validation: {
    nameRequired: "Imię jest wymagane",
    surnameRequired: "Nazwisko jest wymagane",
    invalidEmail: "Email jest niepoprawny",
    emailRequired: "Email jest wymagany",
    passwordRequired: "Hasło jest wymagane",
    passwordRules: "Hasło musi zawierać litery wielkie oraz małe litery, numer oraz znak specjalny",
    passwordShouldMatch: "Hasła muszą być takie same",
    nameMin2: "Imię powinno zawierać przynajmniej dwa znaki",
    passwordMin6: "Hasło powinno zawierać przynajmniej 6 znaków",
    passwordUpperLower: "Hasło powinno zawierać małe oraz wielkie litery.",
    passwordNumeric: "Hasło powinno zawierać przynajmniej jeden znak numeryczny.",
    passwordSpecial: "Hasło powinno zawierać przynajmniej jeden znak specjalny.",
  },
  error: {
    emailNotFound: "Konto o podanym adresie e-mail nie istnieje",
    invalidPassword: "Hasło do konta jest nieprawidłowe",
    emailExists: "Konto o podanym adresie e-mail już istnieje",
  },
};
