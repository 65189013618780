import { TFunction } from "i18next";
import { object, SchemaOf, string } from "yup";

import { DoctorValues } from "models";
import { PL_NUMBER_REGEX } from "pages/checkout/address/address.constants";

export const createDoctorValues: DoctorValues = {
  name: "",
  surname: "",
  address: "",
  city: "",
  email: "",
  website: "",
  voivodeship: "",
  specialization: "",
  phone: "",
  opinion: "",
};

export const doctorSchema = (t: TFunction): SchemaOf<DoctorValues> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  object().shape({
    name: string().required(t("doctors.required")),
    surname: string().required(t("doctors.required")),
    address: string().required(t("doctors.required")),
    city: string().required(t("doctors.required")),
    email: string().email(t("doctors.incorrectEmail")).required(t("doctors.required")),
    website: string().required(t("doctors.required")),
    voivodeship: string().required(t("doctors.required")),
    specialization: string().required(t("doctors.required")),
    phone: string().required(t("doctors.required")).matches(PL_NUMBER_REGEX, t("doctors.incorrectPhone")),
    opinion: string().max(1000),
  });
