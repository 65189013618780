import { FunctionComponent } from "react";
import classNames from "classnames";
import { isSameDay, isToday } from "date-fns";
import { TFunction } from "i18next";

import { Intensity } from "models";

import { ReactComponent as Drop } from "assets/icons/drop.svg";
import { ReactComponent as DoubleDrop } from "assets/icons/double-drop.svg";
import { ReactComponent as TripleDrop } from "assets/icons/triple-drop.svg";

import styles from "pages/user/my-meno/calendar/calendar.module.scss";

export const intensityIcons: Record<Intensity, FunctionComponent> = {
  0: Drop,
  1: Drop,
  2: DoubleDrop,
  3: TripleDrop,
};

type DateClassesArguments = { date: Date; selectedDate: Date };

export const getDateClasses = ({ date, selectedDate }: DateClassesArguments) =>
  classNames({ [styles.todayBorder]: isToday(date) }, { [styles.selectedBorder]: isSameDay(date, selectedDate) });

type DayTooltipArguments = { t: TFunction; date: Date; selectedDate: Date };

export const getDayTooltip = ({ t, date, selectedDate }: DayTooltipArguments) => {
  const localeStringDate = date.toLocaleDateString();

  if (isToday(date)) return t("myMeno.todayTooltip", { date: localeStringDate });
  if (isSameDay(date, selectedDate)) return t("myMeno.selectedDayReport", { date: localeStringDate });
  return localeStringDate;
};
