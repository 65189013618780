import React, { useContext } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

import { FormCheckbox, Modal, CircleOption } from "components";
import { MenstruationData, postMenstruation, getUserMenstruation } from "firestore";
import { useFirebaseFetch } from "hooks";
import { getInitialValues, colorOptions, intensityOptions } from "./menstruation-form.constants";
import { MenstruationFormProps } from "./menstruation-form.types";
import { RootState } from "store";
import { CalendarContext } from "pages/user/my-meno/calendar";

import styles from "./menstruation-form.module.scss";

export const MenstruationForm: React.FC<MenstruationFormProps> = ({ isOpen, handleClose, onSuccess }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedDate } = useContext(CalendarContext);

  const { data, refresh } = useFirebaseFetch(() => getUserMenstruation(user?.id || "", selectedDate), {
    dependencies: [selectedDate],
  });

  const handleSubmit = async (values: MenstruationData, { resetForm }: FormikHelpers<MenstruationData>) => {
    if (user) {
      try {
        await postMenstruation(user.id, selectedDate, values);

        refresh();
        resetForm({ values });
        handleClose();
        onSuccess?.();
        enqueueSnackbar(t("menstruation.saveDataSuccess"), { variant: "success" });
      } catch (error) {
        enqueueSnackbar(t("menstruation.saveDataError"), { variant: "error" });
      }
    }
  };

  const initialValues = getInitialValues(data);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Formik<MenstruationData> initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <p className={styles.label}>{t("menstruation.intensityLabel")}</p>
            <div className={styles.container}>
              {intensityOptions(t).map((option) => (
                <CircleOption<number>
                  key={option.id}
                  option={option}
                  onChange={(value) => setFieldValue("intensity", value)}
                  selected={values.intensity === option.id}
                />
              ))}
            </div>
            <p className={styles.label}>{t("menstruation.bloodingColorLabel")}</p>
            <div className={styles.container}>
              {colorOptions(t).map(({ value, label }) => (
                <FormCheckbox name="color" key={value} label={label} value={value} />
              ))}
            </div>

            <div className={styles.row}>
              <Button type="button" onClick={handleClose}>
                {t("common.cancel")}
              </Button>
              <Button type="submit" variant="contained" sx={{ color: "#fff" }}>
                {t("common.submit")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
