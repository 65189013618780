export const checkout = {
  labels: {
    email: "Email*",
    firstName: "First name*",
    lastName: "Last name*",
    companyName: "Company",
    phone: "Phone number*",
    address1: "Address*",
    address2: "Address 2",
    city: "City*",
    postalCode: "Postal code*",
    quantity: "Quantity:",
  },
  validation: {
    emailRequired: "Email is required",
    invalidEmail: "Email is invalid",
    required: "This field is required",
  },
  details: {
    name: "Name: {{firstName}} {{lastName}}",
    address: "Address: {{postalCode}} {{city}}, {{streetAddress}}",
    phone: "Phone number: {{phoneNumber}}",
    company: "Organization: {{companyName}}",
    country: "Country: {{country}}",

    subtotalPrice: "Subtotal price:",
    tax: "Tax:",
    deliveryPrice: "Delivery price:",
    discount: "Discount:",
    totalPrice: "Total price:",
  },
  cart: {
    title: "Your cart",
    goToCheckout: "Go to checkout",
    noProductsTitle: "You don't have any products",
    noProductsSubtitle: "Go to shop page and select your products!",
    noProductsButton: "Go to our shop",
  },
  actions: {
    changeQuantitySuccess: "Quantity changed",
    changeQuantityError: "Could not change quantity of this product",

    removeProductSuccess: "Product has been removed",
    removeProductError: "Could not remove this product",

    addressDataSuccess: "Data has been saved",
    addressDataError: "Error occurred while saving the data",

    deliverySaveSuccess: "Delivery and payment method have been saved",
    deliverySaveError: "Could not save delivery and payment method",
  },
  payment: {
    getPaymentDetailsErrorTitle: "Could not get payment details",
    getPaymentDetailsErrorSubtitle: "Stripe cannot be initialized - missing configuration",
    getCheckoutDataErrorTitle: "Could not get checkout data",
    getCheckoutDataErrorSubtitle: "Could not get checkout data - payment cannot be fulfilled",
    goBack: "Go back to summary",

    processing: "Your payment is processing",
    success: "Payment was successful",
    successDescription: "All details about order and delivery will be send to your inbox.",
    failed: "Payment failed",
    failedDescription: "Error occurred while finalizing your payment.",
    incorrectData: "Incorrect data provided",
    incorrectDataDescription: "URL or provided data is incorrect",

    enterCardDetails: "Enter your details",
    cardDetailsDescription: "Enter all required data from your card. This is required to make payment.",
    cardNumber: "Card number",
    cardExpiryDate: "Card expiry date (MM / YY)",
    cvc: "CVC",

    createOrderError: "Could not create an order",
    completeCheckoutError: "Could not complete your checkout",
    confirmPaymentError: "Could not confirm your payment",
    createPaymentObjectError: "Could not create payment object",
    initializeCardError: "Card element is not initialized",

    pay: "Pay {{amount}} {{currency}}",

    orderSuccessfulTitle: "Order has been completed successfully",
    orderNumber: "Your order number: {{number}}",
    orderSuccessfulSubtitle: "Your confirmation and order details will be send to your inbox - ",
    confirm: "Confirm and close",

    error: "Error occurred while payment initialization.",
    noPaymentIntentError: "Error occurred while payment initialization. Go back and try again.",

    cashOnDelivery: "Cash on delivery",
    fastBankTransfer: "Fast bank transfer",
    payByCard: "Pay by card",
  },
  billingDetailsTitle: "Billing details",
  shippingDetailsTitle: "Delivery details",
  summaryTitle: "Summary",
  useBillingDetails: "Use same as billing details",
  sameAsBilling: "Same as billing details",

  addressDescription: "Fill in all required fields - data will be used to confirm your payment.",
  shippingMethodDescription: "Select your delivery and payment method. Currently we only support online payments.",
  summaryDescription: "Make sure all data are correct.",
  paymentDescription:
    "Select payment method and fill in all required data. You will be redirected to the bank website after completing all data.",

  paymentMethod: "Payment method",
  noPaymentMethodsAvailable: "No payment methods are available",

  deliveryMethod: "Delivery method",
  noDeliveryMethodAvailable: "No delivery methods are available",
  deliveryPrice: "Price: {{amount}} {{currency}}",
  deliveryTime: "Delivery time (days) - min: {{min}} max: {{max}}",

  goToPayment: "Go to payment",
  fillAllData: "You need to fill all data",
};
