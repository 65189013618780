export const STORAGE_FIELDS = {
  language: "language",
  token: "auth_token",
  refresh_token: "auth_refresh_token",
  checkout_token: "checkout_token",
  favourites_posts_pl: "favourites_posts_pl",
  favourites_posts_en: "favourites_posts_en",
  pwa_install_canceled: "pwa_install_canceled",
  pwa_install_shown: "pwa_install_shown",

  payment_intent: "payment_intent",
  payment_intent_client_secret: "payment_intent_client_secret",

  chatbot_email: "chatbot_email",
  chatbot_open: "chatbot_open",
};
