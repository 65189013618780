import { ChatbotBlock } from "./chatbot.types";

export const NAME: ChatbotBlock = {
  id: "name",
  text: () => "Cześć, tu Mameno Team, chcemy Cię lepiej poznać. Jak masz na imię?",
  input: { name: "name", placeholder: "Imię", type: "text", getNextBlock: () => "email" },
};

export const EMAIL: ChatbotBlock = {
  id: "email",
  text: () => "Podaj swój adres e-mail, abyśmy mogły się z Tobą skontaktować",
  input: { name: "email", placeholder: "Email", type: "email", getNextBlock: () => "age" },
};

export const AGE: ChatbotBlock = {
  id: "age",
  text: ({ name, isAuthenticated }) =>
    isAuthenticated
      ? `Cześć ${name}, miło Cię widzieć. Ile masz lat?`
      : `Cześć ${name}, miło Cię poznać. Ile masz lat?`,
  input: {
    name: "name",
    type: "number",
    placeholder: "Wiek",
    getNextBlock: (value: string) => {
      const age = parseFloat(value);

      if (age <= 35) return "age_less_35";
      if (age > 35 && age <= 40) return "age_more_35_less_40";
      if (age > 40 && age <= 50) return "age_more_41_less_50";
      if (age > 50) return "age_more_51";

      return "age";
    },
  },
};

export const AGE_LESS_35: ChatbotBlock = {
  id: "age_less_35",
  text: () =>
    "Pierwsze objawy nadchodzącej menopauzy rozpoczynają się najczęściej po 40. roku życia, rzadziej po 35. roku życia. Jeśli odczuwasz niepokojące objawy, najprawdopodobniej nie są one spowodowane przez zbliżająca się menopauzę, zgłoś się z nimi do lekarza.",
  buttons: [
    {
      label: "Rozumiem, ale chciałabym się dowiedzieć, czego mogę się spodziewać i jak się przygotować do menopauzy.",
      value: "learn_and_join_now",
      nextId: "age_less_35_learn_and_join",
    },
    {
      label: "Przeszłam menopauzę farmakologiczną/chirurgiczną, chcę do Was dołączyć.",
      value: "join_now",
      nextId: "age_less_35_join_now",
    },
    { label: "OK, wrócę za kilka lat.", value: "join_later", nextId: "age_less_35_join_later" },
  ],
};

export const AGE_LESS_35_LEARN_AND_JOIN_NOW: ChatbotBlock = {
  id: "age_less_35_learn_and_join",
  text: () => "Świetne podejście! W takim razie serdecznie zapraszamy. :-)",
  buttons: [],
  registerButtons: true,
};

export const AGE_LESS_35_JOIN_NOW: ChatbotBlock = {
  id: "age_less_35_join_now",
  text: () => "Rozumiemy. W MAMENO znajdziesz wiedzę i wsparcie społeczności. Zapraszamy!",
  buttons: [],
  registerButtons: true,
};

export const AGE_LESS_35_JOIN_LATER: ChatbotBlock = {
  id: "age_less_35_join_later",
  text: () =>
    "Zapraszamy! Jeśli chcesz się przygotować do menopauzy i dowiedzieć, czego się spodziewać, dołącz do nas już dziś.",
  buttons: [],
  registerButtons: true,
};

export const AGE_MORE_35_LESS_40: ChatbotBlock = {
  id: "age_more_35_less_40",
  text: () =>
    "Pierwsze objawy nadchodzącej menopauzy rozpoczynają się najczęściej po 40. roku życia, rzadziej po 35. roku życia, ale też są możliwe.\n" +
    "Zadamy Ci teraz kilka pytań o Twoje dolegliwości, to pomoże ustalić, na jakim etapie jesteś.",
  buttons: [
    { label: "Kontynuuj", value: "continue", nextId: "menstruation_details", variant: "contained" },
    { label: "Nie, dziękuję", value: "cancel", nextId: null, variant: "outlined" },
  ],
};

export const AGE_MORE_41_LESS_50: ChatbotBlock = {
  id: "age_more_41_less_50",
  text: () =>
    "Pierwsze objawy nadchodzącej menopauzy rozpoczynają się najczęściej po 40., więc jeżeli podejrzewasz, że jesteś w premenopauzie lub perimenopauzie, to prawdopodobnie tak jest. Sprawdźmy to - odpowiedz na kilka pytań.",
  buttons: [
    { label: "Kontynuuj", value: "continue", nextId: "menstruation_details", variant: "contained" },
    { label: "Nie, dziękuję", value: "cancel", nextId: null, variant: "outlined" },
  ],
};

export const AGE_MORE_51: ChatbotBlock = {
  id: "age_more_51",
  text: () =>
    "Menopauza, czyli ostatnia miesiączka, statystycznie występuje w wieku 51 lat. Objawy spowodowane przez spadek poziomu estrogenu zaczynają się kilka lat wcześniej i trwają kilka lat po menopauzie. Niektórych możesz w ogóle nie kojarzyć z menopauzą. Sprawdźmy, jakie masz dolegliwości.",
  buttons: [
    { label: "Kontynuuj", value: "continue", nextId: "menstruation_details", variant: "contained" },
    { label: "Nie, dziękuję", value: "cancel", nextId: null, variant: "outlined" },
  ],
};

export const MENSTRUATION_DETAILS: ChatbotBlock = {
  id: "menstruation_details",
  text: () => "Jakie są Twoje miesiączki?",
  buttons: [
    { label: "Regularne", value: "regular", nextId: "are_menstruations_longer", variant: "contained" },
    { label: "Nieregularne", value: "irregular", nextId: "are_menstruations_longer", variant: "contained" },
    { label: "Nie mam miesiączek", value: "do-not-have", nextId: "no_menstruation_since", variant: "contained" },
  ],
};

export const NO_MENSTRUATION_SINCE: ChatbotBlock = {
  id: "no_menstruation_since",
  text: () => "Od kiedy?",
  buttons: [
    { label: "Od 3-6 miesięcy", value: "3-6-months", nextId: "hot_flush", variant: "contained" },
    { label: "Od 7-11 miesięcy", value: "7-11-months", nextId: "hot_flush", variant: "contained" },
    {
      label: "Od 12 miesięcy lub dłużej",
      value: "over-12-months",
      nextId: "no_menstruation_since_year",
      variant: "contained",
    },
  ],
};

export const NO_MENSTRUATION_SINCE_YEAR: ChatbotBlock = {
  id: "no_menstruation_since_year",
  text: () =>
    "Jeżeli ostatnią miesiączką miałaś rok temu, lub dawniej, to znaczy, że przeszłaś menopauzę.\n" +
    "Jeśli chcesz wiedzieć, jak dbać o swoje zdrowie, dołącz do społeczności kobiet MAMENO.",
  buttons: [],
  registerButtons: true,
};

export const ARE_MENSTRUATIONS_LONGER: ChatbotBlock = {
  id: "are_menstruations_longer",
  countAnswer: true,
  text: () => "Czy Twoje miesiączki zmieniły się? Są dłuższe, bardziej obfite?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "hot_flush", variant: "contained" },
    { label: "NIE", value: "no", nextId: "hot_flush", variant: "outlined" },
  ],
};

export const HOT_FLUSH: ChatbotBlock = {
  id: "hot_flush",
  countAnswer: true,
  text: () => "Czy masz uderzenia gorąca?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "night_sweats", variant: "contained" },
    { label: "NIE", value: "no", nextId: "night_sweats", variant: "outlined" },
  ],
};

export const NIGHT_SWEATS: ChatbotBlock = {
  id: "night_sweats",
  countAnswer: true,
  text: () => "Czy masz nocne poty?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "sleep_problems", variant: "contained" },
    { label: "NIE", value: "no", nextId: "sleep_problems", variant: "outlined" },
  ],
};

export const SLEEP_PROBLEMS: ChatbotBlock = {
  id: "sleep_problems",
  countAnswer: true,
  text: () => "Sypiasz gorzej niż kiedyś?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "did_you_gain_weight", variant: "contained" },
    { label: "NIE", value: "no", nextId: "did_you_gain_weight", variant: "outlined" },
  ],
};

export const DID_YOU_GAIN_WEIGHT: ChatbotBlock = {
  id: "did_you_gain_weight",
  countAnswer: true,
  text: () => "Przytyłaś ostatnio?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "less_desire_for_sex", variant: "contained" },
    { label: "NIE", value: "no", nextId: "less_desire_for_sex", variant: "outlined" },
  ],
};

export const LESS_DESIRE_FOR_SEX: ChatbotBlock = {
  id: "less_desire_for_sex",
  countAnswer: true,
  text: () => "Masz mniejszą ochotę na seks?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "are_you_exhausted", variant: "contained" },
    { label: "NIE", value: "no", nextId: "are_you_exhausted", variant: "outlined" },
  ],
};

export const EXHAUSTED: ChatbotBlock = {
  id: "are_you_exhausted",
  countAnswer: true,
  text: () => "Czujesz zmęczenie i wyczerpanie?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "anxiety_or_sadness", variant: "contained" },
    { label: "NIE", value: "no", nextId: "anxiety_or_sadness", variant: "outlined" },
  ],
};

export const ANXIETY_OR_SADNESS: ChatbotBlock = {
  id: "anxiety_or_sadness",
  countAnswer: true,
  text: () => "Odczuwasz rozdrażnienie, smutek lub stany lękowe?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "muscle_aches", variant: "contained" },
    { label: "NIE", value: "no", nextId: "muscle_aches", variant: "outlined" },
  ],
};

export const MUSCLE_ACHES: ChatbotBlock = {
  id: "muscle_aches",
  countAnswer: true,
  text: () => "Masz bóle stawów i mięśni?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "dry_skin", variant: "contained" },
    { label: "NIE", value: "no", nextId: "dry_skin", variant: "outlined" },
  ],
};

export const DRY_SKIN: ChatbotBlock = {
  id: "dry_skin",
  countAnswer: true,
  text: () => "Twoja skóra stała się sucha?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "hair_loss", variant: "contained" },
    { label: "NIE", value: "no", nextId: "hair_loss", variant: "outlined" },
  ],
};

export const HAIR_LOSS: ChatbotBlock = {
  id: "hair_loss",
  countAnswer: true,
  text: () => "Wypadają Ci włosy i są zdecydowanie słabsze i cieńsze niż kiedyś?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "urinary_incontinence", variant: "contained" },
    { label: "NIE", value: "no", nextId: "urinary_incontinence", variant: "outlined" },
  ],
};

export const URINARY_INCONTINENCE: ChatbotBlock = {
  id: "urinary_incontinence",
  countAnswer: true,
  text: () => "Masz epizody nietrzymania moczu?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "vaginal_dryness", variant: "contained" },
    { label: "NIE", value: "no", nextId: "vaginal_dryness", variant: "outlined" },
  ],
};

export const VAGINAL_DRYNESS: ChatbotBlock = {
  id: "vaginal_dryness",
  countAnswer: true,
  text: () => "Czy cierpisz na suchość pochwy lub częstsze niż kiedyś infekcje intymne?",
  buttons: [
    { label: "TAK", value: "yes", nextId: "thank_you", variant: "contained" },
    { label: "NIE", value: "no", nextId: "thank_you", variant: "outlined" },
  ],
};

export const THANK_YOU: ChatbotBlock = {
  id: "thank_you",
  text: () => "Dziękujemy! Po przeanalizowaniu Twoich odpowiedzi, możemy powiedzieć, że",
  buttons: [],
  last: true,
};

export const SUMMARY_LOW: ChatbotBlock = {
  id: "summary_low",
  text: () =>
    "wygląda, że zmiany hormonalne spowodowane przez wygaszanie pracy jajników nie dają Ci bardzo w kość. Tylko 1 na 4 kobiety jest w podobnej sytuacji. Mamy nadzieję, że tak już zostanie i przejście menopauzalne nie będzie dla Ciebie bardzo uciążliwe. Jednak brak objawów lub ich mała uciążliwość nie znaczy, że powinnaś zostawić swoje zdrowie przypadkowi. Dołącz do MAMENO i dowiedz się, jak dbać o siebie w trzeciej części życia.",
  buttons: [],
  last: true,
  registerButtons: true,
};

export const SUMMARY_MEDIUM: ChatbotBlock = {
  id: "summary_medium",
  text: () =>
    "zmiany w Twoim organizmie dopiero się zaczynają - z czasem niektóre objawy mogą się nasilać, to będzie znaczyło, że Twoje jajniki przestają produkować estrogen i progesteron. Aby lepiej zrozumieć swoje ciało i objawy, porozmawiaj o nich ze swoim lekarzem. Wiele przydatnych informacji na temat tego, jak przygotować się do menopauzy, znajdziesz też na naszej platformie, spotkasz tu także kobiety, które przechodzą przez to samo, co Ty.",
  buttons: [],
  last: true,
  registerButtons: true,
};

export const SUMMARY_HIGH: ChatbotBlock = {
  id: "summary_high",
  text: () =>
    "z dużym prawdopodobieństwem jesteś już w okresie perimenopauzy, czyli Twoje jajniki przestają produkować estrogen i progesteron. Z tego powodu odczuwasz te wszystkie dolegliwości. Aby lepiej zrozumieć swoje ciało i objawy, porozmawiaj o nich ze swoim lekarzem. Wiele przydatnych informacji znajdziesz też na naszej platformie, spotkasz tu także kobiety, które przechodzą przez to samo, co Ty.",
  buttons: [],
  last: true,
  registerButtons: true,
};

export const chatBlocks: ChatbotBlock[] = [
  NAME,
  EMAIL,
  AGE,
  AGE_LESS_35,
  AGE_LESS_35_LEARN_AND_JOIN_NOW,
  AGE_LESS_35_JOIN_NOW,
  AGE_LESS_35_JOIN_LATER,
  AGE_MORE_35_LESS_40,
  AGE_MORE_41_LESS_50,
  AGE_MORE_51,
  MENSTRUATION_DETAILS,
  NO_MENSTRUATION_SINCE,
  NO_MENSTRUATION_SINCE_YEAR,
  ARE_MENSTRUATIONS_LONGER,
  HOT_FLUSH,
  NIGHT_SWEATS,
  SLEEP_PROBLEMS,
  DID_YOU_GAIN_WEIGHT,
  LESS_DESIRE_FOR_SEX,
  EXHAUSTED,
  ANXIETY_OR_SADNESS,
  MUSCLE_ACHES,
  DRY_SKIN,
  HAIR_LOSS,
  URINARY_INCONTINENCE,
  VAGINAL_DRYNESS,
  THANK_YOU,
];
