import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SummaryProps } from "./summary.types";
import { getLinkPath } from "utils";
import { CHECKOUT_PAGE } from "constants/routes.constants";

import styles from "./summary.module.scss";

export const Summary: React.FC<SummaryProps> = ({ checkout }) => {
  const { t } = useTranslation();
  const { subtotalPrice, shippingPrice, totalPrice, discount, id } = checkout || {};

  return (
    <div className={styles.container}>
      <Typography>
        {t("checkout.details.subtotalPrice")}{" "}
        <b>
          {subtotalPrice?.net.amount} {subtotalPrice?.net.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.tax")}{" "}
        <b>
          {subtotalPrice?.tax.amount} {subtotalPrice?.tax.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.deliveryPrice")}{" "}
        <b>
          {shippingPrice?.gross.amount} {shippingPrice?.gross.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.discount")}{" "}
        <b>
          {discount?.amount} {discount?.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.totalPrice")}{" "}
        <b>
          {totalPrice?.gross.amount} {totalPrice?.gross.currency}
        </b>
      </Typography>
      <Link to={getLinkPath(CHECKOUT_PAGE.path, { checkoutId: id })}>
        <Button variant="contained" sx={{ mt: 2, color: "#fff" }}>
          {t("checkout.cart.goToCheckout")}
        </Button>
      </Link>
    </div>
  );
};
