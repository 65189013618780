import { useSelector } from "react-redux";

import { RootState } from "store";
import { UserModel } from "models";

type ReturnValues = {
  isAuthenticated: boolean;
  user: UserModel | null;
};

export const useUser = (): ReturnValues => {
  const { user, token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = Boolean(token && user);

  return { isAuthenticated, user };
};
