import { chatBlocks } from "./chatbot.constants";
import { BlockId, ChatbotBlock, SavedAnswer } from "./chatbot.types";

export const prepareAnswerObject = (
  block: ChatbotBlock,
  text: string,
  answerLabel: string | number,
  answerValue?: string | number | null,
): SavedAnswer => {
  const answerObject = { ...block };

  delete answerObject.input;
  delete answerObject.buttons;

  return {
    ...answerObject,
    text,
    answer: { label: answerLabel, value: answerValue || answerLabel },
  } as SavedAnswer;
};

export const getNextBlockId = (lastSavedId: BlockId, lastSavedAnswerValue: string | number): BlockId | null => {
  const savedBlock = chatBlocks.find((block) => block.id === lastSavedId);

  if (savedBlock) {
    if (savedBlock.last) return savedBlock.id;
    if (savedBlock.input) return savedBlock.input.getNextBlock(lastSavedAnswerValue.toString());

    const lastAnswerButton = savedBlock.buttons?.find((button) => button.value === lastSavedAnswerValue);

    if (lastAnswerButton) return lastAnswerButton.nextId;

    return null;
  }

  return null;
};

export const calculatePoints = (conversation: SavedAnswer[]) => {
  return conversation.reduce((acc, currentValue) => {
    if (currentValue.countAnswer) {
      if (currentValue.answer.value === "yes") {
        return acc + 1;
      }
      return acc;
    }

    return acc;
  }, 0);
};
