import { TFunction } from "i18next";

import { FettleType } from "./home-header.types";

import fine from "assets/images/fine.png";
import irritability from "assets/images/irritability.png";
import dryness from "assets/images/dryness.png";
import insomnia from "assets/images/insomnia.png";
import headache from "assets/images/headache.png";

export const frameOfMind = (t: TFunction): FettleType[] => [
  { name: t("home.fine"), image: fine },
  { name: t("home.irritability"), image: irritability },
  { name: t("home.sad"), image: dryness },
  { name: t("home.insomnia"), image: insomnia },
  { name: t("home.bodyHurts"), image: headache },
];

export const buttonSx = {
  backgroundColor: "background.default",
  width: "70px",
  height: "40px",
  borderBottomLeftRadius: 25,
  borderTopLeftRadius: 25,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
};
