import React, { useState } from "react";

import { WeekCalendar, CalendarContextProvider } from "pages/user/my-meno/calendar";
import { Report } from "./report/report";
import { Content } from "components";

export const MyMenoPage: React.FC = () => {
  const [weeklyReportsUpdater, setWeeklyReportsUpdater] = useState<number>(0);
  const [weeklyMenstruationUpdater, setWeeklyMenstruationUpdater] = useState<number>(0);

  const updateWeeklyReports = () => setWeeklyReportsUpdater((prevState) => prevState + 1);
  const updateWeeklyMenstruation = () => setWeeklyMenstruationUpdater((prevState) => prevState + 1);

  return (
    <CalendarContextProvider>
      <Content>
        <WeekCalendar
          weeklyReportsUpdater={weeklyReportsUpdater}
          weeklyMenstruationUpdater={weeklyMenstruationUpdater}
        />
        <Report updateWeeklyReports={updateWeeklyReports} updateWeeklyMenstruation={updateWeeklyMenstruation} />
      </Content>
    </CalendarContextProvider>
  );
};
