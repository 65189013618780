import React from "react";
import ReactMarkdown from "react-markdown";
import { Typography, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { Content, Loader, NoContent, FetchingError, ArticleFavourite } from "components";
import { useShowContent, useAnalytics } from "hooks";
import { extractImageUrl } from "utils";
import { getArticle } from "server";
import { environment } from "config";

import styles from "./post-details.module.scss";

export const PostDetailsPage: React.FC = () => {
  const navigate = useNavigate();

  const { logEvent } = useAnalytics();
  const { articleId } = useParams<{ articleId: string }>();

  const articleData = useFetch(getArticle.setParams({ articleId: articleId || "0" }).setQueryParams({ populate: "*" }));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(articleData);
  const { data: article, onSuccess } = articleData;

  onSuccess(({ data }) => {
    logEvent(`article_loaded_${data.id}`, { articleId: data.id, articleName: data.attributes.title });
  });

  const goBack = () => navigate(-1);
  const transformUrl = (src: string) => `${environment.serverUrl}${src}`;
  const transformLinkUrl = (url: string) => {
    const urlPattern = /^((http|https|ftp):\/\/)/;

    if (urlPattern.test(url)) {
      return url;
    }

    return `${environment.serverUrl}${url}`;
  };

  const image = extractImageUrl(article?.data);

  return (
    <Content className={styles.container}>
      {showLoader && <Loader height="100%" />}
      {showNoContent && <NoContent />}
      {showError && <FetchingError />}
      {showContent && article && (
        <section className={styles.content}>
          <IconButton onClick={goBack} sx={{ width: "40px", height: "40px", mb: 2, ml: -1 }}>
            <ArrowBack />
          </IconButton>
          <div className={styles.row}>
            <Typography variant="h2" fontWeight={600} className={styles.title}>
              {article.data.attributes.title}
            </Typography>
            {articleId && <ArticleFavourite articleId={+articleId} />}
          </div>
          {image && <img src={image} className={styles.image} alt="article post" />}

          <ReactMarkdown transformImageUri={transformUrl} transformLinkUri={transformLinkUrl}>
            {article.data.attributes.text}
          </ReactMarkdown>
        </section>
      )}
    </Content>
  );
};
