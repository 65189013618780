import React from "react";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { CircleOption } from "components";
import { ReportOptions, ReportModel } from "models";
import { OptionSectionProps } from "./option-section.types";

import styles from "./option-section.module.scss";

export const OptionSection: React.FC<OptionSectionProps> = ({ sectionName, options, sectionKey, single }) => {
  const { values, setFieldValue } = useFormikContext<ReportModel>();

  // TODO: refactor
  const handleChange = (value: ReportOptions | string | number) => {
    if (single) {
      setFieldValue(sectionKey, value);
    } else {
      const itemsArray = values[sectionKey];
      if (Array.isArray(itemsArray)) {
        const isAlreadyAdded = itemsArray.findIndex((item) => item === value);
        if (isAlreadyAdded !== -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const updatedArray = itemsArray.filter((item) => item !== value);
          setFieldValue(sectionKey, updatedArray);
        } else {
          setFieldValue(sectionKey, [...itemsArray, value]);
        }
      } else {
        console.error("Tried to push a new item to the non-array value");
      }
    }
  };

  const isSelected = (value: string): boolean => {
    if (single) return values[sectionKey] === value;

    const items = values[sectionKey];

    if (Array.isArray(items)) {
      const index = items.findIndex((item) => item === value);
      return index !== -1;
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <Typography variant="h4" fontWeight={600} sx={{ mb: 3 }}>
        {sectionName}
      </Typography>
      <section className={styles.gridContainer}>
        {options.map((option) => (
          <CircleOption<ReportOptions | string | number>
            key={option.id}
            option={option}
            onChange={handleChange}
            selected={option?.id ? isSelected(option.id) : false}
          />
        ))}
      </section>
    </div>
  );
};
