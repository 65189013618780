import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Typography, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  User,
  AuthError,
  getAdditionalUserInfo,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { FormTextField } from "components";
import { setToken, setUser } from "store";
import { mapUserData } from "utils";
import { loginSchema, initialValues, providers } from "./login-form.constants";
import { LoginData, ProviderLoginArguments } from "./login-form.types";
import { FIREBASE_ERRORS } from "pages/auth/auth.constants";
import { FirebaseErrorType } from "pages/auth/auth.types";
import { useAnalytics } from "hooks";
import { auth } from "config/firebase.config";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { LANDING_PAGE, REGISTER_PAGE, RESET_PASSWORD_PAGE } from "constants/routes.constants";
import { MAILCHIMP_URL } from "constants/app.constants";

import { ReactComponent as GoogleLogo } from "assets/icons/google-logo.svg";

import styles from "./login-form.module.scss";

interface Props {
  showVerifyScreen: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LoginForm: React.FC<Props> = ({ showVerifyScreen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setUser: setAnalyticsUser } = useAnalytics();

  const onLoginSuccess = (user: User) => {
    user
      .getIdToken()
      .then((idToken) => {
        const userData = mapUserData(user);
        dispatch(setUser(userData));
        dispatch(setToken(idToken));

        localStorage.setItem(STORAGE_FIELDS.token, idToken);
        localStorage.setItem(STORAGE_FIELDS.refresh_token, user.refreshToken);

        if (userData?.email) {
          setAnalyticsUser(userData.email);
        }

        navigate(LANDING_PAGE.path);
      })
      .catch(() => enqueueSnackbar(t("auth.login.tokenError"), { variant: "error" }));
  };

  const handleLogin = (values: LoginData, { setSubmitting }: FormikHelpers<LoginData>) => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, values.email, values.password)
          .then(({ user }) => {
            if (user.emailVerified) {
              onLoginSuccess(user);
            } else {
              showVerifyScreen();
            }
          })
          .catch((error: AuthError) => {
            const errorName = error.code as FirebaseErrorType;
            const message = FIREBASE_ERRORS(t)[errorName] || t("auth.login.error");

            enqueueSnackbar(message, { variant: "error" });
          });
      })
      .catch(() => {
        enqueueSnackbar("Wystąpił problem podczas logowania", { variant: "error" });
      });

    setSubmitting(false);
  };

  const handleProviderLogin =
    ({ authProvider, subscribe }: ProviderLoginArguments) =>
    () => {
      const provider = providers[authProvider];

      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithPopup(auth, provider)
            .then((userCredential) => {
              const { user } = userCredential;

              const details = getAdditionalUserInfo(userCredential);

              if (details?.isNewUser) {
                subscribe({ EMAIL: user.email, FNAME: user.displayName || "" });
              }
              onLoginSuccess(user);
            })
            .catch((error: AuthError) => {
              const errorName = error.code as FirebaseErrorType;
              const message = FIREBASE_ERRORS(t)[errorName] || t("auth.login.error");

              enqueueSnackbar(message, { variant: "error" });
            });
        })
        .catch(() => {
          enqueueSnackbar("Wystąpił błąd podczas logowania", { variant: "error" });
        });
    };

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={({ subscribe }) => {
        return (
          <Formik initialValues={initialValues} onSubmit={handleLogin} validationSchema={loginSchema(t)}>
            {({ isSubmitting }) => (
              <Form className={styles.form}>
                <Typography variant="h2" fontWeight={600} sx={{ textAlign: "center", my: 3 }} className={styles.title}>
                  {t("auth.login.title")}
                </Typography>
                <div className={styles.socialContainer}>
                  <Tooltip title={t("auth.login.google")}>
                    <button
                      type="button"
                      onClick={handleProviderLogin({ authProvider: "google", subscribe })}
                      disabled={isSubmitting}
                    >
                      <GoogleLogo className={styles.icon} />
                    </button>
                  </Tooltip>
                </div>
                <FormTextField name="email" type="email" label={t("auth.labels.email")} className={styles.input} />
                <FormTextField
                  name="password"
                  label={t("auth.labels.password")}
                  type="password"
                  className={styles.input}
                />

                <div className={styles.buttonWrapper}>
                  <Button type="submit" variant="contained" className={styles.button} disabled={isSubmitting}>
                    {t("auth.login.button")}
                  </Button>

                  <Typography
                    className={styles.getStartedTitle}
                    color="text.secondary"
                    variant="body1"
                    fontWeight={300}
                  >
                    {t("auth.login.pageDescription")}
                    <Link to={REGISTER_PAGE.path} className={styles.link}>
                      {t("auth.login.register")}
                    </Link>
                  </Typography>
                  <Typography
                    className={styles.getStartedTitle}
                    color="text.secondary"
                    variant="body1"
                    fontWeight={300}
                  >
                    {t("auth.login.forgotPassword")}
                    <Link to={RESET_PASSWORD_PAGE.path} className={styles.link}>
                      {t("auth.login.reset")}
                    </Link>
                  </Typography>
                </div>
              </Form>
            )}
          </Formik>
        );
      }}
    />
  );
};
