import React from "react";
import { IconButton, Typography } from "@mui/material";
import { MailOutlined, PhoneOutlined, LanguageOutlined } from "@mui/icons-material";

import { Modal } from "components";
import { DoctorModel } from "models";

import styles from "../doctor-item/doctor-item.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  doctor: DoctorModel;
}

export const DoctorDetails: React.FC<Props> = ({ isOpen, handleClose, doctor }) => {
  const { attributes } = doctor;

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div>
        <Typography fontWeight={600} fontSize={24}>
          {attributes.name} {attributes.surname}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {attributes.specialization}
        </Typography>
        <Typography variant="body2" sx={{ my: 2 }}>
          {attributes.address} {attributes.city} - {attributes.voivodeship}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {attributes.opinion}
        </Typography>
        <a href={`mailto:${attributes.email}`}>
          <IconButton>
            <MailOutlined className={styles.icon} sx={{ color: "text.disabled" }} />
          </IconButton>
        </a>
        <a href={`tel:${attributes.phone}`}>
          <IconButton>
            <PhoneOutlined className={styles.icon} sx={{ color: "text.disabled" }} />
          </IconButton>
        </a>
        <a href={attributes.website} target="_blank" rel="noreferrer noopener">
          <IconButton>
            <LanguageOutlined className={styles.icon} sx={{ color: "text.disabled" }} />
          </IconButton>
        </a>
      </div>
    </Modal>
  );
};
