import React from "react";
import { Formik, Form } from "formik";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSubmit } from "@better-typed/react-hyper-fetch";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { FormTextField, Modal } from "components";
import { AdviceModel } from "models";
import { RootState } from "store";
import { createAdviceForm } from "server";
import { createAdviceValues, adviceSchema } from "./advice-modal.constants";

import styles from "./advice-modal.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const AdviceModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(createAdviceForm);
  onSubmitSuccess(() => {
    handleClose();
    enqueueSnackbar(t("advice.formSent"), { variant: "success" });
  });
  onSubmitError(() => {
    enqueueSnackbar(t("advice.formError"), { variant: "error" });
  });

  const handleSubmit = (values: AdviceModel) => {
    if (user) {
      const value = { data: { ...values, publishedAt: null, email: user?.email || "" } };
      submit({ data: value });
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Formik initialValues={createAdviceValues} onSubmit={handleSubmit} validationSchema={adviceSchema(t)}>
        <div>
          <Typography variant="h5" fontWeight={600} sx={{ mb: 2 }}>
            {t("advice.adviceForm")}
          </Typography>
          <Form className={styles.form}>
            <FormTextField name="name" label={t("advice.name")} />
            <FormTextField
              type="number"
              name="age"
              label={t("advice.age")}
              InputProps={{ inputProps: { min: 18, max: 100 } }}
            />

            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("advice.menopauseDiagnosed")}
              </Typography>
              <FormTextField
                name="prePeriPost"
                label=""
                placeholder={t("advice.menopauseDiagnosed")}
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 1000 }}
                fullWidth
              />
            </div>

            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("advice.whatDidYouKnow")}
              </Typography>
              <FormTextField
                name="whatDidYouKnow"
                label=""
                placeholder={t("advice.whatDidYouKnow")}
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 1000 }}
                fullWidth
              />
            </div>
            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("advice.mostSurprising")}
              </Typography>
              <FormTextField
                name="mostSurprising"
                label=""
                placeholder={t("advice.mostSurprising")}
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 1000 }}
                fullWidth
              />
            </div>

            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("advice.howItsGoing")}
              </Typography>
              <FormTextField
                name="howItsGoing"
                label=""
                placeholder={t("advice.howItsGoing")}
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 1000 }}
                fullWidth
              />
            </div>

            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("advice.howDoesItImpact")}
              </Typography>
              <FormTextField
                name="howDoesItImpact"
                label=""
                placeholder={t("advice.howDoesItImpact")}
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 1000 }}
                fullWidth
              />
            </div>

            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("advice.adviceQuestion")}
              </Typography>
              <FormTextField
                name="advice"
                label=""
                placeholder={t("advice.adviceQuestion")}
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 1000 }}
                fullWidth
              />
            </div>

            <Button type="submit" variant="contained" sx={{ color: "#fff" }}>
              {t("common.send")}
            </Button>
          </Form>
        </div>
      </Formik>
    </Modal>
  );
};
