import React from "react";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useSubmit } from "@better-typed/react-hyper-fetch";

import { FormSelect, FormTextField, Modal } from "components";
import { DoctorServerValues, DoctorValues } from "models";
import { voivodeshipOptions } from "constants/voivodeship.constants";
import { specializationOptions } from "constants/specialization.constants";
import { createDoctorValues, doctorSchema } from "./doctors-create.constants";
import { createDoctor } from "server";

import styles from "./doctors-create.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const DoctorsCreate: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(createDoctor);
  onSubmitSuccess(() => {
    handleClose();
    enqueueSnackbar(t("doctors.doctorAdded"), { variant: "success" });
  });
  onSubmitError(() => {
    enqueueSnackbar(t("doctors.doctorAddError"), { variant: "error" });
  });

  const handleSubmit = (values: DoctorValues) => {
    const value: { data: DoctorServerValues } = { data: { ...values, publishedAt: null } };
    submit({ data: value });
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Formik initialValues={createDoctorValues} onSubmit={handleSubmit} validationSchema={doctorSchema(t)}>
        <div>
          <Typography variant="h5" fontWeight={600} sx={{ mb: 2 }}>
            {t("doctors.addDoctor")}
          </Typography>
          <Form className={styles.form}>
            <FormTextField name="name" label={t("doctors.name")} />
            <FormTextField name="surname" label={t("doctors.surname")} />
            <FormTextField name="address" label={t("doctors.address")} />
            <FormTextField name="city" label={t("doctors.city")} />
            <FormTextField type="email" name="email" label={t("doctors.email")} />
            <FormTextField name="website" label={t("doctors.website")} />
            <FormSelect
              name="voivodeship"
              label={t("doctors.voivodeship")}
              options={voivodeshipOptions}
              labelKey="name"
            />
            <FormSelect
              name="specialization"
              label={t("doctors.specialization")}
              options={specializationOptions}
              labelKey="name"
            />
            <FormTextField name="phone" label={t("doctors.phoneNumber")} />
            <FormTextField
              name="opinion"
              label={t("doctors.opinion")}
              multiline
              minRows={6}
              maxRows={6}
              inputProps={{ maxLength: 1000 }}
            />
            <Button type="submit" variant="contained" sx={{ color: "#fff" }}>
              {t("doctors.add")}
            </Button>
          </Form>
        </div>
      </Formik>
    </Modal>
  );
};
