import React from "react";
import { Navigate } from "react-router-dom";

import { Page } from "components";
import { RouteConfig } from "types";
import { useUser } from "hooks";
import { LOGIN_PAGE } from "constants/routes.constants";

export const PageRoute: React.FC<RouteConfig> = ({ component: Component, showNavigation, auth }) => {
  const { isAuthenticated } = useUser();

  if (auth && !isAuthenticated) return <Navigate to={LOGIN_PAGE.path} />;

  return (
    <Page showNavigation={showNavigation}>
      <Component />
    </Page>
  );
};
