import React from "react";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { removePostFromFavourites, pushPostToFavourites, RootState } from "store";
import { ArticleFavouriteProps } from "./article-favourite.types";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

import { ReactComponent as HeartColor } from "assets/icons/heart-color.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";

import styles from "./article-favourite.module.scss";

export const ArticleFavourite: React.FC<ArticleFavouriteProps> = ({ articleId }) => {
  const dispatch = useDispatch();

  const { locale } = useSelector((state: RootState) => state.app);
  const { posts } = useSelector((state: RootState) => state.favourites);

  const storageKey = locale === "pl-PL" ? STORAGE_FIELDS.favourites_posts_pl : STORAGE_FIELDS.favourites_posts_en;
  const isAdded = Boolean(posts.find((postId) => postId === articleId));

  const handleButtonClick = () => {
    if (isAdded) dispatch(removePostFromFavourites({ key: storageKey, value: articleId }));
    if (!isAdded) dispatch(pushPostToFavourites({ key: storageKey, value: articleId }));
  };

  return (
    <IconButton onClick={handleButtonClick} className={styles.button}>
      {isAdded && <HeartColor />}
      {!isAdded && <Heart />}
    </IconButton>
  );
};
