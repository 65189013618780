import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

import { LoaderProps } from "./loader.types";

import styles from "./loader.module.css";

export const Loader: React.FC<LoaderProps> = ({ height, showText, loaderSize }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Box component="div" sx={{ height }} className={styles.wrapper}>
      <Box component="div" className={styles.innerWrapper}>
        <div className={styles.loader}>
          <CircularProgress sx={{ color: palette.primary.main }} size={loaderSize || 30} />
          {showText && (
            <Typography variant="body1" color="primary.light">
              {t("common.loading")}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
};
