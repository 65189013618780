import { TFunction } from "i18next";
import { SchemaOf, object, string } from "yup";

import { passwordValidation } from "utils";
import { RegisterData } from "./register-form.types";

export const initialValues: RegisterData = {
  name: "",
  email: "",
  password: "",
};

export const registerSchema = (t: TFunction): SchemaOf<RegisterData> =>
  object().shape({
    name: string().min(2, t("auth.validation.nameMin2")).required(t("auth.validation.nameRequired")),
    email: string().email(t("auth.validation.invalidEmail")).required(t("auth.validation.emailRequired")),
    password: string()
      .test("password", t("auth.validation.passwordRules"), passwordValidation(t))
      .required(t("auth.validation.passwordRequired")),
  });
