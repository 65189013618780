export const faq = {
  question1: "What is Mameno?",
  answer1: "Answer 1",
  question2: "What is 1Mameno?",
  answer2: "Answer 2",
  question3: "What is 2Mameno?",
  answer3: "Answer 3",
  question4: "What is 3Mameno?",
  answer4: "Answer 4",
  question5: "What is 4Mameno?",
  answer5: "Answer 5",
  question6: "What is 5Mameno?",
  answer6: "Answer 6",
  question7: "What is 6Mameno?",
  answer7: "Answer 7",
  question8: "What is 7Mameno?",
  answer8: "Answer 8",
};
