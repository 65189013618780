import React from "react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { PwaInstall, PwaInstallInfo } from "components";
import { CheckoutContextProvider } from "context";
import { ProviderProps } from "./providers.types";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { resources } from "translations/resources";
import { store } from "store";
import { environment } from "config";
import { DEFAULT_LOCALE } from "constants/app.constants";

import { themeOptions } from "assets/styles/theme";

import styles from "./providers.module.scss";

i18next.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LOCALE,
  // lng: localStorage.getItem(STORAGE_FIELDS.language) || DEFAULT_LOCALE,
});

i18next.on("languageChanged", (lng: string) => {
  localStorage.setItem(STORAGE_FIELDS.language, lng);
});

export const Providers: React.FC<ProviderProps> = ({ children, testStore }) => {
  const client = new ApolloClient({
    uri: environment.saleorApiUrl,
    cache: new InMemoryCache(),
  });

  return (
    <Provider store={testStore || store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={themeOptions}>
          <SnackbarProvider
            preventDuplicate
            autoHideDuration={3000}
            maxSnack={3}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            classes={{
              variantSuccess: styles.success,
              variantWarning: styles.warning,
              variantError: styles.error,
              variantInfo: styles.info,
            }}
          >
            <CheckoutContextProvider>
              <PwaInstall />
              <PwaInstallInfo />
              <BrowserRouter>{children}</BrowserRouter>
            </CheckoutContextProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};
