import React from "react";
import { useTranslation } from "react-i18next";
import { InputBase, InputBaseProps, Paper, useTheme } from "@mui/material";

import { ReactComponent as Search } from "assets/icons/search.svg";

import styles from "./form-search-input.module.scss";

type Props = {
  border?: boolean;
  children?: React.ReactNode;
} & InputBaseProps;

export const FormSearchInput: React.FC<Props> = ({ border, children, ...props }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Paper
      sx={{
        border: border ? `1px solid ${palette.divider}` : "none",
      }}
      className={styles.input}
    >
      <div>
        <Search className={styles.searchIcon} />
      </div>
      <InputBase
        className={styles.innerInput}
        placeholder={t("common.search")}
        inputProps={{ "aria-label": "search google maps" }}
        {...props}
      />
      {children}
    </Paper>
  );
};
