import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getLinkPath } from "utils";
import { Loader } from "components/client/loader/loader";
import { PRODUCT_DETAILS_PAGE } from "constants/routes.constants";
import { DefaultProduct, GET_LATEST_PRODUCTS, ProductsResponse } from "graphql/product";
import { DEFAULT_CHANNEL } from "constants/app.constants";
import { ProductResultsProps } from "./products-results.types";

import styles from "../global-search.module.scss";

const MAX_ITEMS = 25;

export const ProductsResults: React.FC<ProductResultsProps> = ({ searchValue }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery<ProductsResponse<DefaultProduct>>(GET_LATEST_PRODUCTS, {
    variables: { first: MAX_ITEMS, channel: DEFAULT_CHANNEL, search: searchValue },
  });

  const products = data?.products.edges || [];

  const showNoContent = products.length === 0;
  const showContent = !loading && !showNoContent;

  return (
    <div>
      <Typography className={styles.title}>{t("common.products")}</Typography>
      {loading && <Loader />}
      {showNoContent && <Typography className={styles.sectionParagraph}>{t("common.noResults")}</Typography>}
      {showContent &&
        products?.map((product) => (
          <div key={product.node.id} className={styles.sectionWrapper}>
            <Link to={getLinkPath(PRODUCT_DETAILS_PAGE.path, { productId: product.node.id })} className={styles.link}>
              {product.node.name}
            </Link>
          </div>
        ))}
    </div>
  );
};
