import React, { useState } from "react";
import usePWA from "react-pwa-install-prompt";
import { Typography, Button } from "@mui/material";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { STORAGE_FIELDS } from "constants/storage-fields.constants";

import styles from "./pwa-install.module.scss";

export const PwaInstall: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();

  useDidUpdate(
    () => {
      const userCanceledValue = localStorage.getItem(STORAGE_FIELDS.pwa_install_canceled);
      const userCanceled = userCanceledValue === "true";

      setShowModal(isInstallPromptSupported && !userCanceled);
    },
    [isInstallPromptSupported, isStandalone],
    true,
  );

  const onClickInstall = async () => {
    const didInstall = await promptInstall();
    if (didInstall) {
      setShowModal(false);
      localStorage.setItem(STORAGE_FIELDS.pwa_install_canceled, "true");
    }
  };

  const cancelInstall = () => {
    setShowModal(false);
    localStorage.setItem(STORAGE_FIELDS.pwa_install_canceled, "true");
  };

  return (
    <div className={showModal ? styles.container : styles.hidden}>
      <Typography variant="caption">Zainstaluj aplikację Mameno na swoim urządzeniu.</Typography>
      <div className={styles.row}>
        <Button type="button" onClick={cancelInstall} variant="outlined">
          Anuluj
        </Button>
        <Button type="button" onClick={onClickInstall} variant="contained" sx={{ color: "#fff" }}>
          Zainstaluj
        </Button>
      </div>
    </div>
  );
};
