import React from "react";
import Div100vh from "react-div-100vh";

import { Sidebar } from "components";
import { PageProps } from "./page.types";

import styles from "./page.module.scss";

export const Page: React.FC<PageProps> = ({ children, showNavigation }) => {
  if (showNavigation) {
    return (
      <Div100vh>
        <div className={styles.navigationContainer}>
          <Sidebar />
          {children}
        </div>
      </Div100vh>
    );
  }

  return <div className={styles.container}>{children}</div>;
};
