import { auth } from "./auth.translations";
import { pages } from "./pages.translations";
import { common } from "./common.translations";
import { home } from "./home.translations";
import { blog } from "./blog.translations";
import { product } from "./product.translations";
import { myMeno } from "./my-meno.translations";
import { favourites } from "./favourites.translations";
import { faq } from "./faq.translations";
import { menstruation } from "./menstruation.translations";
import { checkout } from "./checkout.translations";
import { forum } from "./forum.translations";
import { chatbot } from "./chatbot.translations";
import { doctors } from "./doctors.translations";
import { advice } from "./advice.translations";

export const plTranslations = {
  auth,
  pages,
  common,
  home,
  blog,
  product,
  myMeno,
  favourites,
  faq,
  menstruation,
  checkout,
  forum,
  chatbot,
  doctors,
  advice,
};
