import { AxiosError } from "axios";
import { CustomEventName, logEvent, setUserId } from "firebase/analytics";

import { analytics } from "config/firebase.config";

type ParamValue = string | number | boolean | AxiosError;
type EventParams = { [key: string]: ParamValue };

type AnalyticsReturnData<T extends string> = {
  logEvent: (eventName: CustomEventName<T>, params?: EventParams) => void;
  setUser: (userId: string) => void;
};

export const useAnalytics = <T extends string>(): AnalyticsReturnData<T> => {
  const setUser = (userId: string) => {
    setUserId(analytics, userId);
  };

  const log = (eventName: CustomEventName<T>, params?: EventParams) => {
    logEvent(analytics, eventName, params);
  };

  return { logEvent: log, setUser };
};
