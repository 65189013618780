import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { mapLocale } from "utils";
import { DEFAULT_LOCALE } from "constants/app.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

type InitialState = {
  locale: string;
  isChatbotOpen: boolean;
};

const storageChatbotOpen = localStorage.getItem(STORAGE_FIELDS.chatbot_open);

const initialState: InitialState = {
  isChatbotOpen: storageChatbotOpen === null ? true : storageChatbotOpen === "true",
  locale: mapLocale(localStorage.getItem(STORAGE_FIELDS.language)) || DEFAULT_LOCALE,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    resetAppStore: () => initialState,
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setChatbotOpen: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem(STORAGE_FIELDS.chatbot_open, action.payload.toString());
      state.isChatbotOpen = action.payload;
    },
  },
});

export const { resetAppStore, setLocale, setChatbotOpen } = app.actions;

export default app.reducer;
