import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { sendPasswordResetEmail, AuthError } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";

import { FormTextField } from "components";
import { initialValues, resetPasswordSchema } from "./reset-password-form.constants";
import { ResetPasswordData } from "./reset-password-form.types";
import { FIREBASE_ERRORS } from "pages/auth/auth.constants";
import { FirebaseErrorType } from "pages/auth/auth.types";
import { auth } from "config/firebase.config";
import { LOGIN_PAGE } from "constants/routes.constants";

import styles from "./reset-password-form.module.scss";

export const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = (values: ResetPasswordData, { setSubmitting }: FormikHelpers<ResetPasswordData>) => {
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        navigate(LOGIN_PAGE.path);
        enqueueSnackbar(t("auth.resetPassword.resetSuccess"), { variant: "success" });
      })
      .catch((error: AuthError) => {
        const errorName = error.code as FirebaseErrorType;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const message = FIREBASE_ERRORS(t)[errorName] || t("auth.login.error");

        enqueueSnackbar(t("auth.resetPassword.resetError"), { variant: "error" });
      });

    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleReset} validationSchema={resetPasswordSchema(t)}>
      {({ isSubmitting }) => (
        <Form className={styles.form}>
          <Typography variant="h2" fontWeight={600} sx={{ textAlign: "center", my: 3 }} className={styles.title}>
            {t("auth.resetPassword.title")}
          </Typography>
          <FormTextField name="email" type="email" label={t("auth.labels.email")} className={styles.input} />

          <div className={styles.buttonWrapper}>
            <Button type="submit" variant="contained" disabled={isSubmitting} className={styles.button}>
              {t("auth.resetPassword.send")}
            </Button>
            <Typography className={styles.getStartedTitle} color="text.secondary" variant="body1" fontWeight={300}>
              {t("auth.resetPassword.rememberPassword")}
              <Link to={LOGIN_PAGE.path} className={styles.link}>
                {t("auth.resetPassword.logIn")}
              </Link>
            </Typography>
          </div>
        </Form>
      )}
    </Formik>
  );
};
