import React from "react";

import { CheckoutProduct } from "components";
import { CheckoutItem } from "graphql/checkout";

interface Props {
  products: CheckoutItem[];
}

export const Products: React.FC<Props> = ({ products }) => {
  return (
    <div>
      {products.map((product) => (
        <CheckoutProduct key={product.id} product={product} />
      ))}
    </div>
  );
};
