import { TFunction } from "i18next";

import { MenstruationData } from "firestore";
// import { CircleOptionType } from "components";
import { Intensity, MenstruationModel } from "models";
import { ColorOption, CircleOptionType } from "./menstruation-form.types";

import { ReactComponent as Drop } from "assets/icons/drop.svg";
import { ReactComponent as DoubleDrop } from "assets/icons/double-drop.svg";
import { ReactComponent as TripleDrop } from "assets/icons/triple-drop.svg";

export const getInitialValues = (menstruation?: MenstruationModel | null): MenstruationData => ({
  intensity: menstruation?.intensity || 0,
  color: menstruation?.color || "red",
});

export const intensityOptions = (t: TFunction): CircleOptionType<Intensity>[] => [
  { id: 0, name: t("menstruation.intensity.spotting"), icon: Drop },
  { id: 1, name: t("menstruation.intensity.low"), icon: Drop },
  { id: 2, name: t("menstruation.intensity.medium"), icon: DoubleDrop },
  { id: 3, name: t("menstruation.intensity.intense"), icon: TripleDrop },
];

export const colorOptions = (t: TFunction): ColorOption[] => [
  { label: t("menstruation.color.red"), value: "red" },
  { label: t("menstruation.color.brightRed"), value: "bright-red" },
  { label: t("menstruation.color.darkRed"), value: "dark-red" },
  { label: t("menstruation.color.pink"), value: "pink" },
  { label: t("menstruation.color.pinkOrange"), value: "pink-orange" },
];
