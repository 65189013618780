import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, DeleteOutlined } from "@mui/icons-material";
import { Avatar, IconButton, Typography } from "@mui/material";

import { ThreadModel } from "models";
import { ConfirmModal } from "components";
import { deleteThread } from "firestore";
import { useUser } from "hooks";
import { FORUM_THREADS_PAGE } from "constants/routes.constants";

import styles from "../forum-thread-details.module.scss";
import threadStyles from "./thread.module.scss";

interface Props {
  thread: ThreadModel;
}

export const Thread: React.FC<Props> = ({ thread }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { threadId } = useParams<{ threadId: string }>();
  const { creator, title, description } = thread;

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);

  const handleDelete = () => {
    if (threadId) {
      deleteThread(threadId)
        .then(() => {
          setDeleteOpen(false);
          enqueueSnackbar("Wątek został usunięty", { variant: "success" });
          navigate(FORUM_THREADS_PAGE.path);
        })
        .catch(() => enqueueSnackbar("Błąd podczas usuwania", { variant: "error" }));
    }
  };
  const handleDeleteOpen = (isOpen: boolean) => () => setDeleteOpen(isOpen);

  const goBackToForum = () => navigate(FORUM_THREADS_PAGE.path);

  const showDelete = thread.creator?.id === user?.id;
  const name = thread?.creator?.displayName || `${thread?.creator?.name} ${thread?.creator?.surname}`;

  return (
    <div>
      <div className={threadStyles.row}>
        <div className={styles.goBack}>
          <IconButton onClick={goBackToForum}>
            <ArrowBackIos />
          </IconButton>
          <Typography>{t("forum.goBack")}</Typography>
        </div>
        {showDelete && (
          <IconButton onClick={handleDeleteOpen(true)}>
            <DeleteOutlined />
          </IconButton>
        )}
      </div>
      <div className={threadStyles.content}>
        <div className={styles.row}>
          <Avatar src={creator?.photoUrl || ""} className={styles.avatar} />
          <div>
            <Typography variant="body1">{name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {new Date(thread.createdAt).toLocaleString()}
            </Typography>
          </div>
        </div>
        <Typography variant="h3" fontWeight={600} sx={{ mb: 3 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap", mb: 6 }}>
          {description}
        </Typography>
      </div>
      <ConfirmModal
        isOpen={isDeleteOpen}
        title={t("forum.deleteTitle")}
        description={t("forum.deleteDescription")}
        accept={handleDelete}
        cancel={handleDeleteOpen(false)}
      />
    </div>
  );
};
