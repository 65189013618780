import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CircleOption, CircleOptionType, Loader } from "components";
import { intensityOptions, colorOptions } from "../../menstruation/menstruation-form.constants";
import { MenstruationModel } from "models";

import styles from "./menstruation-section.module.scss";

interface Props {
  menstruation: MenstruationModel | null;
  openMenstruationForm: () => void;
  loading?: boolean;
}

export const MenstruationSection: React.FC<Props> = ({ menstruation, openMenstruationForm, loading }) => {
  const { t } = useTranslation();

  const showMenstruationData = Boolean(menstruation);

  const currentIntensity = intensityOptions(t).find((intensity) => intensity.id === menstruation?.intensity);
  const currentColor = colorOptions(t).find((color) => color.value === menstruation?.color);

  const prepareOptionWithColorLabel = (intensity: CircleOptionType<number>) => {
    const name = `${currentIntensity?.name} - ${currentColor?.label || menstruation?.color}`;
    return { ...intensity, name };
  };

  return (
    <div className={styles.container}>
      <Typography variant="h4" fontWeight={600} sx={{ mb: 3 }}>
        {t("menstruation.title")}
      </Typography>
      <section className={styles.gridContainer}>
        {!showMenstruationData && !loading && (
          <Button variant="contained" onClick={openMenstruationForm} className={styles.button}>
            {t("common.fillInData")}
          </Button>
        )}
        {loading && (
          <>
            <div className={styles.loader}>
              <Loader />
            </div>
            <div className={styles.loader}>
              <Loader />
            </div>
          </>
        )}
        {showMenstruationData && !loading && menstruation && currentIntensity && (
          <>
            <CircleOption option={prepareOptionWithColorLabel(currentIntensity)} onChange={openMenstruationForm} />
            <Button variant="contained" onClick={openMenstruationForm} className={styles.button}>
              {t("common.editData")}
            </Button>
          </>
        )}
      </section>
    </div>
  );
};
