import React, { useContext } from "react";
import { Avatar, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { DragHandle, LocalMall } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import { GlobalSearch } from "components";
import { setMenuOpen } from "store";
import { useUser } from "hooks";
import { CART_PAGE, USER_PROFILE_PAGE } from "constants/routes.constants";
import { CheckoutContext } from "context";

import styles from "./navbar.module.scss";

interface Props {
  showSearch?: boolean;
}

export const Navbar: React.FC<Props> = ({ showSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated, user } = useUser();
  const { checkout } = useContext(CheckoutContext);

  const handleMenuOpen = () => dispatch(setMenuOpen(true));
  const navigateToProfile = () => navigate(USER_PROFILE_PAGE.path);

  const cartQuantity = checkout?.lines?.reduce((acc, prev) => acc + prev.quantity, 0);

  return (
    <header className={showSearch ? styles.searchContainer : styles.container}>
      {showSearch && <GlobalSearch />}
      <div className={styles.icons}>
        <Link to={CART_PAGE.path}>
          <IconButton className={styles.cartIcon}>
            <LocalMall />
            {Boolean(cartQuantity) && <div className={styles.cartQuantity}>{cartQuantity}</div>}
          </IconButton>
        </Link>
        {isAuthenticated && (
          <Avatar
            sx={{ width: "40px", height: "40px", cursor: "pointer" }}
            src={user?.photoUrl || ""}
            onClick={navigateToProfile}
          />
        )}
        <IconButton onClick={handleMenuOpen} className={styles.hamburger}>
          <DragHandle />
        </IconButton>
      </div>
    </header>
  );
};
