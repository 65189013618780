import React from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";

import { CheckoutTitle } from "pages/checkout";
import { ShippingMethodProps } from "./shipping-method.types";

import styles from "./shipping-method.module.scss";

export const ShippingMethod: React.FC<ShippingMethodProps> = ({
  changeStep,
  checkout,
  shippingMethod,
  setShippingMethod,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setShippingMethod(value);
  };

  const goBackToAddress = () => changeStep("address");

  const availableMethods = checkout?.availableShippingMethods || [];
  const showNoContent = availableMethods.length === 0;
  const showContent = availableMethods.length > 0;

  return (
    <div>
      <CheckoutTitle
        title={t("checkout.deliveryMethod")}
        description={t("checkout.shippingMethodDescription")}
        goBack={goBackToAddress}
        marginBottom="24px"
      />
      {showNoContent && (
        <div>
          <Typography>{t("checkout.noDeliveryMethodAvailable")}</Typography>
        </div>
      )}

      {showContent && (
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="shippingMethod"
          value={shippingMethod}
          onChange={handleChange}
        >
          {availableMethods.map((method) => (
            <FormControlLabel
              key={method.id}
              value={method.id}
              label={
                <div className={styles.row}>
                  <Typography variant="body1" fontWeight={500}>
                    {method.name}
                  </Typography>
                  <Typography variant="body2" fontWeight={700}>
                    {t("checkout.deliveryPrice", { amount: method.price.amount, currency: method.price.currency })}
                  </Typography>
                  {method.minimumDeliveryDays && method.maximumDeliveryDays && (
                    <Typography variant="subtitle1">
                      {t("checkout.deliveryTime", {
                        min: method.minimumDeliveryDays,
                        max: method.maximumDeliveryDays,
                      })}
                    </Typography>
                  )}
                </div>
              }
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      )}
    </div>
  );
};
