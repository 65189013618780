import { User } from "firebase/auth";
import { DataProp } from "editorjs-blocks-react-renderer";

import { UserModel } from "models";

type ObjectValue = Record<string, unknown>;

const convertValue = (value: unknown) => {
  if (value instanceof File || typeof value === "string") {
    return value;
  }

  return JSON.stringify(value);
};

export const mapToFormData = (obj: ObjectValue): FormData => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null) {
      formData.append(key, convertValue(value));
    }
  });

  return formData;
};

export const mapUserData = (user: User): UserModel => ({
  id: user.uid,
  photoUrl: user.photoURL,
  displayName: user.displayName,
  email: user.email,
  details: null,
});

export const parseQueryData = (jsonStringData?: string): DataProp | null => {
  if (!jsonStringData) {
    return null;
  }
  let data;
  try {
    data = JSON.parse(jsonStringData);
  } catch (e) {
    return null;
  }

  if (!data.blocks?.length) {
    // No data to render
    return null;
  }

  // Path for compatibility with data from older version od EditorJS
  if (!data.time) {
    data.time = Date.now().toString();
  }
  if (!data.version) {
    data.version = "2.22.2";
  }

  return data;
};
