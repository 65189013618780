import React, { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { intensityIcons, getDateClasses, getDayTooltip } from "./day.constants";
import { CalendarContext } from "pages/user/my-meno/calendar";
import { DayProps } from "./day.types";

import styles from "./day.module.scss";

export const Day: React.FC<DayProps> = ({ date, onDayClick, menstruationDetails, isReportCompleted, startFrom }) => {
  const { t } = useTranslation();
  const { selectedDate } = useContext(CalendarContext);

  const IntensityIcon = menstruationDetails ? intensityIcons[menstruationDetails.intensityId] : null;

  return (
    <div
      key={date.toLocaleString()}
      className={styles.dayWrapper}
      style={startFrom ? { gridColumnStart: startFrom } : { gridColumnStart: "inherit" }}
    >
      <Tooltip title={getDayTooltip({ t, date, selectedDate })}>
        <IconButton
          onClick={onDayClick(date)}
          className={getDateClasses({ date, selectedDate })}
          sx={{ mt: 2, width: "30px", height: "30px", fontSize: "16px", borderRadius: "50%", color: "#000" }}
        >
          {date.getDate()}
        </IconButton>
      </Tooltip>
      <div className={IntensityIcon ? styles.iconWrapper : styles.onlyCompletedWrapper}>
        {isReportCompleted && (
          <Tooltip title={t("myMeno.completedReportTooltip", { date: date.toLocaleDateString() })}>
            <div className={styles.completedDot} />
          </Tooltip>
        )}
        {IntensityIcon && menstruationDetails && (
          <Tooltip
            title={t("myMeno.menstruationCompleted", {
              date: date.toLocaleDateString(),
              intensity: menstruationDetails.intensityName,
              color: menstruationDetails.color,
            })}
          >
            <IntensityIcon />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
