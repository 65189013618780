import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Content } from "components";
import { QuestionAccordion } from "./question-accordion/question-accordion";
import { faq } from "./faq.constants";

import styles from "./faq.module.scss";

export const FaqPage: React.FC = () => {
  const { t } = useTranslation();

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleExpanded = (index: number) => () => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <Content>
      <Box>
        <div className={styles.wrapper} id="faq">
          <Typography variant="h4" color="text.primary" sx={{ mb: 3, mt: 6 }} fontWeight={600} fontSize={24}>
            {t("pages.faq")}
          </Typography>
          {faq(t).map(({ question, answer }, index) => (
            <QuestionAccordion
              key={question}
              question={question}
              answer={answer}
              isExpanded={expandedIndex === index}
              toggleExpand={toggleExpanded(index)}
            />
          ))}
        </div>
      </Box>
    </Content>
  );
};
