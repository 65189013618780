import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FAVOURITE_PAGE } from "constants/routes.constants";

import { ReactComponent as Heart } from "assets/icons/heart-color.svg";

import styles from "../user-profile.module.scss";

export const Favourites: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Link to={FAVOURITE_PAGE.path}>
      <div className={`${styles.wrapper} ${styles.row}`}>
        <Heart />
        <Typography variant="h5" color="text.primary">
          {t("pages.favourites")}
        </Typography>
      </div>
    </Link>
  );
};
