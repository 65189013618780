export const pushToStorageArray = <T>(key: string, value: T): T[] => {
  const storageItem = localStorage.getItem(key);

  if (storageItem) {
    const previousArray = JSON.parse(storageItem);

    if (previousArray && Array.isArray(previousArray)) {
      previousArray.push(value);
      localStorage.setItem(key, JSON.stringify(previousArray));
      return previousArray;
    }
    return [];
  }

  const newArray = [value];
  localStorage.setItem(key, JSON.stringify(newArray));
  return newArray;
};

export const removeFromStorageArray = <T>(key: string, value: T): T[] => {
  const storageItem = localStorage.getItem(key);

  if (storageItem) {
    const previousArray = JSON.parse(storageItem);

    if (previousArray && Array.isArray(previousArray)) {
      const newArray = previousArray.filter((arrayValue) => arrayValue !== value);
      localStorage.setItem(key, JSON.stringify(newArray));
      return newArray;
    }
    return [];
  }
  return [];
};

export const checkIsInArray = (key: string, value: unknown): boolean => {
  const storageItem = localStorage.getItem(key);
  if (!storageItem) return false;

  const parsedItem = JSON.parse(storageItem);

  if (parsedItem && Array.isArray(parsedItem)) {
    const foundItem = parsedItem.find((arrayItem) => arrayItem === value);
    return Boolean(foundItem);
  }

  return false;
};

export const getParsedStorageValue = <T>(key: string): T | null => {
  const storageItem = localStorage.getItem(key);

  if (storageItem) return JSON.parse(storageItem);
  return null;
};
