import React, { useMemo, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CreateForumThread } from "../create/create-forum-thread";
import { Content, NoContent, FetchingError, Loader, Select } from "components";
import { ThreadItem } from "./thread-item/thread-item";
import { useFirebaseFetch, useShowContent, useUser } from "hooks";
import { getAllThreads } from "firestore";
import { RootState } from "store";
import { LANDING_PAGE } from "constants/routes.constants";

import styles from "./forum-thread-list.module.scss";

export const ForumThreadListPage: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isAuthenticated } = useUser();
  const { categories } = useSelector((state: RootState) => state.categories);

  const [isCreateOpen, setCreateOpen] = useState<boolean>(false);
  const [category, setCategory] = useState<string>("");

  const threadsData = useFirebaseFetch(() => getAllThreads(category), { dependencies: [category] });
  const { showLoader, showContent, showNoContent, showError } = useShowContent(threadsData);
  const { data: threads, refresh } = threadsData;

  const handleCreateOpen = (isOpen: boolean) => () => setCreateOpen(isOpen);

  const goBack = () => navigate(LANDING_PAGE.path);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCategory(event.target.value);
  };

  const categoryOptions = useMemo(() => {
    return categories.map(({ attributes }) => ({ name: attributes.name, value: attributes.name }));
  }, [categories]);

  return (
    <Content>
      <div className={styles.header}>
        <div className={styles.row}>
          <IconButton onClick={goBack} sx={{ width: "40px", height: "40px" }}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h3" fontWeight={600}>
            {t("forum.forum")}
          </Typography>
        </div>
        <div className={styles.buttonRow}>
          <Select
            name="category"
            label={t("product.category")}
            options={categoryOptions}
            sx={{ width: "120px" }}
            size="small"
            value={category || ""}
            onChange={handleCategoryChange}
            showClear
          />
          {isAuthenticated && (
            <Button type="button" onClick={handleCreateOpen(true)}>
              {t("forum.createThreadTitle")}
            </Button>
          )}
        </div>
      </div>

      {showNoContent && (
        <div className={styles.stateContainer}>
          <NoContent />
        </div>
      )}
      {showError && (
        <div className={styles.stateContainer}>
          <FetchingError />
        </div>
      )}
      {showLoader && <Loader height="calc(100% - 100px)" />}
      {showContent && (
        <main className={styles.content}>
          {threads?.map((thread) => (
            <ThreadItem key={thread.id} thread={thread} />
          ))}
        </main>
      )}
      <CreateForumThread isOpen={isCreateOpen} handleClose={handleCreateOpen(false)} onSuccess={refresh} />
    </Content>
  );
};
