import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { useTranslation } from "react-i18next";
import { Button, TextField, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { Content, Loader, Select } from "components";
import { DoctorsCreate } from "./create/doctors-create";
import { DoctorsHeader } from "./doctors-header/doctors-header";
import { DoctorItem } from "./doctor-item/doctor-item";
import { useDebounce, usePagination, useQueryParams, useShowContent, useUser } from "hooks";
import { getPaginationParams } from "utils";
import { Voivodeship } from "types";
import { Specialization } from "models";
import { RootState } from "store";
import { getDoctors } from "server";
import { voivodeshipOptions } from "constants/voivodeship.constants";
import { specializationOptions } from "constants/specialization.constants";

import styles from "./doctors.module.scss";

const PAGE_SIZE = 15;

export const DoctorsPage: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();
  const { locale } = useSelector((state: RootState) => state.app);
  const { page, pageSize, onPageChange } = usePagination(PAGE_SIZE);
  const { updateQueryParams } = useQueryParams<{ page: string }>();

  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const [citySearch, setCitySearch] = useState<string>("");
  const [voivodeship, setVoivodeship] = useState<Voivodeship | null>(null);
  const [specialization, setSpecialization] = useState<Specialization | null>(null);
  const debouncedCitySearch = useDebounce(citySearch, 500);

  const doctorsData = useFetch(
    getDoctors.setQueryParams({
      "filters[city][$containsi]": debouncedCitySearch,
      "filters[specialization][$containsi]": specialization || "",
      "filters[voivodeship][$containsi]": voivodeship || "",
      sort: "createdAt:desc",
      populate: "*",
      locale,
      ...getPaginationParams({ page, pageSize }),
    }),
    { dependencies: [locale] },
  );
  const { showContent, showLoader, showNoContent, showError } = useShowContent(doctorsData);
  const { data: doctors } = doctorsData;

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
    updateQueryParams({ page: value.toString() });
  };

  const handleVoivodeshipChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setVoivodeship(event.target.value as Voivodeship);
  };

  const handleSpecializationChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSpecialization(event.target.value as Specialization);
  };

  const handleCitySearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCitySearch(event.target.value);
  };

  const handleAddDoctorOpen = (isOpen: boolean) => () => setAddOpen(isOpen);

  return (
    <Content>
      <DoctorsHeader
        count={doctors?.meta.pagination.pageCount || 0}
        page={page}
        onChange={handlePaginationChange}
        showPagination={Boolean(showContent && doctors)}
      >
        <div className={styles.row}>
          <TextField
            name="search"
            value={citySearch || ""}
            onChange={handleCitySearch}
            size="small"
            placeholder={t("doctors.city")}
            sx={{ width: "160px" }}
          />
          <Select
            name="category"
            label={t("doctors.voivodeship")}
            options={voivodeshipOptions}
            sx={{ width: "160px" }}
            size="small"
            value={voivodeship || ""}
            onChange={handleVoivodeshipChange}
            disabled={showLoader}
            showClear
          />
          <Select
            name="specialization"
            label={t("doctors.specialization")}
            options={specializationOptions}
            sx={{ width: "160px" }}
            size="small"
            value={specialization || ""}
            onChange={handleSpecializationChange}
            disabled={showLoader}
            showClear
          />
          {isAuthenticated && (
            <Button type="button" onClick={handleAddDoctorOpen(true)}>
              <Add />
              {t("doctors.addDoctor")}
            </Button>
          )}
        </div>
      </DoctorsHeader>
      <>
        {showLoader && <Loader height="100%" />}
        {showNoContent && (
          <div className={styles.emptyStateWrapper}>
            <Typography>{t("common.noContent")}</Typography>
          </div>
        )}
        {showError && (
          <div className={styles.emptyStateWrapper}>
            <Typography>{t("common.fetchError")}</Typography>
          </div>
        )}
        {showContent &&
          doctors?.data.map((doctor, index) => (
            <DoctorItem key={doctor.id} doctor={doctor} showBackground={index % 2 === 0} />
          ))}
      </>

      <DoctorsCreate isOpen={isAddOpen} handleClose={handleAddDoctorOpen(false)} />
    </Content>
  );
};
