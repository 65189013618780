import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { Loader } from "components";
import { ButtonsProps } from "./buttons.types";

import styles from "../checkout.module.scss";

export const Buttons: React.FC<ButtonsProps> = ({ confirm, back }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.buttons}>
      <Button
        variant="contained"
        onClick={confirm.action}
        type="submit"
        disabled={confirm.disabled}
        sx={{ color: "#fff", fontWeight: 600 }}
      >
        {confirm.loading && <Loader />}
        {!confirm.loading && (confirm.label || t("common.next"))}
      </Button>
      {back && (
        <Button variant="outlined" onClick={back.action} disabled={back.disabled}>
          {back.loading && <Loader />}
          {!back.loading && (back.label || t("common.back"))}
        </Button>
      )}
    </div>
  );
};
