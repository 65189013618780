import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

import { FormCheckboxTypes } from "./form-checkbox.types";

import styles from "./form-checkbox.module.css";

export const FormCheckbox = ({ name, label, value }: FormCheckboxTypes) => {
  const { t } = useTranslation();

  const [field, , { setValue }] = useField({ name });

  const handleChange = (e: React.ChangeEvent<any>, checked: boolean) => {
    if (checked) {
      setValue(value);
    }
  };

  return (
    <FormControlLabel
      className={styles.textInput}
      control={<Checkbox onChange={handleChange} onBlur={field.onBlur} checked={field.value === value} name={name} />}
      label={t<string>(label)}
    />
  );
};
