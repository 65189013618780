import { TFunction } from "i18next";

import { ReportOption } from "./report.types";
import { BrainIds, EnergyIds, PsychoIds, BodyIds, BeautyIds, SexIds, ReportModel } from "models";

import brain2 from "assets/icons-update/brain2.png";
import brain3 from "assets/icons-update/brain3.png";
import brain4 from "assets/icons-update/brain4.png";
import brain5 from "assets/icons-update/brain5.png";
import brain6 from "assets/icons-update/brain6.png";
import psyche1 from "assets/icons-update/psyche1.png";
import psyche2 from "assets/icons-update/psyche2.png";
import psyche3 from "assets/icons-update/psyche3.png";
import psyche4 from "assets/icons-update/psyche4.png";
import psyche5 from "assets/icons-update/psyche5.png";
import body1 from "assets/icons-update/body1.png";
import body2 from "assets/icons-update/body2.png";
import body3 from "assets/icons-update/body3.png";
import body4 from "assets/icons-update/body4.png";
import body5 from "assets/icons-update/body5.png";
import beauty1 from "assets/icons-update/beauty1.png";
import beauty2 from "assets/icons-update/beauty2.png";
import beauty3 from "assets/icons-update/beauty3.png";
import beauty4 from "assets/icons-update/beauty4.png";
import sex1 from "assets/icons-update/sex1.png";
// import sex2 from "assets/icons-update/sex2.png";
// import sex3 from "assets/icons-update/sex3.png";
// import sex4 from "assets/icons-update/sex4.png";
import sex5 from "assets/icons-update/sex5.png";
import sex6 from "assets/icons-update/sex6.png";
import sex7 from "assets/icons-update/sex7.png";
// import energyCharged from "assets/icons-update/energy1.png";
import energyFull from "assets/icons-update/energy2.png";
import energyMiddle from "assets/icons-update/energy3.png";
import energyLow from "assets/icons-update/energy4.png";
import noEnergy from "assets/icons-update/energy5.png";

export const brain = (t: TFunction): ReportOption<BrainIds>[] => [
  { id: "brain-fog", icon: brain6, name: t("myMeno.brainFog") },
  { id: "concentration-problems", icon: brain2, name: t("myMeno.concentrationProblems") },
  { id: "memory-problems", icon: brain3, name: t("myMeno.memoryProblems") },
  { id: "insomnia", icon: brain4, name: t("myMeno.insomnia") },
  { id: "headache", icon: brain5, name: t("myMeno.headache") },
];

export const psyche = (t: TFunction): ReportOption<PsychoIds>[] => [
  { id: "anxiety", icon: psyche1, name: t("myMeno.anxiety") },
  { id: "depression", icon: psyche2, name: t("myMeno.depression") },
  { id: "irritation", icon: psyche3, name: t("myMeno.irritation") },
  { id: "tearfulness", icon: psyche4, name: t("myMeno.tearfulness") },
  { id: "anger", icon: psyche5, name: t("myMeno.anger") },
];

export const body = (t: TFunction): ReportOption<BodyIds>[] => [
  { id: "muscle-aches", icon: body1, name: t("myMeno.muscleAches") },
  { id: "weight-gain", icon: body2, name: t("myMeno.weightGain") },
  { id: "flatulence", icon: body3, name: t("myMeno.flatulence") },
  { id: "water-retention", icon: body4, name: t("myMeno.waterRetention") },
  { id: "itching", icon: body5, name: t("myMeno.itching") },
];

export const beauty = (t: TFunction): ReportOption<BeautyIds>[] => [
  { id: "alopecia", icon: beauty1, name: t("myMeno.alopecia") },
  { id: "brittle-hair", icon: beauty2, name: t("myMeno.brittleHair") },
  { id: "brittle-nails", icon: beauty3, name: t("myMeno.brittleNails") },
  { id: "skin-dryness", icon: beauty4, name: t("myMeno.skinDryness") },
];

export const sex = (t: TFunction): ReportOption<SexIds>[] => [
  { id: "libido", icon: sex1, name: t("myMeno.libido") },
  { id: "vaginal-dryness", icon: sex5, name: t("myMeno.vaginalDryness") },
  { id: "vaginitis", icon: sex6, name: t("myMeno.vaginitis") },
  { id: "urinary-incontinence", icon: sex7, name: t("myMeno.urinaryIncontinence") },
];

export const energy = (t: TFunction): ReportOption<EnergyIds>[] => [
  { id: "no-power", icon: noEnergy, name: t("myMeno.noPower") },
  { id: "exhausted", icon: energyLow, name: t("myMeno.exhausted") },
  { id: "tired", icon: energyMiddle, name: t("myMeno.tired") },
  { id: "ok", icon: energyFull, name: t("myMeno.ok") },
];

export const reportInitialState = (report: ReportModel | null): ReportModel => ({
  beauty: report?.beauty || [],
  body: report?.body || [],
  brain: report?.brain || [],
  energy: report?.energy || null,
  psycho: report?.psycho || [],
  sex: report?.sex || [],
  creatorId: "",
});
