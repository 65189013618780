import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";

import { ChatbotConversation } from "./chatbot.types";
import { SavedAnswer } from "components/client/chatbot/chatbot.types";
import { getCollectionRef, firestoreCollections } from "config/firebase.config";

export const getUserConversation = async (email: string) => {
  if (email) {
    const collectionRef = getCollectionRef<ChatbotConversation>(firestoreCollections.chatbot);

    const documentRef = doc(collectionRef, email);

    return getDoc(documentRef);
  }
  return null;
};

export const postUserConversation = (email: string, data: SavedAnswer[], points: number | null) => {
  const collectionRef = getCollectionRef<ChatbotConversation>(firestoreCollections.chatbot);

  const documentRef = doc(collectionRef, email);

  const values: ChatbotConversation = { email, conversation: data, points };

  return setDoc(documentRef, values);
};

export const deleteUserConversation = (email: string) => {
  const collectionRef = getCollectionRef<ChatbotConversation>(firestoreCollections.chatbot);

  const documentRef = doc(collectionRef, email);

  return deleteDoc(documentRef);
};
