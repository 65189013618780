import { TFunction } from "i18next";
import { object, SchemaOf, string } from "yup";

import { ThreadValues } from "firestore";

export const threadInitialValues: ThreadValues = {
  title: "",
  description: "",
  category: "",
};

export const threadSchema = (t: TFunction): SchemaOf<ThreadValues> =>
  object().shape({
    title: string().required(t("common.required")),
    description: string().required(t("common.required")),
    category: string().required(t("common.required")),
  });
