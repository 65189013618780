import { builder } from "server/builder";
import { DoctorModel, DoctorServerValues } from "models";
import { StrapiData } from "types";

export const getDoctors = builder.createCommand<StrapiData<DoctorModel[]>>()({
  method: "GET",
  endpoint: "/api/doctors",
});

export const createDoctor = builder.createCommand<
  unknown,
  { data: DoctorServerValues & { publishedAt: string | null } }
>()({
  method: "POST",
  endpoint: "/api/doctors",
});
