import React from "react";

import { useQueryParams } from "hooks";
import { VerifyEmail } from "../verify-email/verify-email";
import { ConfirmPasswordReset } from "../confirm-password-reset/confirm-password-reset";
import { AuthModeParams } from "../auth.types";

export const ActionPage: React.FC = () => {
  const { query } = useQueryParams<AuthModeParams>();

  return (
    <>
      {query?.mode === "resetPassword" && <ConfirmPasswordReset />}
      {query?.mode === "verifyEmail" && <VerifyEmail />}
    </>
  );
};
