import React, { useState } from "react";

import { Content } from "components";
import { AdviceModal } from "modals/advice-modal/advice-modal";
import { ProfileDetails } from "./profile-details/profile-details";
import { Favourites } from "./favourites/favourites";
import { AdviceForm } from "./advice-form/advice-form";

import styles from "./user-profile.module.scss";

export const UserProfilePage: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleModalOpen = (isOpen: boolean) => () => setModalOpen(isOpen);

  return (
    <Content className={styles.container}>
      <ProfileDetails />
      <div className={styles.sideContainer}>
        <Favourites />
        <AdviceForm openModal={handleModalOpen(true)} />
      </div>

      <AdviceModal isOpen={isModalOpen} handleClose={handleModalOpen(false)} />
    </Content>
  );
};
