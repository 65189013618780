import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CategoryModel } from "models";
import { getLinkPath } from "utils";
import { BLOG_CATEGORY_PAGE } from "constants/routes.constants";

import styles from "./categories.module.scss";

interface Props {
  categories: CategoryModel[];
}

export const Categories: React.FC<Props> = ({ categories }) => {
  const { t } = useTranslation();

  const showNoContent = categories.length === 0;
  const showContent = Boolean(categories.length);

  return (
    <div className={styles.container}>
      <Typography variant="h3" fontWeight={600} sx={{ mb: 3 }}>
        {t("blog.categories")}
      </Typography>
      {showNoContent && <Typography color="primary.light">{t("blog.noCategories")}</Typography>}
      {showContent && (
        <div className={styles.row}>
          {categories.map((category) => (
            <Link key={category.id} to={getLinkPath(BLOG_CATEGORY_PAGE.path, { categoryId: category.id })}>
              <Button key={category.id} variant="outlined">
                {category.attributes.name}
              </Button>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
