export const pages = {
  home: "Strona główna",
  myMeno: "MojeMeno",
  blog: "Baza wiedzy",
  blogAll: "Wszystko",
  learn: "Dowiedz się",
  shop: "Mameno store",
  forum: "Forum",
  profile: "Profil",
  signIn: "Logowanie",
  signOut: "Wyloguj się",
  signUp: "Rejestracja",
  resetPassword: "Zresetuj hasło",
  products: "Produkty",
  product: "Produkt",
  blogPost: "Wpis na blogu",
  logOut: "Wyloguj się",
  favourites: "Zapisane",
  faq: "FAQ",
  cart: "Koszyk",
  checkout: "Podsumowanie",
  order: "Zamówienie",
  orderList: "Lista zamówień",
  paymentSummary: "Podsumowanie płatności",
  doctors: "Baza lekarzy",
  authAction: "Autoryzacja",
  contact: "Kontakt",
};
