export const common = {
  search: "Search",
  save: "Save",
  close: "Close",
  accept: "Accept",
  hide: "Hide",
  cancel: "Cancel",
  submit: "Submit",
  yes: "Yes",
  no: "No",
  pl: "Polish",
  en: "English",
  continue: "Continue",
  back: "Back",
  next: "Next",
  send: "Send",
  confirm: "Confirm",

  iAccept: "I accept",

  goToMain: "Go back to main page",

  termsOfService: "Terms of service",
  shopRules: "Shop terms of service",
  privacyPolicy: "Privacy policy",

  acceptTermsOfService: "Accept terms of service",
  acceptShopTermsOfService: "Accept shop terms of service",

  fillInData: "Fill in the data",
  editData: "Edit data",
  noContent: "No content",
  fetchingError: "Fetching error",
  fetchingErrorDescription: "Error during fetching",
  goToHomePage: "Go back to the home page",
  refresh: "Refresh",
  required: "This field is required",

  monthLabel: "Month",
  yearLabel: "Year",

  pages: "Pages",
  section: "Section {{name}}",

  articles: "Articles",
  products: "Products",
  categories: "Article categories",

  noResults: "No results",
  fetchError: "Fetch error",

  deleteAccountTitle: "Dou you really want to delete your account?",
  deleteAccountDescription: "You will not be able to undo this action.",
  deleteAccount: "Delete your account",

  clear: "Clear",

  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  daysShort: {
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
  },
};
