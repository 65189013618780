import React from "react";
import { Typography, IconButton } from "@mui/material";

import { OptionItemProps } from "./circle-option.types";

import styles from "./circle-option.module.scss";

export function CircleOption<T>({ option, onChange, selected }: OptionItemProps<T>) {
  const { id, icon: Icon, name } = option;
  const handleOptionClick = () => onChange(id);

  return (
    <div className={styles.content}>
      <IconButton onClick={handleOptionClick} className={styles.iconButton}>
        <div className={selected ? styles.selectedIcon : styles.icon}>
          {typeof Icon === "string" ? <img src={Icon} alt="Brain" className={styles.imageIcon} /> : <Icon />}
        </div>
      </IconButton>
      <Typography>{name}</Typography>
    </div>
  );
}
