import { combineReducers, configureStore } from "@reduxjs/toolkit";

import auth from "./reducers/auth";
import ui from "./reducers/ui";
import app from "./reducers/app";
import favourites from "./reducers/favourites";
import categories from "./reducers/categories";

const reducer = combineReducers({
  auth,
  ui,
  app,
  favourites,
  categories,
});

const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof reducer>;

export * from "./reducers/auth";
export * from "./reducers/ui";
export * from "./reducers/app";
export * from "./reducers/favourites";
export * from "./reducers/categories";

export { store };
