export const advice = {
  formSent: "Formularz został wysłany",
  formError: "Błąd podczas wysyłania formularza",

  adviceForm: "Formularz",
  tellYourStory: "Opowiedz swoją historię",

  name: "Imię",
  age: "Wiek",
  menopauseDiagnosed:
    "Czy stwierdzono u Ciebie lub podejrzewasz, że jesteś w okresie premenopauzy, perimenopauzy, postmenopauzy?",
  whatDidYouKnow: "Co wiedziałaś o menopauzie zanim rozpoczęłaś swoją podróż?",
  mostSurprising: "Co było dla ciebie najbardziej zaskakującym aspektem menopauzy?",
  howItsGoing: "Jak sobie radzisz/radziłaś z objawami menopauzy?",
  howDoesItImpact: "Jak menopauza wpłynęła na twoje życie osobiste i zawodowe?",
  adviceQuestion: "Jakiej rady udziliłabyś młodszym kobietom na temat tego, czego mogą się spodziewać w tym okresie?",

  privacyPolicy: "przetwarzanie danych osobowych*",
};
