import React from "react";
import { Typography } from "@mui/material";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { FetchingError, Loader } from "components";
import { Articles } from "../articles/articles";
import { useShowContent } from "hooks";
import { RootState } from "store";
import { CategoryModel } from "models";
import { getArticles } from "server";
import { getLinkPath, getPaginationParams } from "utils";
import { BLOG_CATEGORY_PAGE } from "constants/routes.constants";

interface Props {
  category: CategoryModel;
}

const ARTICLES_PER_CATEGORY = 4;

export const CategoryArticles: React.FC<Props> = ({ category }) => {
  const { locale } = useSelector((state: RootState) => state.app);

  const articlesData = useFetch(
    getArticles.setQueryParams({
      "filters[category]": category.id,
      sort: "createdAt:desc",
      populate: "*",
      locale,
      ...getPaginationParams({ page: 1, pageSize: ARTICLES_PER_CATEGORY }),
    }),
    { dependencies: [locale] },
  );
  const { showContent, showLoader, showNoContent, showError } = useShowContent(articlesData);
  const { data: articles } = articlesData;

  if (showNoContent) return null;

  return (
    <div>
      <Link to={getLinkPath(BLOG_CATEGORY_PAGE.path, { categoryId: category.id })}>
        <Typography
          variant="h3"
          fontWeight={600}
          color="text.primary"
          sx={{ mb: 3, my: 3, hyphens: "auto", wordBreak: "break-all" }}
        >
          {category.attributes.name}
        </Typography>
      </Link>
      {showLoader && <Loader height="300px" />}
      {showError && <FetchingError height="300px" />}
      {showContent && articles && <Articles articles={articles.data} />}
    </div>
  );
};
