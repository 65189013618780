import { useState } from "react";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";

import { useQueryParams } from "hooks";
import { PaginationReturnData } from "./use-pagination.types";

const DEFAULT_PER_PAGE = 10;

export const usePagination = (perPage?: number): PaginationReturnData => {
  const { query, updateQueryParams } = useQueryParams<{ page: string }>();

  const currentPage = query?.page ? +query.page : 1;

  const [page, setPage] = useState<number>(currentPage);
  const [pageSize, setPageSize] = useState<number>(perPage || DEFAULT_PER_PAGE);

  const onPageChange = (newPage: number) => setPage(newPage);
  const onPageSizeChange = (newPageSize: number) => setPageSize(newPageSize);

  useDidMount(() => {
    if (!query?.page || Number.isNaN(+query.page) || +query.page < 1) {
      updateQueryParams({ page: "1" });
    }
  });

  return {
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
  };
};
