import qs from "qs";

import { Nullable } from "types";
import { ArticleModel } from "models";
import { environment } from "config";
import { DEFAULT_LOCALE } from "constants/app.constants";

type NullableObject = Nullable<Record<string, unknown>>;

export const isEmptyObject = (obj: NullableObject): boolean => {
  return Boolean(obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype);
};

export const extractImageUrl = (article?: ArticleModel): string | null => {
  const url = environment.serverUrl;
  const imagePath = article?.attributes?.image?.data?.attributes?.url || null;

  return imagePath ? `${url}${imagePath}` : null;
};

export const mapLocale = (locale?: string | null) => {
  if (locale === "pl") return "pl-PL";
  return locale || DEFAULT_LOCALE;
};

export const prepareStrapiFilters = (idsArray: (string | number)[], options?: Record<string, unknown>): string => {
  return qs.stringify(
    {
      filters: {
        id: {
          $in: idsArray,
        },
      },
      ...options,
    },
    { encodeValuesOnly: true },
  );
};

type PaginationParams = { page: number | string; pageSize: number | string };

export const getPaginationParams = ({ page, pageSize }: PaginationParams) => {
  return {
    "pagination[page]": page,
    "pagination[pageSize]": pageSize,
  };
};

export const getFirstName = (displayName?: string | null) => {
  if (displayName) return displayName.split(" ")[0];
  return null;
};
