import React, { useState } from "react";
import { Formik, Form } from "formik";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useSnackbar } from "notistack";
import { Button, Typography } from "@mui/material";

import { FormTextField, Loader } from "components";
import { AuthLayout } from "../auth-layout/auth-layout";
import { resetPasswordSchema, resetPasswordValues } from "./confirm-password-reset.constants";
import { useQueryParams } from "hooks";
import { AuthModeParams } from "pages/auth/auth.types";
import { ConfirmPasswordResetData } from "./confirm-password-reset.types";
import { LOGIN_PAGE } from "constants/routes.constants";
import { auth } from "config/firebase.config";

import styles from "./confirm-password-reset.module.scss";

export const ConfirmPasswordReset: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { query } = useQueryParams<AuthModeParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useDidUpdate(
    () => {
      if (!query?.oobCode) {
        navigate(LOGIN_PAGE.path);
        enqueueSnackbar("Nieprawidłowy adres linku.", { variant: "error" });
      } else {
        verifyPasswordResetCode(auth, query.oobCode)
          .then((emailToResetPassword) => {
            setEmail(emailToResetPassword);
            setLoading(false);
          })
          .catch(() => {
            setEmail(null);
            setLoading(false);
          });
      }
    },
    [query],
    true,
  );

  const showLoader = loading;
  const showSuccess = !loading && Boolean(email);
  const showError = !loading && !email;

  const handleSubmit = (values: ConfirmPasswordResetData) => {
    if (query?.oobCode) {
      confirmPasswordReset(auth, query.oobCode, values.password)
        .then(() => {
          navigate(LOGIN_PAGE.path);
          enqueueSnackbar("Hasło zostało zresetowane.", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Nie udało się zresetować twojego hasła.", { variant: "error" });
        });
    } else {
      enqueueSnackbar("Kod wygasł lub jest nieprawidłowy.", { variant: "error" });
    }
  };

  return (
    <AuthLayout variant="reset-password">
      <div className={styles.container}>
        <Formik initialValues={resetPasswordValues} onSubmit={handleSubmit} validationSchema={resetPasswordSchema(t)}>
          <Form className={styles.form}>
            {showLoader && <Loader />}
            {showSuccess && (
              <div className={styles.formElements}>
                <Typography variant="h5" fontWeight={600}>
                  Zresetuj swoje hasło
                </Typography>
                <FormTextField name="password" type="password" label="Hasło" />
                <FormTextField name="repeatedPassword" type="password" label="Powtórz hasło" />
                <Button type="submit" variant="contained" sx={{ color: "#fff" }}>
                  Zresetuj swoje hasło
                </Button>
              </div>
            )}
            {showError && (
              <div>
                <Typography variant="body2">
                  Istnieje prawdopodobieństwo, że twój kod jest wygasł. Spróbuj zresetować hasło ponownie.
                </Typography>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </AuthLayout>
  );
};
