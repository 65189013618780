import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import { NoContentProps } from "./no-content.types";
import { LANDING_PAGE } from "constants/routes.constants";

import styles from "./no-content.module.css";

export const NoContent = memo(
  ({
    title = "",
    subtitle = "",
    btnText = "",
    size = "big",
    onClick,
    className = "",
    showButton = false,
    height,
  }: NoContentProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    const isHomePage = location.pathname === "/";

    const showBtn = showButton && (!isHomePage || onClick);
    const text = onClick ? btnText : t("common.goToHomePage");

    const subtitleText = subtitle || t("common.noContent");

    const handleClick = () => {
      if (onClick) {
        onClick();
      } else {
        navigate(LANDING_PAGE.path);
      }
    };

    return (
      <div className={`${styles.container} ${styles[size]} ${className}`} style={{ height }}>
        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 200 }}>
          {subtitleText}
        </Typography>
        {showBtn && <Button onClick={handleClick}>{text}</Button>}
      </div>
    );
  },
);
