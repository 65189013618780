import { Appearance } from "@stripe/stripe-js";

export const appearance: Appearance = {
  theme: "stripe",
  variables: {
    fontFamily: "Montserrat, sans-serif",
    colorBackground: "#f5f5f5",
    borderRadius: "8px",
  },
};
