export const common = {
  search: "Szukaj",
  save: "Zapisz",
  close: "Zamknij",
  accept: "Akceptuj",
  hide: "Schowaj",
  cancel: "Anuluj",
  submit: "Potwierdź",
  yes: "Tak",
  no: "Nie",
  pl: "Polski",
  en: "English",
  continue: "Kontynuuj",
  back: "Wstecz",
  next: "Dalej",
  send: "Wyślij",
  confirm: "Potwierdź",

  iAccept: "Akceptuję",

  goToMain: "Wróć na stronę główną",

  termsOfService: "Warunki korzystania z serwisu",
  shopRules: "Regulamin sklepu",
  privacyPolicy: "Politykę prywatności",

  acceptTermsOfService: "Zaakceptuj warunki korzystania z serwisu",
  acceptShopTermsOfService: "Zaakceptuj regulamin sklepu",

  fillInData: "Uzupełnij dane",
  editData: "Zaktualizuj dane",
  noContent: "Brak wyników",
  fetchingError: "Błąd podczas pobierania danych",
  fetchingErrorDescription: "Wystąpił błąd podczas pobierania danych",
  goToHomePage: "Powrót na stronę główną",
  refresh: "Odśwież",
  required: "To pole jest wymagane",

  monthLabel: "Miesiąc",
  yearLabel: "Rok",

  pages: "Strony",
  section: "Sekcja: {{name}}",

  articles: "Artykuły",
  products: "Produkty",
  categories: "Kategorie artykułów",

  noResults: "Brak wyników",
  fetchError: "Błąd ładowania",

  deleteAccount: "Usuń swoje konto",
  deleteAccountTitle: "Na pewno chcesz usunąć swoje konto?",
  deleteAccountDescription:
    "Wszystkie dane zostaną usunięte i nie będziesz w stanie aktywować swojego konta w przyszłości.",

  clear: "Wyczyść",

  months: {
    january: "Styczeń",
    february: "Luty",
    march: "Marzec",
    april: "Kwiecień",
    may: "Maj",
    june: "Czerwiec",
    july: "Lipiec",
    august: "Sierpień",
    september: "Wrzesień",
    october: "Październik",
    november: "Listopad",
    december: "Grudzień",
  },
  days: {
    monday: "Poniedziałek",
    tuesday: "Wtorek",
    wednesday: "Środa",
    thursday: "Czwartek",
    friday: "Piątek",
    saturday: "Sobota",
    sunday: "Niedziela",
  },
  daysShort: {
    monday: "Pon",
    tuesday: "Wt",
    wednesday: "Śr",
    thursday: "Czw",
    friday: "Pt",
    saturday: "Sob",
    sunday: "Nie",
  },
};
