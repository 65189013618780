import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { Button, Typography } from "@mui/material";

import { FormSelect, FormTextField, Modal } from "components";
import { postThread, ThreadValues } from "firestore";
import { RootState } from "store";
import { CreateForumThreadProps } from "./create-forum-thread.types";
import { LOGOUT_PAGE } from "constants/routes.constants";
import { threadInitialValues, threadSchema } from "./create-forum-thread.constants";

import styles from "./create-forum-thread.module.scss";

export const CreateForumThread: React.FC<CreateForumThreadProps> = ({ isOpen, handleClose, onSuccess }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { categories } = useSelector((state: RootState) => state.categories);

  const handleSubmit = async (values: ThreadValues) => {
    if (user) {
      try {
        await postThread(user, values);
        enqueueSnackbar(t("forum.createThreadSuccess"), { variant: "success" });
        handleClose();
        onSuccess();
      } catch (error) {
        enqueueSnackbar(t("forum.createThreadError"), { variant: "error" });
      }
    } else {
      enqueueSnackbar(t("forum.createThreadAuthError"), { variant: "error" });
      navigate(LOGOUT_PAGE.path);
    }
  };

  const categoryOptions = useMemo(() => {
    return categories.map(({ attributes }) => ({ label: attributes.name, value: attributes.name }));
  }, [categories]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Formik<ThreadValues>
        initialValues={threadInitialValues}
        onSubmit={handleSubmit}
        validationSchema={threadSchema(t)}
      >
        <div className={styles.container}>
          <Typography variant="h5" fontWeight={600} sx={{ mb: 2 }}>
            {t("forum.createThreadTitle")}
          </Typography>
          <Form className={styles.form}>
            <FormTextField name="title" label={t("forum.threadTitle")} />
            <div>
              <Typography variant="caption" color="text.secondary" sx={{ mb: 0 }}>
                {t("forum.threadContent")}
              </Typography>
              <FormTextField
                name="description"
                label=""
                placeholder={t("forum.threadContent")}
                multiline
                inputProps={{ maxLength: 1000 }}
                minRows={5}
                maxRows={8}
                fullWidth
              />
              <FormSelect name="category" label={t("forum.category")} options={categoryOptions} />
            </div>

            <Button type="submit" variant="contained" sx={{ color: "#fff" }}>
              {t("common.send")}
            </Button>
          </Form>
        </div>
      </Formik>
    </Modal>
  );
};
