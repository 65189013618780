import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { ProductListHeader } from "./product-list-header/product-list-header";
import { Content, FetchingError, Loader, NoContent, Product } from "components";
import { GET_LATEST_PRODUCTS, ProductsResponse, DefaultProduct } from "graphql/product";
import { DEFAULT_CHANNEL } from "constants/app.constants";

import styles from "./product-list.module.scss";

const MAX_PRODUCT_ITEMS = 15;
const MAX_CATEGORIES_ITEMS = 30;

export const ProductListPage: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [lastCursor, setLastCursor] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedCollection, setSelectedCollection] = useState<string | null>(null);

  const showTemporaryDisabled = true;

  const { data, loading, error } = useQuery<ProductsResponse<DefaultProduct>>(GET_LATEST_PRODUCTS, {
    variables: {
      first: MAX_PRODUCT_ITEMS,
      after: lastCursor,
      channel: DEFAULT_CHANNEL,
      search: "",
      collection: selectedCollection || null,
      category: selectedCategory || null,
    },
  });

  const products = data?.products.edges || [];

  useDidUpdate(() => {
    const lastIndex = products.length - 1;
    const lastProduct = products[lastIndex];

    if (lastProduct) setLastCursor(lastProduct.cursor);
  }, [products]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const showContent = products.length > 0 && !loading && !error;
  const showNoContent = products.length === 0 && !loading && !error;
  const showError = error && !loading;

  const count = data?.products.pageInfo.hasNextPage ? page * MAX_PRODUCT_ITEMS : undefined;

  if (showTemporaryDisabled) {
    return (
      <Content>
        <div className={styles.wrapper}>
          <div>
            <Typography variant="h4" className={styles.title}>
              Sklep dostępny wkrótce!
            </Typography>
          </div>
        </div>
      </Content>
    );
  }

  return (
    <Content>
      <ProductListHeader
        page={page}
        handlePaginationChange={handlePaginationChange}
        showContent={showContent}
        loading={loading}
        maxCategories={MAX_CATEGORIES_ITEMS}
        count={count}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
      />

      {loading && <Loader height="80vh" />}
      {showError && (
        <div className={styles.emptyStateWrapper}>
          <FetchingError />
        </div>
      )}
      {showNoContent && (
        <div className={styles.emptyStateWrapper}>
          <NoContent />
        </div>
      )}
      {showContent && (
        <section className={styles.container}>
          {products.map((product) => {
            const { id, name, description, thumbnail, pricing } = product.node;
            const { amount, currency } = pricing.priceRange.start.gross;

            return (
              <Product
                key={id}
                id={id}
                name={name}
                description={description}
                image={thumbnail.url}
                alt={thumbnail.alt}
                price={amount}
                currency={currency}
              />
            );
          })}
        </section>
      )}
    </Content>
  );
};
