import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore, collection, CollectionReference } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

import { environment } from "config/environment.config";

const firebaseConfig = {
  apiKey: environment.firebaseApiKey,
  authDomain: environment.firebaseAuthDomain,
  projectId: environment.firebaseProjectId,
  storageBucket: environment.firebaseStorageBucket,
  messagingSenderId: environment.firebaseMessagingSender,
  appId: environment.firebaseAppId,
  measurementId: environment.firebaseMeasurementId,
};

const firestoreCollections = {
  users: "users",
  reports: "reports",
  menstruations: "menstruations",
  chatbot: "chatbot",
  threads: "threads",
  replies: "replies",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

const getCollectionRef = <D>(collectionName: string) => collection(firestore, collectionName) as CollectionReference<D>;
const getStorageRef = (url: string) => ref(storage, url);

export { auth, analytics, firestore, getCollectionRef, getStorageRef, firestoreCollections };
