export const home = {
  welcomeNameTitle: "Hi there, {{name}}!",
  welcomeTitle: "Hi there!",
  welcomeQuestion: "How do you feel today?",

  fine: "I am fine",
  irritability: "I am irritated",
  sad: "I am sad",
  insomnia: "I slept bad",
  bodyHurts: "My body hurts",

  alreadyInStore: "Already in \n the store",
  myMeno: "My meno",
  whatsNew: "What's new?",
  howCanI: "How can I...",

  lookOnTheBlog: "Find out more",
  completeYourReport: "Complete your daily report",
};
