import React, { useContext, useMemo, useState } from "react";
import Blocks from "editorjs-blocks-react-renderer";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuItem, TextField, Typography, Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { Content, Loader, FetchingError, NoContent } from "components";
import { parseQueryData } from "utils";
import { RootState } from "store";
import { ProductResponse, GET_SINGLE_PRODUCT } from "graphql/product";
import {
  ADD_PRODUCT_TO_CHECKOUT,
  AddProductVariables,
  CREATE_CHECKOUT,
  CreateCheckoutResponse,
  CreateCheckoutVariables,
} from "graphql/checkout";
import { CheckoutContext } from "context";
import { DEFAULT_CHANNEL, DEFAULT_PL_LOCALE } from "constants/app.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { CART_PAGE } from "constants/routes.constants";

import styles from "./product-details.module.scss";

type Params = { productId: string };

export const ProductDetailsPage: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { productId } = useParams<Params>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { checkout, checkoutToken, setCheckoutToken } = useContext(CheckoutContext);

  const [currentVariant, setCurrentVariant] = useState<string | null>(null);

  const navigateToCart = () => navigate(CART_PAGE.path);
  const onCartCreateSuccess = (data: CreateCheckoutResponse) => {
    const checkoutError = data?.checkoutCreate?.errors[0];
    if (checkoutError) {
      enqueueSnackbar(checkoutError.message, { variant: "error" });
    }

    const token = data?.checkoutCreate?.checkout?.token;
    if (token) {
      setCheckoutToken(token);
      localStorage.setItem(STORAGE_FIELDS.checkout_token, token);
      navigateToCart();
    }
  };

  const [createCheckout] = useMutation<CreateCheckoutResponse, CreateCheckoutVariables>(CREATE_CHECKOUT, {
    onCompleted: onCartCreateSuccess,
  });
  const [addProductToCheckout] = useMutation<any, AddProductVariables>(ADD_PRODUCT_TO_CHECKOUT, {
    onCompleted: navigateToCart,
  });

  const { data, loading, error } = useQuery<ProductResponse>(GET_SINGLE_PRODUCT, {
    variables: { id: productId, channel: DEFAULT_CHANNEL },
  });

  const selectedVariant = useMemo(() => {
    if (data && currentVariant) {
      return data.product.variants.find((variant) => variant.id === currentVariant);
    }
    return null;
  }, [data, currentVariant]);

  const handleVariantChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentVariant(event.target.value);
  };

  const addProductToCart = async () => {
    if (!currentVariant) {
      enqueueSnackbar(t("product.selectVariant"), { variant: "warning" });
      return;
    }

    if (checkout) {
      await addProductToCheckout({
        variables: { checkoutToken, variantId: currentVariant, locale: DEFAULT_PL_LOCALE },
      });
    } else {
      await createCheckout({
        variables: {
          email: user?.email || "",
          channel: DEFAULT_CHANNEL,
          lines: [{ quantity: 1, variantId: currentVariant }],
        },
      });
    }
  };

  const availableVariants = useMemo(() => {
    return data?.product.variants || [];
  }, [data]);

  const showNoContent = !data?.product;

  const blocks = parseQueryData(data?.product?.description);

  // const selectedVariant = availableVariants.find((variant) => variant.id === currentVariant);
  const attributes = data?.product?.attributes.concat(selectedVariant?.attributes || []) || [];
  const showDefaultVariant = currentVariant && availableVariants.length === 1;

  useDidUpdate(
    () => {
      if (availableVariants.length >= 1) {
        setCurrentVariant(availableVariants[0].id);
      }
    },
    [availableVariants],
    true,
  );

  const price = selectedVariant?.pricing.price.gross.amount || data?.product.pricing.priceRange.start.gross.amount;
  const currency =
    selectedVariant?.pricing.price.gross.currency || data?.product.pricing.priceRange.start.gross.currency;

  return (
    <Content showSearch={false}>
      {loading && <Loader height="100%" />}
      {error && <FetchingError height="100%" />}
      {showNoContent && !error && <NoContent height="100%" />}
      {data && data?.product && (
        <div className={styles.container}>
          <div className={styles.mainContent}>
            <img src={data.product?.thumbnail?.url || ""} alt={data.product?.thumbnail?.alt} className={styles.image} />
            <div className={styles.content}>
              <div>
                <Typography variant="h4" fontWeight={600} className={styles.name}>
                  {data.product?.name}
                </Typography>
                {blocks && (
                  <Blocks
                    data={blocks}
                    config={{
                      paragraph: {
                        className: styles.text,
                      },
                    }}
                  />
                )}
                <Typography variant="h5" fontWeight={500} sx={{ my: 3 }}>
                  {price} {currency}
                </Typography>
              </div>

              <div>
                {showDefaultVariant && (
                  <Typography sx={{ mb: 2 }}>
                    {t("product.selectedVariant", { variant: availableVariants[0].name })}
                  </Typography>
                )}
                {!showDefaultVariant && (
                  <TextField
                    label={t("product.variant")}
                    className={styles.textInput}
                    select
                    value={currentVariant || ""}
                    defaultValue={currentVariant || ""}
                    onChange={handleVariantChange}
                  >
                    {availableVariants.map((variant) => (
                      <MenuItem key={variant.id} value={variant.id}>
                        <Typography variant="body2">{variant.name}</Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                <Button variant="contained" onClick={addProductToCart} sx={{ color: "#fff", fontWeight: 600 }}>
                  {t("product.addToCart")}
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.details}>
            <Typography variant="body1" fontWeight={600} sx={{ my: 3 }}>
              {t("product.attributes")}
            </Typography>
            {attributes.map(({ attribute, values }) => {
              const attributeValues = values.map((value) => value.name).join(", ");

              return (
                <Grid container gap={4}>
                  <Typography color="text.secondary">{attribute.name}</Typography>
                  <Typography color="text.secondary">{attributeValues}</Typography>
                </Grid>
              );
            })}
          </div>
        </div>
      )}
    </Content>
  );
};
