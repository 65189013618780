import React from "react";

import { AuthLayout } from "../auth-layout/auth-layout";
import { ResetPasswordForm } from "./reset-password-form/reset-password-form";

export const ResetPasswordPage: React.FC = () => {
  return (
    <AuthLayout variant="reset-password">
      <ResetPasswordForm />
    </AuthLayout>
  );
};
