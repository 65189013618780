import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";
import { deleteUser } from "firebase/auth";
import { deleteObject } from "firebase/storage";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

// import { UpdateDetails } from "../update-details/update-details";
// import { detailsRenderer } from "./profile-details.constants";
import { ConfirmModal } from "components";
import { ProfileAvatar } from "../profile-avatar/profile-avatar";
import { useUser } from "hooks";
import { removeUserAccount } from "firestore";
import { auth, getStorageRef } from "config/firebase.config";
import { LOGOUT_PAGE } from "constants/routes.constants";

import styles from "../user-profile.module.scss";
import profileStyles from "./profile-details.module.scss";

export const ProfileDetails: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useUser();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  // const [isUpdateOpen, setUpdateOpen] = useState<boolean>(false);

  // const handleUpdateOpen = (isOpen: boolean) => () => setUpdateOpen(isOpen);

  // const showDetails = Boolean(user?.details);

  const handleDeleteModalOpen = (isOpen: boolean) => () => setShowDeleteModal(isOpen);

  const handleAccountDelete = () => {
    if (user && auth.currentUser) {
      deleteUser(auth.currentUser)
        .then(() => {
          if (user.email) {
            removeUserAccount(user.id, user.email).finally(() => {
              enqueueSnackbar("Konto zostało usunięte", { variant: "success" });
              navigate(LOGOUT_PAGE.path);
              setShowDeleteModal(false);
            });

            const storageRef = getStorageRef(user.email);
            deleteObject(storageRef).then();
          }
        })
        .catch(() => {
          enqueueSnackbar("Wystąpił błąd podczas usuwania konta", { variant: "error" });
        });
    }
  };

  return (
    <section className={styles.profile}>
      <div className={profileStyles.wrapper}>
        <ProfileAvatar />
        <Typography variant="h4" fontWeight={600} sx={{ mt: 2 }}>
          {user?.displayName}
        </Typography>
      </div>
      {/* <div className={profileStyles.wrapper}> */}
      {/*  {showDetails && */}
      {/*    user && */}
      {/*    detailsRenderer(t).map(({ name, key, suffix }) => ( */}
      {/*      <section key={key} className={profileStyles.data}> */}
      {/*        <Typography variant="h5" fontWeight={600}> */}
      {/*          {name} */}
      {/*        </Typography> */}
      {/*        <Typography variant="h5"> */}
      {/*          {user.details?.[key]} */}
      {/*          {suffix} */}
      {/*        </Typography> */}
      {/*      </section> */}
      {/*    ))} */}
      {/*  <Button variant="contained" onClick={handleUpdateOpen(true)} sx={{ color: "#fff", fontWeight: 600, mt: 4 }}> */}
      {/*    {t("myMeno.updateDetailsTitle")} */}
      {/*  </Button> */}
      <div className={profileStyles.wrapper}>
        <Button
          variant="contained"
          onClick={handleDeleteModalOpen(true)}
          sx={{ color: "#fff", fontWeight: 600, mt: 4 }}
        >
          {t("common.deleteAccount")}
        </Button>
      </div>
      <ConfirmModal
        isOpen={showDeleteModal}
        accept={handleAccountDelete}
        cancel={handleDeleteModalOpen(false)}
        title={t("common.deleteAccountTitle")}
        description={t("common.deleteAccountDescription")}
      />
      {/* </div> */}
      {/* <UpdateDetails isOpen={isUpdateOpen} handleClose={handleUpdateOpen(false)} /> */}
    </section>
  );
};
