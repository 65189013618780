import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Buttons, ButtonValues } from "pages/checkout";
import { ShippingMethod } from "./shipping-method/shipping-method";
import { PaymentMethod } from "./payment-method/payment-method";
import { DeliveryPaymentProps } from "./delivery-payment.types";
import { UPDATE_SHIPPING_METHOD, UpdateShippingMethodResponse, UpdateShippingMethodVariables } from "graphql/checkout";
import { DEFAULT_PL_LOCALE } from "constants/app.constants";
import { CheckoutContext } from "context";

import checkoutStyles from "../checkout.module.scss";

export const DeliveryPayment: React.FC<DeliveryPaymentProps> = ({ checkout, changeStep }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { paymentMethod } = useContext(CheckoutContext);

  const [shippingMethod, setShippingMethod] = useState<string | null>(checkout.shippingMethod?.id || null);

  const [updateShippingMethod, { loading }] = useMutation<UpdateShippingMethodResponse, UpdateShippingMethodVariables>(
    UPDATE_SHIPPING_METHOD,
  );

  const handleSubmit = async () => {
    if (shippingMethod && paymentMethod) {
      try {
        const updateResponse = await updateShippingMethod({
          variables: { token: checkout.token, shippingMethodId: shippingMethod, locale: DEFAULT_PL_LOCALE },
        });

        if (updateResponse?.errors && updateResponse.errors.length > 0) {
          enqueueSnackbar(t("checkout.actions.deliverySaveError"), { variant: "error" });
        } else {
          changeStep("summary");
          enqueueSnackbar(t("checkout.actions.deliverySaveSuccess"), { variant: "success" });
        }
      } catch (error) {
        enqueueSnackbar(t("checkout.actions.deliverySaveError"), { variant: "error" });
      }
    }
  };

  const goBackToAddress = () => changeStep("address");

  const disabled = loading || !shippingMethod || !paymentMethod;
  const confirmButton: ButtonValues = { loading, disabled, action: handleSubmit };
  const backButton: ButtonValues = { action: goBackToAddress };

  return (
    <div className={checkoutStyles.container}>
      <div className={checkoutStyles.content}>
        <ShippingMethod
          changeStep={changeStep}
          checkout={checkout}
          shippingMethod={shippingMethod}
          setShippingMethod={setShippingMethod}
        />
        <PaymentMethod checkout={checkout} />
      </div>
      <Buttons confirm={confirmButton} back={backButton} />
    </div>
  );
};
