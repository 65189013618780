import React from "react";
import { IconButton, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

import { CheckoutTitleProps } from "./checkout-title.types";

import styles from "./checkout-title.module.scss";

export const CheckoutTitle: React.FC<CheckoutTitleProps> = ({ title, description, goBack, marginBottom }) => {
  return (
    <div style={{ marginBottom: marginBottom || "48px" }}>
      <div className={styles.row}>
        <IconButton onClick={goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4" fontWeight={700}>
          {title}
        </Typography>
      </div>
      <Typography variant="subtitle1" color="text.secondary">
        {description}
      </Typography>
    </div>
  );
};
