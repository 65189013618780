import { SchemaOf, string, object } from "yup";
import { TFunction } from "i18next";

import { CheckoutAddressValues, UserAddressDetails } from "./address.types";
import { AddressDetails, CheckoutDetails } from "graphql/checkout";
import { UserModel } from "models";

export const addressInitialValues = (address?: AddressDetails | null) => {
  return {
    city: address?.city || "",
    cityArea: address?.cityArea || "",
    companyName: address?.companyName || "",
    country: address?.country.code || "PL",
    countryArea: address?.countryArea || "",
    firstName: address?.firstName || "",
    lastName: address?.lastName || "",
    phone: address?.phone || "",
    postalCode: address?.postalCode || "",
    streetAddress1: address?.streetAddress1 || "",
    streetAddress2: address?.streetAddress2 || "",
  };
};

type ValuesArguments = { checkout: CheckoutDetails; user: UserModel | null };

export const initialValues = ({ checkout, user }: ValuesArguments): CheckoutAddressValues => {
  return {
    email: checkout?.email || user?.email || "",
    billingAddress: addressInitialValues(checkout?.billingAddress),
    shippingAddress: addressInitialValues(checkout?.shippingAddress),
  };
};

export const PL_NUMBER_REGEX =
  /^(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}$/;
// const PL_NUMBER_REGEX = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/g;
const PL_POSTAL_CODE_REGEX = /^\d{2}-\d{3}/;

const userAddressSchema = (t: TFunction): SchemaOf<UserAddressDetails> =>
  object().shape({
    city: string().required(t("checkout.validation.required")).min(2),
    cityArea: string(),
    companyName: string(),
    country: string().required(t("checkout.validation.required")),
    countryArea: string(),
    firstName: string().required(t("checkout.validation.required")),
    lastName: string().required(t("checkout.validation.required")),
    phone: string().required(t("checkout.validation.required")).matches(PL_NUMBER_REGEX, "Phone number is invalid"),
    postalCode: string()
      .required(t("checkout.validation.required"))
      .matches(PL_POSTAL_CODE_REGEX, "Postal code is invalid"),
    streetAddress1: string().required(t("checkout.validation.required")),
    streetAddress2: string(),
  });

export const addressValuesSchema = (t: TFunction): SchemaOf<CheckoutAddressValues> =>
  object().shape({
    email: string().email(t("checkout.validation.invalidEmail")).required(t("checkout.validation.emailRequired")),
    billingAddress: userAddressSchema(t),
    shippingAddress: userAddressSchema(t),
  });

export const inputStyles = { backgroundColor: "#fff", borderRadius: "8px" };
