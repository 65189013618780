import React, { useState } from "react";

import { RegisterForm } from "./register-form/register-form";
import { RegisterVerifyEmail } from "./register-verify-email/register-verify-email";
import { AuthLayout } from "pages/auth/auth-layout/auth-layout";

export const RegisterPage: React.FC = () => {
  const [showVerify, setShowVerify] = useState<boolean>(false);

  const showVerifyScreen = () => setShowVerify(true);

  return (
    <AuthLayout variant="register">
      {showVerify && <RegisterVerifyEmail />}
      {!showVerify && <RegisterForm showVerifyScreen={showVerifyScreen} />}
    </AuthLayout>
  );
};
