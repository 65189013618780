export const forum = {
  availableSoon: "Forum dostępne wkrótce!",

  createThreadSuccess: "Nowy wątek został utworzony",
  createThreadError: "Nie udało się utworzyć nowego wątku",
  createThreadAuthError: "Nie udało się pobrać danych o użytkowniku. Zaloguj się ponownie.",

  forum: "Forum",
  createThreadTitle: "Utworz nowy wątek na forum",
  threadTitle: "Tytuł",
  threadContent: "Treść",

  goBack: "Wróć do forum",

  category: "Kategoria",

  replyAdded: "Odpowiedź została dodana",
  replyAuthError: "Nie udało się zapisać danych o użytkowniku, zaloguj się ponownie.",
  fillReply: "Uzupełnij treść odpowiedzi",
  usersAnswers: "Odpowiedzi użytkowników",
  noContent: "Brak udzielonych odpowiedzi.",
  fetchError: "Błąd ładowania danych.",
  writeYourReply: "Napisz swoją odpowiedź",

  logInToReply: "Zaloguj się aby móc odpowiadać",

  deleteTitle: "Czy na pewno chcesz usunąć ten wątek?",
  deleteDescription:
    "Wątek wraz z odpowiedziami zostanie usunięty i nie będziesz mogła cofnąć tej decyzji. Czy chcesz kontynuować?",
};
