import { eachDayOfInterval, endOfWeek, getWeek, startOfWeek } from "date-fns";

export const getWeekDetails = (date: Date, offset: number) => {
  const newDate = new Date(date);
  const weekDaysOffset = 7 * offset;

  newDate.setDate(newDate.getDate() + weekDaysOffset);

  const weekStartDate = startOfWeek(newDate, { weekStartsOn: 1 });
  const weekEndDate = endOfWeek(newDate, { weekStartsOn: 1 });

  const weekDays = eachDayOfInterval({ start: weekStartDate, end: weekEndDate });
  const weekOfYearNumber = getWeek(newDate, { weekStartsOn: 1 });

  return {
    weekStartDate,
    weekEndDate,
    weekDays,
    weekOfYearNumber,
  };
};
