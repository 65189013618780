import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, ListItem, ListItemText } from "@mui/material";
import { useDispatch } from "react-redux";

import { SidebarSectionItem } from "./sidebar-section-item";
import { setMenuOpen } from "store";
import { SidebarSectionProps } from "./sidebar-section.types";
import { useAnalytics, useUser } from "hooks";

import styles from "../sidebar.module.scss";

export const SidebarSection: React.FC<SidebarSectionProps> = ({
  sectionName,
  icon: Icon,
  requireAuth,
  items,
  path,
}) => {
  const dispatch = useDispatch();

  const { logEvent } = useAnalytics();
  const { isAuthenticated } = useUser();

  const [isCollapseOpen, setCollapseOpen] = useState<boolean>(true);

  const handleSidebarClick = () => {
    logEvent("sidebar_click", { section: sectionName });
    dispatch(setMenuOpen(false));
  };

  const handleOptionsToggle = () => {
    setCollapseOpen((isOpen) => !isOpen);
  };

  if (requireAuth && !isAuthenticated) return null;

  if (path) {
    return (
      <NavLink to={path} className={({ isActive }) => (isActive ? styles.active : "")} onClick={handleSidebarClick}>
        <SidebarSectionItem sectionName={sectionName} icon={Icon} />
      </NavLink>
    );
  }

  return (
    <>
      <SidebarSectionItem sectionName={sectionName} icon={Icon} handleToggle={handleOptionsToggle} />
      <Collapse in={isCollapseOpen}>
        {items.map(({ name, path: itemPath }) => (
          <NavLink
            to={itemPath}
            onClick={handleSidebarClick}
            key={name}
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            <ListItem sx={{ color: "text.primary", height: "48px", pl: 8 }}>
              <ListItemText primary={name} primaryTypographyProps={{ fontSize: "13px" }} />
            </ListItem>
          </NavLink>
        ))}
      </Collapse>
    </>
  );
};
