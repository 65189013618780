import React, { useState } from "react";
import { ClickAwayListener } from "@mui/material";

import { FormSearchInput } from "components/form";
import { GlobalSearchResults } from "./global-search-results/global-search-results";
import { useDebounce } from "hooks";

export const GlobalSearch: React.FC = () => {
  const [isFocused, setFocused] = useState<boolean>(false);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const searchValue = useDebounce(searchPhrase, 400);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  };

  const handleFocus = (focus: boolean) => () => setFocused(focus);

  const resetState = () => {
    handleFocus(false)();
    setSearchPhrase("");
  };

  return (
    <ClickAwayListener onClickAway={handleFocus(false)}>
      <div style={{ width: "90%" }}>
        <FormSearchInput onChange={handleChange} onFocus={handleFocus(true)}>
          {isFocused && <GlobalSearchResults searchPhrase={searchValue} resetState={resetState} />}
        </FormSearchInput>
      </div>
    </ClickAwayListener>
  );
};
