import { TFunction } from "i18next";
import { SchemaOf, string, object } from "yup";
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";

import { LoginData, ProviderObject } from "./login-form.types";

export const initialValues: LoginData = {
  email: "",
  password: "",
};

export const loginSchema = (t: TFunction): SchemaOf<LoginData> =>
  object().shape({
    email: string().email(t("auth.validation.invalidEmail")).required(t("auth.validation.emailRequired")),
    password: string().required(t("auth.validation.passwordRequired")),
  });

export const providers: ProviderObject = {
  facebook: new FacebookAuthProvider(),
  google: new GoogleAuthProvider(),
};
