import React, { useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { Loader } from "components";
import { CheckoutContext } from "context";
import { STRIPE_GATEWAY } from "../payment.constants";
import { appearance } from "./stripe.constants";
import { StripeCardForm } from "./stripe-card-form/stripe-card-form";
import { StripeProps } from "./stripe.types";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import {
  COMPLETE_CHECKOUT,
  CompleteCheckoutResponse,
  CREATE_CHECKOUT_PAYMENT,
  CreateCheckoutPaymentResponse,
} from "graphql/checkout";

import styles from "./stripe.module.scss";

export const Stripe: React.FC<StripeProps> = ({ changeStep }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { checkout } = useContext(CheckoutContext);

  const [confirmationNeeded, setConfirmationNeeded] = useState<boolean>(false);
  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(true);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState<string | null>(null);

  const [createCheckoutPayment] = useMutation<CreateCheckoutPaymentResponse>(CREATE_CHECKOUT_PAYMENT);
  const [completeCheckout] = useMutation<CompleteCheckoutResponse>(COMPLETE_CHECKOUT);

  const tryToCompleteCheckout = async () => {
    if (checkout) {
      const { errors: paymentCreateErrors } = await createCheckoutPayment({
        variables: {
          checkoutId: checkout.id,
          input: {
            gateway: "saleor.payments.stripe",
            amount: checkout.totalPrice?.gross.amount,
          },
        },
      });

      if (paymentCreateErrors) {
        enqueueSnackbar(t("checkout.payment.error"), { variant: "error" });
        return;
      }

      const { data: completeData, errors: completeErrors } = await completeCheckout({
        variables: {
          checkoutId: checkout.id,
          paymentData: JSON.stringify({
            payment_method_types: ["card", "p24", "blik"],
          }),
        },
      });

      if (completeErrors) {
        enqueueSnackbar(t("checkout.payment.error"), { variant: "error" });
        return;
      }

      setConfirmationNeeded(completeData?.checkoutComplete?.confirmationNeeded || true);

      const confirmationData = JSON.parse(completeData?.checkoutComplete?.confirmationData || "");
      if (confirmationData) {
        setPaymentIntentClientSecret(confirmationData?.client_secret);
        localStorage.setItem(STORAGE_FIELDS.payment_intent, confirmationData?.id || null);
        localStorage.setItem(STORAGE_FIELDS.payment_intent_client_secret, confirmationData?.client_secret || null);
      }
    }
  };

  useDidMount(() => {
    setPaymentLoading(true);
    tryToCompleteCheckout()
      .then(() => setPaymentLoading(false))
      .catch(() => setPaymentLoading(false));
  });

  const stripeGateway = checkout?.availablePaymentGateways.find((gateway) => gateway.id === STRIPE_GATEWAY);
  const stripeApiKey = stripeGateway?.config.find((conf) => conf.field === "api_key")?.value;

  const goBackToSummary = () => changeStep("summary");

  if (!stripeApiKey) {
    return (
      <div className={styles.noContentContainer}>
        <div>
          <Typography variant="h4" sx={{ mb: 4 }}>
            {t("checkout.payment.getPaymentDetailsErrorTitle")}
          </Typography>
          <Typography sx={{ mb: 2 }}>{t("checkout.payment.getPaymentDetailsErrorSubtitle")}</Typography>
          <Button onClick={goBackToSummary}>{t("checkout.payment.goBack")}</Button>
        </div>
      </div>
    );
  }

  if (!checkout) {
    return (
      <div className={styles.noContentContainer}>
        <div>
          <Typography variant="h4" sx={{ mb: 4 }}>
            {t("checkout.payment.getCheckoutDataErrorTitle")}
          </Typography>
          <Typography sx={{ mb: 2 }}>{t("checkout.payment.getCheckoutDataErrorSubtitle")}</Typography>
          <Button onClick={goBackToSummary}>{t("checkout.payment.goBack")}</Button>
        </div>
      </div>
    );
  }

  if (isPaymentLoading) {
    return <Loader height="90%" />;
  }

  if (!paymentIntentClientSecret && !isPaymentLoading) {
    return (
      <div>
        <Typography>{t("checkout.payment.noPaymentIntentError")}</Typography>
        <Button variant="outlined" onClick={goBackToSummary}>
          {t("common.back")}
        </Button>
      </div>
    );
  }

  const stripePromise = loadStripe(stripeApiKey);

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: paymentIntentClientSecret || undefined, appearance }}>
      <StripeCardForm checkout={checkout} changeStep={changeStep} confirmationNeeded={confirmationNeeded} />
    </Elements>
  );
};
