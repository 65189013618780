export const menstruation = {
  title: "Krwawienie",
  intensityLabel: "Intensywność",
  bloodingColorLabel: "Kolor krwawienia",
  color: {
    red: "Czerwony",
    brightRed: "Jasny czerwony",
    darkRed: "Ciemny czerwony",
    pink: "Różowy",
    pinkOrange: "Różowo-pomarańczowy",
  },
  intensity: {
    spotting: "Plamienie",
    low: "Lekkie krwawienie",
    medium: "Średnie krwawienie",
    intense: "Intensywne krwawienie",
  },
  saveDataSuccess: "Dane zostały zapisane!",
  saveDataError: "Nie udało się zapisać danych",
};
