export const product = {
  attributes: "Atrybuty",
  variant: "Wariant",
  addToCart: "Dodaj do koszyka",

  selectVariant: "Wybierz wariant produktu",
  selectedVariant: "Wariant: {{variant}}",
  category: "Kategoria",
  collection: "Kolekcja",
};
