import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CategoryModel } from "models";

type InitialState = {
  categories: CategoryModel[];
};

const initialState: InitialState = {
  categories: [],
};

const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    resetCategoriesStore: () => initialState,
    setCategories: (state, action: PayloadAction<CategoryModel[]>) => {
      state.categories = action.payload;
    },
  },
});

export const { resetCategoriesStore, setCategories } = categories.actions;

export default categories.reducer;
