import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";

import { getLinkPath } from "utils";
import { CheckoutContext } from "context";
import {
  CheckoutProductUpdateResponse,
  CheckoutProductUpdateVariables,
  REMOVE_CHECKOUT_PRODUCT,
  UPDATE_CHECKOUT_PRODUCT,
} from "graphql/checkout";
import { DEFAULT_PL_LOCALE } from "constants/app.constants";
import { CheckoutProductProps } from "./checkout-product.types";
import { PRODUCT_DETAILS_PAGE } from "constants/routes.constants";
import { RemoveCartProductResponse, RemoveCartProductVariables } from "graphql/product";

import styles from "./checkout-product.module.scss";

export const CheckoutProduct: React.FC<CheckoutProductProps> = ({ product, editable }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { checkoutToken } = useContext(CheckoutContext);
  const { variant, totalPrice, quantity } = product;

  const [updateCheckoutProduct] = useMutation<CheckoutProductUpdateResponse, CheckoutProductUpdateVariables>(
    UPDATE_CHECKOUT_PRODUCT,
    {
      onCompleted: () => enqueueSnackbar(t("checkout.actions.changeQuantitySuccess"), { variant: "success" }),
      onError: () => enqueueSnackbar(t("checkout.actions.changeQuantityError"), { variant: "error" }),
    },
  );
  const [removeCheckoutProduct] = useMutation<RemoveCartProductResponse, RemoveCartProductVariables>(
    REMOVE_CHECKOUT_PRODUCT,
    {
      onCompleted: () => enqueueSnackbar(t("checkout.actions.removeProductSuccess"), { variant: "success" }),
      onError: () => enqueueSnackbar(t("checkout.actions.removeProductError"), { variant: "error" }),
    },
  );

  const updateProduct = async (updatedQuantity: number) => {
    await updateCheckoutProduct({
      variables: {
        token: checkoutToken,
        locale: DEFAULT_PL_LOCALE,
        lines: [
          {
            quantity: updatedQuantity,
            variantId: variant.id || "",
          },
        ],
      },
    });
  };

  const increaseQuantity = async () => {
    await updateProduct(quantity + 1);
  };

  const decreaseQuantity = async () => {
    if (quantity - 1 > 0) {
      await updateProduct(quantity - 1);
    }
  };

  const removeProduct = async () => {
    await removeCheckoutProduct({
      variables: { checkoutToken, lineId: product.id, locale: DEFAULT_PL_LOCALE },
    });
  };

  return (
    <div className={styles.container}>
      {editable && (
        <IconButton onClick={removeProduct} className={styles.removeButton}>
          <Close />
        </IconButton>
      )}
      <div className={styles.row}>
        <Link to={getLinkPath(PRODUCT_DETAILS_PAGE.path, { productId: variant.product.id })} target="_blank">
          <img src={variant.product.thumbnail?.url || ""} alt="product" className={styles.image} />
        </Link>
        <Link to={getLinkPath(PRODUCT_DETAILS_PAGE.path, { productId: variant.product.id })} target="_blank">
          <Typography color="text.primary">{variant.product.name}</Typography>
        </Link>
      </div>
      {editable && (
        <div className={styles.row}>
          <IconButton disabled={quantity - 1 === 0} onClick={decreaseQuantity}>
            -
          </IconButton>
          <Typography>{quantity}</Typography>
          <IconButton onClick={increaseQuantity}>+</IconButton>
        </div>
      )}
      {!editable && (
        <Typography>
          {t("checkout.labels.quantity")} <b>{quantity}</b>
        </Typography>
      )}
      <Typography variant="body1" fontWeight={700}>
        {totalPrice?.gross.amount} {totalPrice?.gross.currency}
      </Typography>
    </div>
  );
};
