import React, { useState } from "react";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";

import { Message } from "../message/message";
import { calculatePoints } from "../chatbot.utils";
import { SummaryProps } from "./summary.types";
import { SUMMARY_HIGH, SUMMARY_LOW, SUMMARY_MEDIUM } from "../chatbot.constants";
import { ConversationEndButtons } from "../conversation-end-buttons/conversation-end-buttons";

export const Summary: React.FC<SummaryProps> = ({ conversation, name, isAuthenticated, startOver, closeChatbot }) => {
  const [points, setPoints] = useState<number>(0);

  useDidMount(() => {
    const counter = calculatePoints(conversation);

    setPoints(counter);
  });

  const showSummaryLow = points >= 0 && points < 5;
  const showSummaryMedium = points >= 5 && points < 10;
  const showSummaryHigh = points >= 10 && points < 14;

  return (
    <>
      {showSummaryLow && <Message message={SUMMARY_LOW.text({ name, isAuthenticated })} sender="bot" />}
      {showSummaryMedium && <Message message={SUMMARY_MEDIUM.text({ name, isAuthenticated })} sender="bot" />}
      {showSummaryHigh && <Message message={SUMMARY_HIGH.text({ name, isAuthenticated })} sender="bot" />}
      <ConversationEndButtons startOver={startOver} closeChatbot={closeChatbot} />
    </>
  );
};
