export const myMeno = {
  brain: "Brain",
  psyche: "Psyche",
  body: "Body",
  beauty: "Beauty",
  sex: "Sex",
  menstruation: "Menstruation",
  energy: "Energy",
  brainFog: "Brain fog",
  concentrationProblems: "Concentration problems",
  memoryProblems: "Memory problems",
  insomnia: "Insomnia",
  headache: "Headache",
  anxiety: "Anxiety",
  depression: "Depression",
  irritation: "Irritation",
  tearfulness: "Tearfulness",
  anger: "Anger",
  muscleAches: "Muscle aches",
  weightGain: "Weight gain",
  flatulence: "Flatulence",
  waterRetention: "Water retention",
  itching: "Itching",
  alopecia: "Alopecia",
  brittleHair: "Brittle hair",
  brittleNails: "Brittle nails",
  skinDryness: "Skin dryness",
  libido: "Lack of libido",
  vaginalDryness: "Vaginal dryness",
  vaginitis: "Vaginitis",
  urinaryIncontinence: "Urinary incontinence",
  noMenstruation: "I haven't had menstruation for a year",
  menstruationCalendar: "Menstruation calendar",
  noPower: "I don't have a power",
  exhausted: "Exhausted",
  tired: "I'm tired",
  ok: "I'm OK",
  weekOfYear: "{{week}} week of the year",
  age: "Age",
  height: "Height",
  weight: "Weight",
  heightLabel: "Height (cm)",
  weightLabel: "Weight (kg)",
  updateDetailsTitle: "Update your profile",
  updateDetailsDescription: "Update this data to get you know better",

  profileUpdateSuccess: "Profile has been updated successfully",
  profileUpdateError: "Could not update your profile",

  createReportSuccess: "Report has been saved",
  createReportError: "Could not save a report",

  validation: {
    required: "This field is required",

    weightMin: "Weight should be greater than 30kg",
    weightMax: "Weight should be less than 260kg",

    heightMin: "Height should be greater than 110cm",
    heightMax: "Height should be less than 220cm",

    ageMin: "Age should be greater than 0",
    ageMax: "Age should be less than 110",
  },

  todayTooltip: "Today - {{date}}",
  completedReportTooltip: "Completed report - {{date}}",
  selectedDayReport: "Selected day - {{date}}",
  menstruationCompleted: "Filled menstruation data - {{date}}: {{intensity}} - {{color}}",
};
