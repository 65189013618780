import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useUser } from "hooks";
import { ConversationEndButtonsProps } from "./conversation-end-buttons.types";
import { REGISTER_PAGE } from "constants/routes.constants";

import styles from "./conversation-end-buttons.module.scss";

export const ConversationEndButtons: React.FC<ConversationEndButtonsProps> = ({ startOver, closeChatbot }) => {
  const { t } = useTranslation();

  const { isAuthenticated } = useUser();

  return (
    <div className={styles.buttons}>
      {!isAuthenticated && (
        <Link to={REGISTER_PAGE.path} target="_blank">
          <Button variant="contained" sx={{ color: "#fff", fontWeight: 600 }}>
            {t("chatbot.register")}
          </Button>
        </Link>
      )}
      <Button variant="outlined" onClick={startOver} sx={{ fontWeight: 600 }}>
        {t("chatbot.startOver")}
      </Button>
      <Button variant="outlined" onClick={closeChatbot} sx={{ fontWeight: 600 }}>
        {t("chatbot.finish")}
      </Button>
    </div>
  );
};
