import { doc, getDoc, getDocs, setDoc, query, where, deleteDoc } from "firebase/firestore";
import { format } from "date-fns";

import { MenstruationModel } from "models";
import { getCollectionRef, firestoreCollections } from "config/firebase.config";
import { MenstruationData, MenstruationResponse } from "./menstruation.types";

const getDocumentId = (userId: string, date: Date) => {
  const formattedDate = format(date, "dd-MM-yyyy");
  return `${userId}_${formattedDate}`;
};

export const getUserMenstruation = (userId: string, date: Date) => {
  const collectionRef = getCollectionRef<MenstruationModel>(firestoreCollections.menstruations);
  const id = getDocumentId(userId, date);

  const documentRef = doc(collectionRef, id);

  return getDoc(documentRef);
};

export const getUserMenstruations = (userId: string, startDate: Date, endDate: Date) => {
  const collectionRef = getCollectionRef<MenstruationResponse[]>(firestoreCollections.menstruations);

  const queryRef = query(
    collectionRef,
    where("date", ">=", startDate),
    where("date", "<", endDate),
    where("userId", "==", userId),
  );

  return getDocs(queryRef);
};

export const postMenstruation = (userId: string, date: Date, menstruationData: MenstruationData) => {
  const collectionRef = getCollectionRef<MenstruationModel>(firestoreCollections.menstruations);
  const id = getDocumentId(userId, date);

  const documentRef = doc(collectionRef, id);
  const values: MenstruationModel = { ...menstruationData, date, userId };

  return setDoc(documentRef, values);
};

export const deleteUserMenstruation = async (userId: string) => {
  const menstruationRef = getCollectionRef<MenstruationModel[]>(firestoreCollections.menstruations);

  const menstruationQuery = query(menstruationRef, where("userId", "==", userId));

  const queryReference = await getDocs(menstruationQuery);

  const deletePromises = queryReference.docs.map((menstruation) => {
    if (menstruation.id.includes(userId)) {
      return deleteDoc(menstruation.ref);
    }

    return Promise;
  });

  return Promise.all(deletePromises);

  // getDocs(menstruationQuery).then(async (snapshot) => {
  //   snapshot.docs.forEach((menstruation) => {
  //     if (menstruation.id.includes(userId)) {
  //       deleteDoc(menstruation.ref);
  //     }
  //   });
  // });
};
