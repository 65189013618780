import React, { useContext, useState } from "react";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useMutation } from "@apollo/client";
import { CheckCircle, Error, Cancel } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Content, Loader } from "components";
import { useQueryParams } from "hooks";
import { PaymentSummaryQuery, View } from "./payment-summary.types";
import { COMPLETE_CHECKOUT, CompleteCheckoutResponse, Order } from "graphql/checkout";
import { CheckoutContext } from "context";
import { LANDING_PAGE } from "constants/routes.constants";

import styles from "./payment-summary.module.scss";

export const PaymentSummaryPage: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { query } = useQueryParams<PaymentSummaryQuery>();
  const {
    checkout,
    loading: isCartLoading,
    paymentIntent,
    paymentIntentClientSecret,
    resetCheckout,
  } = useContext(CheckoutContext);

  const [isProcessing, setProcessing] = useState<boolean>(true);
  const [view, setView] = useState<View>("processing");
  const [order, setOrder] = useState<Order | null>(null);

  const [completeCheckout] = useMutation<CompleteCheckoutResponse>(COMPLETE_CHECKOUT);

  useDidMount(() => {
    return () => resetCheckout();
  });

  useDidUpdate(
    () => {
      setProcessing(true);

      const successCondition = query?.redirect_status === "succeeded";
      const valuesAreCorrect =
        paymentIntent === query?.payment_intent && paymentIntentClientSecret === query?.payment_intent_client_secret;

      if (query?.redirect_status === "failed") {
        setView("failed");
        setProcessing(false);
      }

      if (checkout && successCondition && valuesAreCorrect) {
        completeCheckout({
          variables: {
            checkoutId: checkout.id,
          },
        }).then(({ errors, data }) => {
          if (errors) {
            setView("failed");
          } else {
            setOrder(data?.checkoutComplete?.order || null);
            setView("success");
          }
          setProcessing(false);
        });
      } else {
        setView("incorrect-data");
        setProcessing(false);
      }
    },
    [checkout, query, paymentIntent, paymentIntentClientSecret],
    true,
  );

  const goToMainPage = () => {
    resetCheckout();
    navigate(LANDING_PAGE.path);
  };

  if (isCartLoading || isProcessing)
    return (
      <Content>
        <div className={styles.container}>
          <div>
            <Loader loaderSize="60px" />
            <Typography variant="h4" color="info.main" sx={{ fontFamily: "Montserrat" }}>
              {t("checkout.payment.processing")}
            </Typography>
          </div>
        </div>
      </Content>
    );

  return (
    <Content>
      <div className={styles.container}>
        {view === "processing" && (
          <div>
            <Loader loaderSize="60px" />
            <Typography variant="h4" color="info.main" sx={{ fontFamily: "Montserrat" }}>
              {t("checkout.payment.processing")}
            </Typography>
          </div>
        )}
        {view === "success" && order && (
          <div>
            <div className={styles.row}>
              <CheckCircle sx={{ color: "success.main", width: "60px", height: "60px" }} />
              <Typography variant="h4" color="success.main" sx={{ fontFamily: "Montserrat" }}>
                {t("checkout.payment.success")}
              </Typography>
            </div>
            <Typography sx={{ mt: 2, fontFamily: "Montserrat" }}>
              {t("checkout.payment.orderNumber", { number: order.number })}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>{t("checkout.payment.successDescription")}</Typography>
            <Button sx={{ mt: 2 }} onClick={goToMainPage} variant="outlined">
              {t("common.goToMain")}
            </Button>
          </div>
        )}
        {view === "failed" && (
          <div>
            <Cancel sx={{ color: "error.main", width: "60px", height: "60px" }} />
            <Typography variant="h4" color="error.main" sx={{ fontFamily: "Montserrat" }}>
              {t("checkout.payment.failed")}
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Montserrat" }}>{t("checkout.payment.failedDescription")}</Typography>
            <Button sx={{ mt: 2 }} onClick={goToMainPage} variant="outlined">
              {t("common.goToMain")}
            </Button>
          </div>
        )}
        {view === "incorrect-data" && (
          <div>
            <Error sx={{ color: "error.main", width: "60px", height: "60px" }} />
            <Typography variant="h4" color="error.main" sx={{ fontFamily: "Montserrat" }}>
              {t("checkout.payment.incorrectData")}
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Montserrat" }}>
              {t("checkout.payment.incorrectDataDescription")}
            </Typography>
            <Button sx={{ mt: 2 }} onClick={goToMainPage} variant="outlined">
              {t("common.goToMain")}
            </Button>
          </div>
        )}
      </div>
    </Content>
  );
};
