import { builder } from "server/builder";
import { CategoryModel } from "models";
import { StrapiData } from "types";

export const getCategories = builder.createCommand<StrapiData<CategoryModel[]>>()({
  method: "GET",
  endpoint: "/api/categories",
});

export const getCategory = builder.createCommand<StrapiData<CategoryModel>>()({
  method: "GET",
  endpoint: "/api/categories/:categoryId",
});
