import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { CheckoutValues } from "./checkout.types";
import { CheckoutByTokenDocument, CheckoutDetailsResponse, CheckoutDetailsVariables } from "graphql/checkout";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { DEFAULT_PL_LOCALE } from "constants/app.constants";
import { PAYMENT_METHODS } from "pages/checkout/checkout.constants";
import { PaymentMethod } from "pages/checkout/checkout.types";

export const CheckoutContext = React.createContext<CheckoutValues>({
  checkoutToken: null,
  setCheckoutToken: () => {},
  resetCheckoutToken: () => {},
  paymentMethod: PAYMENT_METHODS.cash_on_delivery,
  setPaymentMethod: () => {},
  useBillingAsShipping: false,
  setUseBillingAsShipping: () => {},
  checkout: null,
  checkoutError: null,
  loading: false,

  resetCheckout: () => {},

  paymentIntent: null,
  paymentIntentClientSecret: null,
});

interface Props {
  children: React.ReactNode;
}

export const CheckoutContextProvider: React.FC<Props> = ({ children }) => {
  const token = localStorage.getItem(STORAGE_FIELDS.checkout_token);

  const [checkoutToken, setCheckoutToken] = useState<string | null>(token || null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS.p24);
  const [useBillingAsShipping, setUseBillingAsShipping] = useState<boolean>(false);

  const [paymentIntent] = useState<string | null>(localStorage.getItem(STORAGE_FIELDS.payment_intent));
  const [paymentIntentClientSecret] = useState<string | null>(
    localStorage.getItem(STORAGE_FIELDS.payment_intent_client_secret),
  );

  const { data, loading, error } = useQuery<CheckoutDetailsResponse, CheckoutDetailsVariables>(
    CheckoutByTokenDocument,
    {
      variables: { checkoutToken, locale: DEFAULT_PL_LOCALE },
      skip: !checkoutToken,
    },
  );

  const resetCheckout = () => {
    localStorage.removeItem(STORAGE_FIELDS.checkout_token);
    localStorage.removeItem(STORAGE_FIELDS.payment_intent);
    localStorage.removeItem(STORAGE_FIELDS.payment_intent_client_secret);

    setCheckoutToken(null);
    setPaymentMethod(PAYMENT_METHODS.p24);
    setUseBillingAsShipping(false);
  };

  const resetCheckoutToken = () => setCheckoutToken(null);

  return (
    <CheckoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        checkoutToken,
        setCheckoutToken,
        resetCheckoutToken,
        paymentMethod,
        setPaymentMethod,
        useBillingAsShipping,
        setUseBillingAsShipping,
        checkout: data?.checkout,
        loading,
        checkoutError: error,
        resetCheckout,

        paymentIntent,
        paymentIntentClientSecret,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
