import React, { useState } from "react";

import { AuthLayout } from "../auth-layout/auth-layout";
import { LoginVerifyAccount } from "./login-verify-account/login-verify-account";
import { LoginForm } from "./login-form/login-form";

export const LoginPage: React.FC = () => {
  const [showVerify, setShowVerify] = useState<boolean>(false);

  const showVerifyScreen = () => setShowVerify(true);
  const hideVerificationScreen = () => setShowVerify(false);

  return (
    <AuthLayout variant="login">
      {showVerify && <LoginVerifyAccount hideVerificationScreen={hideVerificationScreen} />}
      {!showVerify && <LoginForm showVerifyScreen={showVerifyScreen} />}
    </AuthLayout>
  );
};
