import React from "react";

import { Chatbot, Content } from "components";
import { HomeHeader } from "./home-header/home-header";
import { NewArticles } from "pages/post/list/new-articles/new-articles";

import styles from "./landing.module.scss";

export const LandingPage: React.FC = () => {
  return (
    <Content className={styles.container}>
      <HomeHeader />
      <div className={styles.news}>
        <NewArticles />
      </div>
      <Chatbot />
    </Content>
  );
};
