import { TFunction } from "i18next";
import { object, number, SchemaOf, string } from "yup";

import { AdviceModel } from "models";

export const createAdviceValues: AdviceModel = {
  name: "",
  age: 40,
  prePeriPost: "",
  whatDidYouKnow: "",
  mostSurprising: "",
  howItsGoing: "",
  howDoesItImpact: "",
  advice: "",
};

export const adviceSchema = (t: TFunction): SchemaOf<AdviceModel> =>
  object().shape({
    name: string().required(t("doctors.required")),
    age: number()
      .required(t("doctors.required"))
      .min(16, t("doctors.incorrectAge"))
      .max(100, t("doctors.incorrectAge")),
    prePeriPost: string().required(t("doctors.required")),
    whatDidYouKnow: string().required(t("doctors.required")),
    mostSurprising: string().required(t("doctors.required")),
    howItsGoing: string().required(t("doctors.required")),
    howDoesItImpact: string().required(t("doctors.required")),
    advice: string().required(t("doctors.required")),
  });
