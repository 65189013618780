import { createTheme, ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#E7A59F",
      light: "#EDCFCC",
    },
    secondary: {
      main: "#296B6F",
      light: "rgba(245,247,250,0.8)",
      dark: "#eaeaea",
    },
    background: {
      paper: "#fff",
      default: "#D9D9D9",
    },
    text: {
      primary: "#284B5E",
      secondary: "rgba(0, 0, 0, 0.5)",
      disabled: "#c5c5c5",
    },
    error: {
      main: "#b94c48",
    },
    warning: {
      main: "#d59858",
    },
    info: {
      main: "#5da8c2",
    },
    success: {
      main: "#629f5d",
    },
    action: {
      active: "#980000",
    },
    divider: "#e6e6e6",
  },
  spacing: 8,
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: "contain",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          padding: "0 5px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: "#a9a9a9",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#e6e6e6",
          borderRadius: "6px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
        contained: {
          margin: "10px 0",
        },
        sizeMedium: {
          minWidth: "100px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
          color: "#284B5E",
        },
        h1: {
          fontSize: "61px",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: 400,
        },
      },
    },
  },
});
