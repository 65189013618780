import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useFetch } from "@better-typed/react-hyper-fetch";

import { PageRoute, Loader } from "components";
import { mapUserData } from "utils";
import { RootState, setCategories, setToken, setUser, setUserDetails, updateFavouritesPosts } from "store";
import { getUserDetails } from "firestore";
import { routes } from "config";
import { auth } from "config/firebase.config";
import { LOGOUT_PAGE } from "constants/routes.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { getCategories } from "server";

import "./assets/styles/app.scss";

export const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);
  const { locale } = useSelector((state: RootState) => state.app);

  const [isLoading, setLoading] = useState<boolean>(true);

  const categoriesData = useFetch(getCategories.setQueryParams({ populate: "*", locale }));
  const { onSuccess, loading } = categoriesData;
  onSuccess(({ data }) => dispatch(setCategories(data)));

  useDidUpdate(
    () => {
      dispatch(updateFavouritesPosts({ locale }));
    },
    [locale],
    true,
  );

  useDidUpdate(
    () => {
      const sub = onAuthStateChanged(auth, (firebaseUser) => {
        if (firebaseUser && !user) {
          setLoading(true);
          firebaseUser
            .getIdToken()
            .then((idToken) => {
              dispatch(setUser(mapUserData(firebaseUser)));
              dispatch(setToken(idToken));

              getUserDetails(firebaseUser.uid).then((details) => {
                const userDetails = details.data();

                if (userDetails) dispatch(setUserDetails(userDetails));
              });

              localStorage.setItem(STORAGE_FIELDS.token, idToken);
              localStorage.setItem(STORAGE_FIELDS.refresh_token, firebaseUser.refreshToken);
              setLoading(false);
            })
            .catch(() => {
              navigate(LOGOUT_PAGE.path);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });

      return sub;
    },
    [user],
    true,
  );

  if (isLoading || loading) {
    return <Loader height="100vh" />;
  }

  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.name} path={route.path} element={<PageRoute {...route} />} />
      ))}
    </Routes>
  );
};
