import React from "react";

import { PagesResults } from "../pages-results/pages-results";
import { ArticlesResults } from "../articles-results/articles-results";
import { ProductsResults } from "../products-results/products-results";
import { ArticlesCategoriesResults } from "../articles-categories-results/articles-categories-results";
import { GlobalSearchResultsProps } from "./global-search-results.types";

import styles from "./global-search-results.module.scss";

export const GlobalSearchResults: React.FC<GlobalSearchResultsProps> = ({ searchPhrase, resetState }) => {
  return (
    <div className={styles.results}>
      <PagesResults searchValue={searchPhrase} resetState={resetState} />
      <ArticlesResults searchValue={searchPhrase} resetState={resetState} />
      <ProductsResults searchValue={searchPhrase} resetState={resetState} />
      <ArticlesCategoriesResults searchValue={searchPhrase} resetState={resetState} />
    </div>
  );
};
