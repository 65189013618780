import { TFunction } from "i18next";
import { SchemaOf, string, object } from "yup";

import { ResetPasswordData } from "./reset-password-form.types";

export const initialValues: ResetPasswordData = {
  email: "",
};

export const resetPasswordSchema = (t: TFunction): SchemaOf<ResetPasswordData> =>
  object().shape({
    email: string().email(t("auth.validation.invalidEmail")).required(t("auth.validation.emailRequired")),
  });
