import React from "react";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LOGIN_PAGE, REGISTER_PAGE } from "constants/routes.constants";
import { AuthVariantDetails, AuthLayoutProps } from "./auth-layout.types";

import { ReactComponent as Logo } from "assets/icons/logo.svg";
import auth from "assets/images/auth.png";

import styles from "./auth-layout.module.scss";

export const AuthLayout: React.FC<AuthLayoutProps> = ({ variant, children }) => {
  const { t } = useTranslation();

  const variantDetails: AuthVariantDetails = {
    login: { redirectLabel: t("auth.login.signUp"), redirectPath: REGISTER_PAGE.path },
    register: { redirectLabel: t("auth.register.login"), redirectPath: LOGIN_PAGE.path },
    "reset-password": { redirectLabel: t("auth.register.login"), redirectPath: LOGIN_PAGE.path },
  };

  return (
    <div className={styles.container}>
      <Link to={LOGIN_PAGE.path} className={styles.logo}>
        <Logo />
      </Link>
      <div className={styles.sidebar}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img src={auth} className={styles.image} alt="sidebar" />
            <Link to={variantDetails[variant].redirectPath}>
              <Button className={styles.imageButton}>{variantDetails[variant].redirectLabel}</Button>
            </Link>
          </div>
          <Typography className={styles.tag}>
            <span>#</span>
            {t("auth.support")}
            <span>{t("auth.in")}</span>
            {t("auth.change")}
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
};
