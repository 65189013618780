import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PRODUCT_LIST_PAGE } from "constants/routes.constants";

import styles from "./no-content.module.scss";

export const NoContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        <Typography variant="h4" fontWeight={700} sx={{ mb: 4 }} className={styles.title}>
          {t("checkout.cart.noProductsTitle")}
        </Typography>
        <Typography sx={{ mb: 2 }}>{t("checkout.cart.noProductsSubtitle")}</Typography>
        <Link to={PRODUCT_LIST_PAGE.path}>
          <Button variant="outlined">{t("checkout.cart.noProductsButton")}</Button>
        </Link>
      </div>
    </div>
  );
};
