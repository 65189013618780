import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getParsedStorageValue, pushToStorageArray, removeFromStorageArray } from "utils";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

type InitialState = {
  posts: number[];
};

const initialState: InitialState = {
  posts: [],
};

const favourites = createSlice({
  name: "favourites",
  initialState,
  reducers: {
    resetFavouritesStore: () => initialState,
    updateFavouritesPosts: (state, action: PayloadAction<{ locale: string }>) => {
      const key =
        action.payload.locale === "pl-PL" ? STORAGE_FIELDS.favourites_posts_pl : STORAGE_FIELDS.favourites_posts_en;

      const favouritesPosts = getParsedStorageValue<number[]>(key);
      state.posts = favouritesPosts || [];
    },
    pushPostToFavourites: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const updatedArray = pushToStorageArray<number>(action.payload.key, action.payload.value);
      state.posts = updatedArray;
    },
    removePostFromFavourites: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const updatedArray = removeFromStorageArray<number>(action.payload.key, action.payload.value);
      state.posts = updatedArray;
    },
  },
});

export const { resetFavouritesStore, updateFavouritesPosts, pushPostToFavourites, removePostFromFavourites } =
  favourites.actions;

export default favourites.reducer;
