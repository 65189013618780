import { gql } from "@apollo/client";

import { CheckoutDetailsFragmentDoc, PriceFragmentDoc } from "graphql/checkout";

export const ProductMediaFragmentDoc = gql`
  fragment ProductMediaFragment on ProductMedia {
    url
    alt
    type
  }
`;

export const GET_LATEST_PRODUCTS = gql`
  query getLatestProducts($first: Int, $search: String, $channel: String, $collection: [ID!], $category: [ID!]) {
    products(
      first: $first
      channel: $channel
      filter: { search: $search, collections: $collection, categories: $category }
    ) {
      edges {
        cursor
        node {
          id
          name
          description
          pricing {
            priceRange {
              start {
                gross {
                  amount
                  currency
                }
              }
            }
          }
          thumbnail(size: 200) {
            url
            alt
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_SINGLE_PRODUCT = gql`
  query getProduct($id: ID, $channel: String) {
    product(id: $id, channel: $channel) {
      id
      name
      description
      attributes {
        attribute {
          id
          name
        }
        values {
          id
          name
        }
      }

      variants {
        id
        name
        quantityAvailable
        attributes {
          attribute {
            id
            name
          }
          values {
            id
            name
          }
        }
        media {
          ...ProductMediaFragment
        }
        pricing {
          price {
            gross {
              ...PriceFragment
            }
          }
        }
      }
      pricing {
        priceRange {
          start {
            gross {
              amount
              currency
            }
          }
        }
      }
      thumbnail(size: 800) {
        url
        alt
      }
    }
  }
  ${PriceFragmentDoc}
  ${ProductMediaFragmentDoc}
`;

export const REMOVE_CART_PRODUCT = gql`
  mutation RemoveProductFromCheckout($checkoutToken: UUID!, $lineId: ID!, $locale: LanguageCodeEnum!) {
    checkoutLineDelete(token: $checkoutToken, lineId: $lineId) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        field
        message
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;
