import React, { useContext, useEffect, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useSelector } from "react-redux";

import { Modal, Select } from "components";
import { Day } from "../day/day";
import { FullCalendarProps } from "./month-calendar.types";
import { weekdaysShort } from "constants/date.constants";
import { CalendarContext } from "../context/calendar.context";
import {
  getMonthDetails,
  getWeekDayIndex,
  getMonthsOptions,
  JANUARY_MONTH_INDEX,
  DECEMBER_MONTH_INDEX,
  yearOptions,
} from "./month-calendar.constants";
import { useFirebaseFetch } from "hooks";
import { getDateFromReportId, getUserMenstruations, getUserReports } from "firestore";
import { RootState } from "store";
import { checkIfReportCompleted, getMenstruationDetails } from "../calendar.constants";

import styles from "./month-calendar.module.scss";

export const MonthCalendar: React.FC<FullCalendarProps> = ({ isOpen, handleClose, onDateSelect }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedDate, setSelectedDate } = useContext(CalendarContext);

  const [selectedMonth, setSelectedMonth] = useState<number>(selectedDate.getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(selectedDate.getFullYear());

  const { monthStartDate, monthEndDate, daysOfMonth } = useMemo(() => {
    const date = new Date(selectedYear, selectedMonth, 1);
    return getMonthDetails(date);
  }, [selectedMonth, selectedYear]);

  const { data } = useFirebaseFetch(() => getUserReports(user?.id || "", monthStartDate, monthEndDate), {
    dependencies: [monthStartDate, monthEndDate],
  });

  const { data: menstruations } = useFirebaseFetch(
    () => getUserMenstruations(user?.id || "", monthStartDate, monthEndDate),
    {
      dependencies: [monthStartDate, monthEndDate],
    },
  );

  // dates with completed reports
  const completedReportDates = useMemo(() => data?.map((report) => getDateFromReportId(report.id)) || [], [data]);

  useEffect(() => {
    setSelectedMonth(selectedDate.getMonth());
    setSelectedYear(selectedDate.getFullYear());
  }, [selectedDate]);

  const weekDayIndex = useMemo(() => getWeekDayIndex(monthStartDate), [monthStartDate]);

  const onNextMonthClick = () => {
    const newMonthIndex = selectedMonth + 1;
    if (newMonthIndex > DECEMBER_MONTH_INDEX) {
      // next year
      setSelectedMonth(JANUARY_MONTH_INDEX);
      setSelectedYear((previousYear) => previousYear + 1);
      return;
    }

    setSelectedMonth((previousMonth) => previousMonth + 1);
  };

  const onPreviousMonthClick = () => {
    const newMonthIndex = selectedMonth - 1;
    if (newMonthIndex < JANUARY_MONTH_INDEX) {
      // previous year
      setSelectedMonth(DECEMBER_MONTH_INDEX);
      setSelectedYear((previousYear) => previousYear - 1);
      return;
    }

    setSelectedMonth((previousMonth) => previousMonth - 1);
  };

  const handleDateClick = (date: Date) => () => {
    setSelectedDate(date);
    handleClose();
    onDateSelect(date);
  };

  const getMenstruation = (date: Date) => getMenstruationDetails(t, date, menstruations || []);
  const checkIsCompleted = (date: Date) => checkIfReportCompleted(date, completedReportDates);

  const handleMonthChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSelectedMonth(+event.target.value);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSelectedYear(+event.target.value);
  };

  const monthsOptions = getMonthsOptions(t);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div>
        <div className={styles.navigation}>
          <IconButton onClick={onPreviousMonthClick} sx={{ width: "30px", height: "30px" }}>
            <ArrowBackIos sx={{ width: "20px", height: "20px" }} />
          </IconButton>
          <Select
            name="month"
            label={t("common.monthLabel")}
            options={monthsOptions}
            value={selectedMonth}
            onChange={handleMonthChange}
            size="small"
            className={styles.monthSelect}
          />
          <Select
            name="month"
            label={t("common.yearLabel")}
            options={yearOptions}
            value={selectedYear}
            onChange={handleYearChange}
            size="small"
            className={styles.yearSelect}
          />
          <IconButton onClick={onNextMonthClick} sx={{ width: "30px", height: "30px" }}>
            <ArrowForwardIos sx={{ width: "20px", height: "20px" }} />
          </IconButton>
        </div>

        <div className={styles.days}>
          {weekdaysShort(t).map((day) => (
            <p className={styles.weekDay} key={day}>
              {day}
            </p>
          ))}
        </div>
        <div className={styles.container}>
          {daysOfMonth.map((date, index) => (
            <Day
              key={date.toLocaleDateString()}
              date={date}
              onDayClick={handleDateClick}
              menstruationDetails={getMenstruation(date)}
              startFrom={index === 0 ? weekDayIndex : null}
              isReportCompleted={checkIsCompleted(date)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
