import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";

import { Content, Loader } from "components";
import { Address } from "./address/address";
import { DeliveryPayment } from "./delivery-payment/delivery-payment";
import { Summary } from "./summary/summary";
import { Payment } from "./payment/payment";
import { Steps } from "./checkout.types";
import { CheckoutContext } from "context";
import { CART_PAGE } from "constants/routes.constants";
import { STRIPE_GATEWAY } from "./payment/payment.constants";

export const CheckoutPage: React.FC = () => {
  const { checkout, loading } = useContext(CheckoutContext);

  const [step, setStep] = useState<Steps>("address");

  if (loading) return <Loader height="100%" />;

  if (!checkout) return <Navigate to={CART_PAGE.path} />;

  return (
    <Content>
      {step === "address" && <Address checkout={checkout} changeStep={setStep} />}
      {step === "delivery_payment" && <DeliveryPayment checkout={checkout} changeStep={setStep} />}
      {step === "summary" && <Summary checkout={checkout} changeStep={setStep} />}
      {step === "payment" && <Payment paymentMethod={STRIPE_GATEWAY} changeStep={setStep} />}
    </Content>
  );
};
