import { doc, getDoc, getDocs, setDoc, query, where, deleteDoc } from "firebase/firestore";
import { format } from "date-fns";

import { MenstruationModel, ReportModel, ReportWithId } from "models";
import { ReportData, ReportWithDate } from "./report.types";
import { getCollectionRef, firestoreCollections } from "config/firebase.config";

export const getDocumentId = (userId: string, date: Date) => {
  const formattedDate = format(date, "dd-MM-yyyy");
  return `${userId}_${formattedDate}`;
};

export const getDateFromReportId = (id: string) => {
  const [, date] = id.split("_");
  const [day, month, year] = date.split("-");

  return new Date(+year, +month - 1, +day, 12);
};

export const getUserReport = (userId: string, date: Date) => {
  const collectionRef = getCollectionRef<ReportModel>(firestoreCollections.reports);
  const id = getDocumentId(userId, date);

  const documentRef = doc(collectionRef, id);

  return getDoc(documentRef);
};

export const getUserReports = (userId: string, startDate: Date, endDate: Date) => {
  const collectionRef = getCollectionRef<ReportWithId[]>(firestoreCollections.reports);

  const queryRef = query(
    collectionRef,
    where("date", ">=", startDate),
    where("date", "<", endDate),
    where("creatorId", "==", userId),
  );
  return getDocs(queryRef);
};

export const postReport = (userId: string, date: Date, report: ReportData) => {
  const collectionRef = getCollectionRef<ReportModel>(firestoreCollections.reports);
  const id = getDocumentId(userId, date);

  const documentRef = doc(collectionRef, id);
  const values: ReportWithDate = { ...report, date, creatorId: userId };

  return setDoc(documentRef, values);
};

export const deleteUserReports = async (userId: string) => {
  const reportsRef = getCollectionRef<MenstruationModel[]>(firestoreCollections.reports);

  const reportsQuery = query(reportsRef, where("creatorId", "==", userId));

  const queryReference = await getDocs(reportsQuery);

  const deletePromises = queryReference.docs.map((report) => {
    if (report.id.includes(userId)) {
      return deleteDoc(report.ref);
    }

    return Promise;
  });

  return Promise.all(deletePromises);
};
