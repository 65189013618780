export const doctors = {
  addDoctor: "Add doctor",
  name: "Name",
  surname: "Surname",
  address: "Address",
  city: "City",
  email: "Email",
  website: "Website",
  voivodeship: "Voivodeship",
  specialization: "Specialization",
  phoneNumber: "Phone number",
  opinion: "Opinion",

  add: "Add",

  required: "This field is required",
  incorrectEmail: "Email is incorrect",
  incorrectPhone: "Phone number format is incorrect",

  doctorAdded: "Doctor has been added",
  doctorAddError: "Error while adding a doctor",
  incorrectAge: "Age is incorrect (16-100)",
};
