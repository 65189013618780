import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal } from "components";
import { SuccessModalProps } from "./stripe-success-modal.types";
import { CheckoutContext } from "context";
import { LANDING_PAGE } from "constants/routes.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

export const StripeSuccessModal: React.FC<SuccessModalProps> = ({ orderNumber, isOpen, handleClose }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { resetCheckoutToken, checkout } = useContext(CheckoutContext);

  const closeModal = () => {
    handleClose();
    localStorage.removeItem(STORAGE_FIELDS.checkout_token);
    resetCheckoutToken();
    navigate(LANDING_PAGE.path);
  };

  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <Typography variant="h4" fontWeight={700} sx={{ mb: orderNumber ? 0 : 4 }}>
        {t("checkout.payment.orderSuccessfulTitle")}
      </Typography>
      {orderNumber && (
        <Typography sx={{ mb: 4 }}>{t("checkout.payment.orderNumber", { number: orderNumber })}</Typography>
      )}
      <Typography sx={{ mb: 2 }}>
        {t("checkout.payment.orderSuccessfulSubtitle")} <b>{checkout?.email}</b>
      </Typography>
      <Button onClick={closeModal}>{t("checkout.payment.confirm")}</Button>
    </Modal>
  );
};
