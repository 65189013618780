import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { ServiceCheckbox } from "components";
import { Buttons, ButtonValues, CheckoutTitle } from "pages/checkout";
import { StripeSuccessModal } from "../stripe-success-modal/stripe-success-modal";
import { StripeCardFormProps } from "./stripe-card-form.types";
import { environment } from "config";
import { PAYMENT_SUMMARY_PAGE } from "constants/routes.constants";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import termsOfServiceDocument from "assets/docs/terms-of-service.pdf";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import privacyPolicyDocument from "assets/docs/privacy-policy.pdf";

import styles from "./stripe-card-form.module.scss";
import checkoutStyles from "../../../checkout.module.scss";

export const StripeCardForm: React.FC<StripeCardFormProps> = ({ checkout, changeStep, confirmationNeeded }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [shopTermsOfService, setShopTermsOfService] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string | null>(null);

  const totalPrice = checkout.totalPrice?.gross;
  const payLabel = t("checkout.payment.pay", { amount: totalPrice?.amount, currency: totalPrice?.currency });

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
    setOrderNumber(null);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsPaymentProcessing(true);

    if (elements === null || stripe === null) {
      setIsPaymentProcessing(false);

      return;
    }

    const paymentElement = elements.getElement(PaymentElement);

    if (!paymentElement) {
      enqueueSnackbar(t("checkout.payment.initializeCardError"), { variant: "error" });
      setIsPaymentProcessing(false);
      return;
    }

    if (!checkout.email) return null;

    // Additional payment action is needed (ex. 3D Secure)
    if (confirmationNeeded) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${environment.frontUrl}${PAYMENT_SUMMARY_PAGE.path}`,
          payment_method_data: {
            billing_details: {
              email: checkout.email,
              phone: checkout.billingAddress?.phone || "",
              name: `${checkout.billingAddress?.firstName} ${checkout.billingAddress?.lastName}`,
              address: {
                line1: checkout.billingAddress?.streetAddress1,
                city: checkout.billingAddress?.city,
                country: checkout.billingAddress?.country.code,
                postal_code: checkout.billingAddress?.postalCode,
              },
            },
          },
        },
      });

      if (error) {
        enqueueSnackbar(t("checkout.payment.confirmPaymentError"), { variant: "error" });
        setIsPaymentProcessing(false);
      }

      // todo: complete checkout?
    }
  };

  const goBackToSummary = () => changeStep("summary");

  const disabled = !stripe || !elements || isPaymentProcessing;
  const confirmDisabled = disabled || !shopTermsOfService || !privacyPolicy;
  const backButton: ButtonValues = { disabled, action: goBackToSummary };
  const confirmButton: ButtonValues = { disabled: confirmDisabled, loading: isPaymentProcessing, label: payLabel };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={checkoutStyles.container}>
        <div className={checkoutStyles.content}>
          <CheckoutTitle
            title={t("checkout.payment.enterCardDetails")}
            description={t("checkout.paymentDescription")}
            goBack={goBackToSummary}
          />
          <Typography>{t("checkout.payment.cardDetailsDescription")}</Typography>

          <div className={styles.column}>
            <PaymentElement
              options={{
                defaultValues: {
                  billingDetails: {
                    name: checkout?.billingAddress
                      ? `${checkout.billingAddress.firstName} ${checkout.billingAddress.lastName}`
                      : undefined,
                    email: checkout?.email || undefined,
                    phone: checkout?.billingAddress?.phone || undefined,
                  },
                },
                fields: {
                  billingDetails: {
                    name: "never",
                    email: "never",
                    phone: "never",
                  },
                },
              }}
            />
            <div>
              <ServiceCheckbox checked={shopTermsOfService} onChange={setShopTermsOfService}>
                <Typography variant="body2" sx={{ ml: -1, textAlign: "left" }}>
                  Akceptuję postanowienia{" "}
                  <strong>
                    <a
                      href={termsOfServiceDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#000" }}
                    >
                      Regulaminu
                    </a>{" "}
                    i{" "}
                    <a href={privacyPolicyDocument} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }}>
                      Polityki Prywatności
                    </a>
                  </strong>{" "}
                </Typography>
              </ServiceCheckbox>

              <ServiceCheckbox checked={privacyPolicy} onChange={setPrivacyPolicy}>
                Zamawiam z obowiązkiem zapłaty.
              </ServiceCheckbox>
            </div>
          </div>
        </div>
        <Buttons confirm={confirmButton} back={backButton} />
      </div>
      <StripeSuccessModal orderNumber={orderNumber} isOpen={isSuccessModalOpen} handleClose={closeSuccessModal} />
    </form>
  );
};
