import { RouteConstant } from "types";

export const LOGIN_PAGE: RouteConstant = {
  path: "/login",
  name: "pages.signIn",
  auth: false,
  showNavigation: false,
};

export const LOGOUT_PAGE: RouteConstant = {
  path: "/logout",
  name: "pages.signOut",
  auth: false,
  showNavigation: false,
};

export const REGISTER_PAGE: RouteConstant = {
  path: "/register",
  name: "pages.signUp",
  auth: false,
  showNavigation: false,
};

export const RESET_PASSWORD_PAGE: RouteConstant = {
  path: "/reset-password",
  name: "pages.resetPassword",
  auth: false,
  showNavigation: false,
};

export const AUTH_ACTION_PAGE: RouteConstant = {
  path: "/action",
  name: "pages.authAction",
  auth: false,
  showNavigation: false,
};

export const LANDING_PAGE: RouteConstant = {
  path: "/",
  name: "pages.home",
  auth: false,
  showNavigation: true,
};

export const BLOG_PAGE: RouteConstant = {
  path: "/blog",
  name: "pages.blog",
  auth: true,
  showNavigation: true,
};

export const BLOG_CATEGORY_PAGE = {
  path: "/category/:categoryId",
  name: "pages.blog",
  auth: true,
  showNavigation: true,
} as const;

export const BLOG_POST_PAGE = {
  path: "/blog/:articleId",
  name: "pages.blogPost",
  auth: false,
  showNavigation: true,
} as const;

export const PRODUCT_LIST_PAGE = {
  path: "/products",
  name: "pages.products",
  auth: false,
  showNavigation: true,
};

export const PRODUCT_DETAILS_PAGE = {
  path: "/products/:productId",
  name: "pages.product",
  auth: false,
  showNavigation: true,
} as const;

export const CART_PAGE = {
  path: "/cart",
  name: "pages.cart",
  auth: false,
  showNavigation: true,
};

export const CHECKOUT_PAGE = {
  path: "/checkout/:checkoutId",
  name: "pages.checkout",
  auth: false,
  showNavigation: true,
} as const;

// not used
export const ORDER_DETAILS_PAGE = {
  path: "/order/:orderId",
  name: "pages.order",
  auth: false,
  showNavigation: true,
} as const;

export const USER_PROFILE_PAGE = {
  path: "/profile",
  name: "pages.profile",
  auth: true,
  showNavigation: true,
};

export const MY_MENO_PAGE = {
  path: "/my-meno",
  name: "pages.myMeno",
  auth: true,
  showNavigation: true,
};

export const DOCTORS_PAGE = {
  path: "/doctors",
  name: "pages.doctors",
  auth: true,
  showNavigation: true,
};

export const FAVOURITE_PAGE = {
  path: "/favourites",
  name: "pages.favourites",
  auth: false,
  showNavigation: true,
};

export const FORUM_THREADS_PAGE = {
  path: "/threads",
  name: "pages.forum",
  auth: true,
  showNavigation: true,
};

export const FORUM_THREAD_DETAILS_PAGE = {
  path: "/thread/:threadId",
  name: "pages.forum",
  auth: true,
  showNavigation: true,
} as const;

export const FAQ_PAGE = {
  path: "/faq",
  name: "pages.favourites",
  auth: false,
  showNavigation: true,
};

export const PAYMENT_SUMMARY_PAGE = {
  path: "/payment-summary",
  name: "pages.paymentSummary",
  auth: false,
  showNavigation: true,
};

export const CONTACT_PAGE = {
  path: "/contact",
  name: "pages.contact",
  auth: false,
  showNavigation: true,
};
