import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { CalendarContext } from "../calendar";
import { useFirebaseFetch } from "hooks";
import { RootState } from "store";
import { OptionSection } from "./option-section/option-section";
import { ReportProps } from "./report.types";
import { MenstruationSection } from "./menstruation-section/menstruation-section";
import { getUserMenstruation, getUserReport, postReport, ReportData } from "firestore";
import { brain, psyche, body, beauty, sex, energy, reportInitialState } from "./report.constants";
import { MenstruationForm } from "../menstruation/menstruation-form";

import styles from "./report.module.scss";

export const Report: React.FC<ReportProps> = ({ updateWeeklyReports, updateWeeklyMenstruation }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedDate } = useContext(CalendarContext);

  const [isMenstruationFormOpen, setMenstruationFormOpen] = useState<boolean>(false);

  // TODO: handle loading and error states
  const { data: userReport, refresh: refreshReport } = useFirebaseFetch(
    () => getUserReport(user?.id || "", selectedDate),
    {
      dependencies: [selectedDate],
    },
  );

  const {
    data: menstruationData,
    loading,
    refresh: refreshMenstruationData,
  } = useFirebaseFetch(() => getUserMenstruation(user?.id || "", selectedDate), {
    dependencies: [selectedDate],
  });

  const handleSubmit = async (values: ReportData, { resetForm }: FormikHelpers<ReportData>) => {
    if (user) {
      try {
        await postReport(user.id, selectedDate, values);

        refreshReport();
        resetForm({ values });
        updateWeeklyReports();
        enqueueSnackbar(t("myMeno.createReportSuccess"), { variant: "success" });
      } catch (error) {
        enqueueSnackbar(t("myMeno.createReportError"), { variant: "error" });
      }
    }
  };

  const handleMenstruationFormOpen = (isOpen: boolean) => () => setMenstruationFormOpen(isOpen);
  const onMenstruationSuccess = () => {
    refreshMenstruationData();
    updateWeeklyMenstruation();
  };

  return (
    <div className={styles.wrapper}>
      <Formik initialValues={reportInitialState(userReport)} onSubmit={handleSubmit} enableReinitialize>
        {({ dirty, resetForm }) => (
          <Form>
            {dirty && (
              <div className={styles.row}>
                <Button type="button" onClick={() => resetForm()}>
                  {t("common.cancel")}
                </Button>
                <Button type="submit" variant="contained" sx={{ color: "#fff" }}>
                  {t("common.submit")}
                </Button>
              </div>
            )}
            <MenstruationSection
              menstruation={menstruationData}
              openMenstruationForm={handleMenstruationFormOpen(true)}
              loading={loading}
            />
            <OptionSection sectionName={t("myMeno.brain")} sectionKey="brain" options={brain(t)} />
            <OptionSection sectionName={t("myMeno.psyche")} sectionKey="psycho" options={psyche(t)} />
            <OptionSection sectionName={t("myMeno.body")} sectionKey="body" options={body(t)} />
            <OptionSection sectionName={t("myMeno.beauty")} sectionKey="beauty" options={beauty(t)} />
            <OptionSection sectionName={t("myMeno.sex")} sectionKey="sex" options={sex(t)} />
            <OptionSection sectionName={t("myMeno.energy")} sectionKey="energy" options={energy(t)} single />
          </Form>
        )}
      </Formik>
      <MenstruationForm
        isOpen={isMenstruationFormOpen}
        handleClose={handleMenstruationFormOpen(false)}
        onSuccess={onMenstruationSuccess}
      />
    </div>
  );
};
