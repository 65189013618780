import React, { useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button, IconButton, TextField } from "@mui/material";
import { useDidMount, useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useDispatch, useSelector } from "react-redux";
import { Minimize, ChatBubbleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { Message } from "./message/message";
import { Summary } from "./summary/summary";
import { ConversationEndButtons } from "./conversation-end-buttons/conversation-end-buttons";
import { BlockId, SavedAnswer } from "./chatbot.types";
import { chatBlocks } from "./chatbot.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { calculatePoints, getNextBlockId, prepareAnswerObject } from "./chatbot.utils";
import { RootState, setChatbotOpen } from "store";
import { useFirebaseFetch, useUser } from "hooks";
import { getUserConversation, postUserConversation } from "firestore";
import { getFirstName } from "utils";

import styles from "./chatbot.module.scss";

export const Chatbot: React.FC = () => {
  const dispatch = useDispatch();
  const messagesRef = useRef<HTMLDivElement | null>(null);
  const savedEmail = localStorage.getItem(STORAGE_FIELDS.chatbot_email);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, user } = useUser();
  const { isChatbotOpen } = useSelector((state: RootState) => state.app);

  const [name, setName] = useState<string | null>(getFirstName(user?.displayName) || null);
  const [email, setEmail] = useState<string | null>(user?.email || savedEmail || null);

  const [inputText, setInputText] = useState<string>("");
  const [currentBlockId, setCurrentBlockId] = useState<BlockId | null>("name");
  const [chatConversation, setChatConversation] = useState<SavedAnswer[]>([]);

  const { data, loading } = useFirebaseFetch(() => getUserConversation(email || ""), { dependencies: [] });

  useDidUpdate(() => {
    if (data) {
      const nameAnswer = data.conversation.find((conversation) => conversation.id === "name");
      if (nameAnswer?.answer?.value) setName(nameAnswer.answer.value.toString());
      setChatConversation(data.conversation);

      const lastIndex = data.conversation.length - 1;

      if (lastIndex !== -1) {
        const lastSavedItem = data.conversation[lastIndex];

        const nextId = getNextBlockId(lastSavedItem.id, lastSavedItem.answer.value);
        setCurrentBlockId(nextId);
      }
    }
  }, [data]);

  const currentBlock = useMemo(() => {
    return chatBlocks.find((chatBlock) => chatBlock.id === currentBlockId) || null;
  }, [currentBlockId]);

  useDidUpdate(
    () => {
      const hasFinishedConversation = user && !currentBlock;

      if (!isAuthenticated || !hasFinishedConversation) {
        // localStorage.setItem(STORAGE_FIELDS.chatbot_open, "true");
        // dispatch(setChatbotOpen(true));
      } else {
        localStorage.setItem(STORAGE_FIELDS.chatbot_open, "false");
        dispatch(setChatbotOpen(false));
      }
    },
    [user, currentBlock],
    true,
  );

  const scrollToBottom = () => {
    messagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const pushNewAnswer = (answer: SavedAnswer) =>
    setChatConversation((prevConversation) => [...prevConversation, answer]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const getFirstQuestion = () => {
    if (name && email) {
      setCurrentBlockId("age");
    } else {
      setCurrentBlockId("name");
    }
  };

  const startOver = () => {
    if (email) {
      postUserConversation(email, [], null)
        .then(() => {
          setChatConversation([]);
          getFirstQuestion();
        })
        .catch(() => {
          enqueueSnackbar(t("chatbot.startOverError"), { variant: "error" });
        });
    }
  };

  useDidMount(() => {
    getFirstQuestion();

    setTimeout(() => {
      scrollToBottom();
    }, 400);
  });

  useDidUpdate(() => {
    scrollToBottom();

    if (name && email && chatConversation.length > 0) {
      const points = currentBlock?.last ? calculatePoints(chatConversation) : null;
      postUserConversation(email, chatConversation, points).then();
    }
  }, [chatConversation, currentBlock, name, email]);

  const saveInputValue = () => {
    if (currentBlock && inputText) {
      const nextId = currentBlock.input?.getNextBlock(inputText) || null;

      if (currentBlock.id === "name") setName(inputText);
      if (currentBlock.id === "email") {
        localStorage.setItem(STORAGE_FIELDS.chatbot_email, inputText);
        setEmail(inputText);
      }

      const chatbotMessage = currentBlock.text({ name: name || "", isAuthenticated });
      const answerObject = prepareAnswerObject(currentBlock, chatbotMessage, inputText);

      pushNewAnswer(answerObject);
      setInputText("");

      setCurrentBlockId(nextId);
    }
  };

  type SaveButtonArguments = { answerLabel: string | number; answerValue: string | number; nextId: BlockId | null };

  const saveButtonValue =
    ({ answerLabel, answerValue, nextId }: SaveButtonArguments) =>
    () => {
      if (currentBlock) {
        const chatbotMessage = currentBlock.text({ name: name || "", isAuthenticated });
        const answerObject = prepareAnswerObject(currentBlock, chatbotMessage, answerLabel, answerValue);
        pushNewAnswer(answerObject);
        setCurrentBlockId(nextId);
      }
    };

  const minimizeChatbot = () => {
    dispatch(setChatbotOpen(false));
  };

  const closeChatbot = () => {
    minimizeChatbot();
  };

  const openChatbot = () => {
    if (!isChatbotOpen) dispatch(setChatbotOpen(true));
  };

  const element = document.getElementById("modal-root");

  if (!element || loading) return null;

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={isChatbotOpen ? styles.wrapper : styles.wrapperClosed} onClick={openChatbot}>
      <ChatBubbleOutline className={styles.openIcon} />
      <div className={styles.navbar}>
        <IconButton onClick={minimizeChatbot} className={styles.iconButton}>
          <Minimize sx={{ color: "#fff" }} className={styles.minimize} />
        </IconButton>
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          {chatConversation.map((conversation) => (
            <div key={conversation.id} className={styles.block}>
              <Message message={conversation.text} sender="bot" />
              <Message message={conversation.answer.label} sender="user" />
            </div>
          ))}
          {currentBlock && <Message message={currentBlock.text({ name: name || "", isAuthenticated })} sender="bot" />}
          {currentBlock?.last && (
            <Summary
              conversation={chatConversation}
              name={name || ""}
              isAuthenticated={isAuthenticated}
              startOver={startOver}
              closeChatbot={closeChatbot}
            />
          )}
          <div ref={messagesRef} />

          <div className={styles.buttons}>
            {currentBlock?.buttons?.map((button) => (
              <Button
                key={button.value}
                onClick={saveButtonValue({
                  answerLabel: button.label,
                  answerValue: button.value,
                  nextId: button.nextId,
                })}
                variant={button.variant || "contained"}
                size="small"
                sx={{
                  color: button.variant === "outlined" ? "e7a59f" : "#fff",
                  fontWeight: 600,
                }}
              >
                {button.label}
              </Button>
            ))}
          </div>
          {(currentBlock?.registerButtons || currentBlockId === null) && (
            <ConversationEndButtons startOver={startOver} closeChatbot={closeChatbot} />
          )}
        </div>
        {currentBlock?.input && (
          <div className={styles.inputs}>
            <div className={styles.inputWrapper}>
              <TextField
                value={inputText}
                name={currentBlock.input.name}
                type={currentBlock.input.type}
                onChange={handleInputChange}
                placeholder={currentBlock.input.placeholder}
                size="small"
                inputProps={{ style: { border: "none" } }}
              />
              <Button onClick={saveInputValue}>{t("common.save")}</Button>
            </div>
          </div>
        )}
      </div>
    </div>,
    element,
  );
};
