import React from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

import { FormSelectTypes } from "./form-select.types";

import styles from "./form-select.module.css";

export const FormSelect = ({
  name,
  labelKey = "label",
  valueKey = "value",
  options,
  wantEmpty = false,
  ...rest
}: FormSelectTypes) => {
  const { t } = useTranslation();

  const [field, meta] = useField(name);

  const { touched, error } = meta;

  const itemsList = options.map((item) => (
    <MenuItem key={item[valueKey]} value={item[valueKey]}>
      <Typography variant="body2">{item[labelKey]}</Typography>
    </MenuItem>
  ));

  return (
    <TextField
      {...field}
      className={styles.textInput}
      id={name}
      error={touched && Boolean(error)}
      helperText={touched && error}
      FormHelperTextProps={{ className: styles.helper }}
      select
      {...rest}
    >
      {wantEmpty && (
        <MenuItem sx={{ color: "warning.main" }} value="">
          <Typography variant="body2">{t("common.default")}</Typography>
        </MenuItem>
      )}

      {itemsList}
    </TextField>
  );
};
