export const doctors = {
  addDoctor: "Dodaj lekarza",
  name: "Imię",
  surname: "Nazwisko",
  address: "Adres",
  city: "Miasto",
  email: "Email",
  website: "Strona internetowa",
  voivodeship: "Województwo",
  specialization: "Specjalizacja",
  phoneNumber: "Numer telefonu",
  opinion: "Opinia, uwagi",

  add: "Dodaj",

  required: "To pole jest wymagane",
  incorrectEmail: "Email jest nieprawidłowy",
  incorrectPhone: "Nieprawidłowy numer telefonu",

  doctorAdded: "Lekarz został dodany",
  doctorAddError: "Błąd podczas dodawania lekarza",
  incorrectAge: "Podany wiek jest nieprawidłowy (16-100)",
};
