export const product = {
  attributes: "Attributes",
  variant: "Variant",
  addToCart: "Add to cart",

  selectVariant: "You must select product variant",
  selectedVariant: "Variant: {{variant}}",
  category: "Category",
  collection: "Collection",
};
