export const menstruation = {
  title: "Blooding",
  intensityLabel: "Intensity",
  bloodingColorLabel: "Blooding color",
  color: {
    red: "Red",
    brightRed: "Bright red",
    darkRed: "Dark red",
    pink: "Pink",
    pinkOrange: "Pink-orange",
  },
  intensity: {
    spotting: "Spotting",
    low: "Low blooding",
    medium: "Medium blooding",
    intense: "Intense blooding",
  },
  saveDataSuccess: "Data has been saved!",
  saveDataError: "Could not save your data",
};
