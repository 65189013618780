import { gql } from "@apollo/client";

export const AddressDetailsFragment = gql`
  fragment AddressDetailsFragment on Address {
    id
    phone
    firstName
    lastName
    streetAddress1
    city
    postalCode
    isDefaultBillingAddress
    isDefaultShippingAddress
    country {
      code
      country
    }
  }
`;

export const PriceFragment = gql`
  fragment PriceFragment on Money {
    currency
    amount
  }
`;

export const ImageFragment = gql`
  fragment ImageFragment on Image {
    url
    alt
  }
`;

export const CheckoutLineDetailsFragment = gql`
  fragment CheckoutLineDetailsFragment on CheckoutLine {
    id
    totalPrice {
      gross {
        ...PriceFragment
      }
    }
    variant {
      id
      product {
        id
        name
        translation(languageCode: $locale) {
          id
          name
        }
        slug
        thumbnail {
          ...ImageFragment
        }
      }
      pricing {
        price {
          gross {
            ...PriceFragment
          }
        }
      }
      name
      translation(languageCode: $locale) {
        id
        name
      }
    }
    quantity
  }
  ${PriceFragment}
  ${ImageFragment}
`;

export const DeliveryMethodFragment = gql`
  fragment DeliveryMethodFragment on ShippingMethod {
    id
    name
    translation(languageCode: $locale) {
      id
      name
    }
    price {
      ...PriceFragment
    }
    minimumDeliveryDays
    maximumDeliveryDays
  }
  ${PriceFragment}
`;

export const CheckoutDetailsFragment = gql`
  fragment CheckoutDetailsFragment on Checkout {
    id
    token
    email
    billingAddress {
      ...AddressDetailsFragment
    }
    shippingAddress {
      ...AddressDetailsFragment
    }
    shippingMethod {
      ...DeliveryMethodFragment
    }
    isShippingRequired
    availableShippingMethods {
      ...DeliveryMethodFragment
    }
    availablePaymentGateways {
      id
      name
      config {
        field
        value
      }
    }
    lines {
      ...CheckoutLineDetailsFragment
    }
    discount {
      ...PriceFragment
    }
    discountName
    subtotalPrice {
      net {
        ...PriceFragment
      }
      tax {
        ...PriceFragment
      }
    }
    shippingPrice {
      gross {
        ...PriceFragment
      }
    }
    totalPrice {
      gross {
        ...PriceFragment
      }
    }
  }
`;

export const CheckoutDetailsFragmentDoc = gql`
  fragment CheckoutDetailsFragment on Checkout {
    id
    token
    email
    billingAddress {
      ...AddressDetailsFragment
    }
    shippingAddress {
      ...AddressDetailsFragment
    }
    shippingMethod {
      ...DeliveryMethodFragment
    }
    isShippingRequired
    availableShippingMethods {
      ...DeliveryMethodFragment
    }
    availablePaymentGateways {
      id
      name
      config {
        field
        value
      }
    }
    lines {
      ...CheckoutLineDetailsFragment
    }
    discount {
      ...PriceFragment
    }
    discountName
    subtotalPrice {
      net {
        ...PriceFragment
      }
      tax {
        ...PriceFragment
      }
    }
    shippingPrice {
      gross {
        ...PriceFragment
      }
    }
    totalPrice {
      gross {
        ...PriceFragment
      }
    }
  }
  ${AddressDetailsFragment}
  ${DeliveryMethodFragment}
  ${CheckoutLineDetailsFragment}
  ${PriceFragment}
`;

export const CollectionBasicFragmentDoc = gql`
  fragment CollectionBasicFragment on Collection {
    id
    name
    translation(languageCode: $locale) {
      id
      name
    }
    slug
  }
`;

export const ErrorDetailsFragmentDoc = gql`
  fragment ErrorDetailsFragment on CheckoutError {
    field
    message
    code
  }
`;

export const AddressDetailsFragmentDoc = gql`
  fragment AddressDetailsFragment on Address {
    id
    phone
    firstName
    lastName
    streetAddress1
    city
    postalCode
    isDefaultBillingAddress
    isDefaultShippingAddress
    country {
      code
      country
    }
  }
`;

export const PriceFragmentDoc = gql`
  fragment PriceFragment on Money {
    currency
    amount
  }
`;

export const CheckoutByTokenDocument = gql`
  query CheckoutByToken($checkoutToken: UUID!, $locale: LanguageCodeEnum!) {
    checkout(token: $checkoutToken) {
      ...CheckoutDetailsFragment
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const ADD_PRODUCT_TO_CHECKOUT = gql`
  mutation CheckoutAddProductLine($checkoutToken: UUID!, $variantId: ID!, $locale: LanguageCodeEnum!) {
    checkoutLinesAdd(token: $checkoutToken, lines: [{ quantity: 1, variantId: $variantId }]) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        message
        code
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const CREATE_CHECKOUT = gql`
  mutation CreateCheckout($email: String!, $lines: [CheckoutLineInput!]!, $channel: String!) {
    checkoutCreate(input: { channel: $channel, email: $email, lines: $lines }) {
      checkout {
        id
        token
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export const REMOVE_CHECKOUT_PRODUCT = gql`
  mutation RemoveProductFromCheckout($checkoutToken: UUID!, $lineId: ID!, $locale: LanguageCodeEnum!) {
    checkoutLineDelete(token: $checkoutToken, lineId: $lineId) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        field
        message
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const UPDATE_CHECKOUT_PRODUCT = gql`
  mutation CheckoutLineUpdate($token: UUID, $lines: [CheckoutLineInput!]!, $locale: LanguageCodeEnum!) {
    checkoutLinesUpdate(token: $token, lines: $lines) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        ...ErrorDetailsFragment
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
  ${ErrorDetailsFragmentDoc}
`;

export const UPDATE_CHECKOUT_EMAIL = gql`
  mutation CheckoutEmailUpdate($token: UUID!, $email: String!, $locale: LanguageCodeEnum!) {
    checkoutEmailUpdate(email: $email, token: $token) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        field
        message
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const UPDATE_CHECKOUT_BILLING_ADDRESS = gql`
  mutation CheckoutBillingAddressUpdate($token: UUID!, $address: AddressInput!, $locale: LanguageCodeEnum!) {
    checkoutBillingAddressUpdate(billingAddress: $address, token: $token) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        field
        message
        code
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const UPDATE_CHECKOUT_SHIPPING_ADDRESS = gql`
  mutation CheckoutShippingAddressUpdate($token: UUID!, $address: AddressInput!, $locale: LanguageCodeEnum!) {
    checkoutShippingAddressUpdate(shippingAddress: $address, token: $token) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        field
        message
        code
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const UPDATE_SHIPPING_METHOD = gql`
  mutation CheckoutShippingMethodUpdate($token: UUID!, $shippingMethodId: ID!, $locale: LanguageCodeEnum!) {
    checkoutShippingMethodUpdate(shippingMethodId: $shippingMethodId, token: $token) {
      checkout {
        ...CheckoutDetailsFragment
      }
      errors {
        field
        message
        code
      }
    }
  }
  ${CheckoutDetailsFragmentDoc}
`;

export const CREATE_ORDER_FROM_CHECKOUT = gql`
  mutation orderCreateFromCheckout($checkoutId: ID!, $removeCheckout: Boolean) {
    orderCreateFromCheckout(id: $checkoutId, removeCheckout: $removeCheckout) {
      order {
        id
      }
    }
  }
`;

export const CREATE_CHECKOUT_PAYMENT = gql`
  mutation checkoutPaymentCreate($checkoutId: ID!, $input: PaymentInput!) {
    #  mutation checkoutPaymentCreate($checkoutID: UUID!, $checkoutToken: UUID!, $paymentInput: PaymentInput!) {
    checkoutPaymentCreate(checkoutId: $checkoutId, input: $input) {
      #    checkoutPaymentCreate(checkoutId: $checkoutID, token: $checkoutToken, input: $paymentInput) {
      payment {
        id
        total {
          ...PriceFragment
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${PriceFragmentDoc}
`;

export const COMPLETE_CHECKOUT = gql`
  mutation checkoutComplete($checkoutId: ID!, $paymentData: JSONString) {
    #  mutation checkoutComplete($checkoutToken: UUID!, $paymentData: JSONString) {
    checkoutComplete(checkoutId: $checkoutId, paymentData: $paymentData) {
      #    checkoutComplete(token: $checkoutToken, paymentData: $paymentData) {
      order {
        id
        status
        token
        number
        billingAddress {
          id
          ...AddressDetailsFragment
        }
        shippingAddress {
          id
          ...AddressDetailsFragment
        }
      }
      confirmationNeeded
      confirmationData
      errors {
        field
        message
        variants
        addressType
      }
    }
  }
  ${AddressDetailsFragmentDoc}
`;
