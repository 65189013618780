export const voivodeshipOptions = [
  { name: "dolnośląskie", value: "dolnośląskie" },
  { name: "kujawsko-pomorskie", value: "kujawsko-pomorskie" },
  { name: "lubelskie", value: "lubelskie" },
  { name: "lubuskie", value: "lubuskie" },
  { name: "łódzkie", value: "łódzkie" },
  { name: "małopolskie", value: "małopolskie" },
  { name: "mazowieckie", value: "mazowieckie" },
  { name: "opolskie", value: "opolskie" },
  { name: "podkarpackie", value: "podkarpackie" },
  { name: "podlaskie", value: "podlaskie" },
  { name: "pomorskie", value: "pomorskie" },
  { name: "śląskie", value: "śląskie" },
  { name: "świętokrzyskie", value: "świętokrzyskie" },
  { name: "warmińsko-mazurskie", value: "warmińsko-mazurskie" },
  { name: "wielkopolskie", value: "wielkopolskie" },
  { name: "zachodniopomorskie", value: "zachodniopomorskie" },
];
