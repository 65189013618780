export const home = {
  welcomeNameTitle: "Witaj, {{name}}!",
  welcomeTitle: "Dzień dobry",
  welcomeQuestion: "Jak się dzisiaj czujesz?",

  fine: "Jest w porządku",
  irritability: "Jestem rozdrażniona",
  sad: "Smutno mi",
  insomnia: "Słabo spałam",
  bodyHurts: "Boli mnie ciało",

  alreadyInStore: "Już \n w sprzedaży",
  myMeno: "Moja meno",
  whatsNew: "Co nowego?",
  howCanI: "Jak mogę...",

  lookOnTheBlog: "Zobacz więcej",
  completeYourReport: "Wypełnij swój dzienny raport samopoczucia",
};
