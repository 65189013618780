import { gql } from "@apollo/client";

export const GET_PRODUCT_COLLECTIONS = gql`
  query getProductCollections($first: Int, $channel: String!) {
    collections(first: $first, channel: $channel) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories($first: Int) {
    categories(first: $first) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;
