export const pages = {
  home: "Home",
  myMeno: "MyMeno",
  blog: "Blog",
  learn: "Learn",
  shop: "Mameno store",
  forum: "Forum",
  profile: "Profile",
  signIn: "Sign in",
  signOut: "Log out",
  signUp: "Register",
  resetPassword: "Reset password",
  products: "Products",
  product: "Product",
  blogPost: "Blog post",
  logOut: "Log out",
  favourites: "Favourites",
  faq: "FAQ",
  cart: "Cart",
  checkout: "Checkout",
  order: "Order",
  orderList: "List of orders",
  paymentSummary: "Payment summary",
  doctors: "Doctors",
  blogAll: "All posts",
  authAction: "Auth action",
  contact: "Contact",
};
