export const advice = {
  formSent: "Form has been sent",
  formError: "Error while sending the form",

  adviceForm: "Form",
  tellYourStory: "Tell your story",

  name: "Name",
  age: "Age",
  menopauseDiagnosed:
    "Have you been diagnosed, or you suspect that you are in premenopause, perimenopause, postmenopause?",
  whatDidYouKnow: "What did you know about menopause before you started your journey?",
  mostSurprising: "What was the most surprising aspect of menopause for you?",
  howItsGoing: "How do you cope with the symptoms of menopause?",
  howDoesItImpact: "How has menopause affected your personal and professional life?",
  adviceQuestion: "What advice would you give to a younger women about what to expect during this period?",

  privacyPolicy: "privacy policy*",
};
