import {
  LoginPage,
  RegisterPage,
  LandingPage,
  PostListPage,
  PostDetailsPage,
  LogoutPage,
  CategoryPage,
  ProductListPage,
  ProductDetailsPage,
  UserProfilePage,
  MyMenoPage,
  FavouritePage,
  FaqPage,
  ResetPasswordPage,
  CartPage,
  CheckoutPage,
  PaymentSummaryPage,
  DoctorsPage,
  ForumThreadListPage,
  ForumThreadDetailsPage,
  ActionPage,
  ContactPage,
} from "pages";
import {
  LOGIN_PAGE,
  REGISTER_PAGE,
  LANDING_PAGE,
  BLOG_PAGE,
  BLOG_POST_PAGE,
  LOGOUT_PAGE,
  BLOG_CATEGORY_PAGE,
  PRODUCT_LIST_PAGE,
  PRODUCT_DETAILS_PAGE,
  USER_PROFILE_PAGE,
  MY_MENO_PAGE,
  FAVOURITE_PAGE,
  FAQ_PAGE,
  RESET_PASSWORD_PAGE,
  CART_PAGE,
  CHECKOUT_PAGE,
  PAYMENT_SUMMARY_PAGE,
  DOCTORS_PAGE,
  FORUM_THREADS_PAGE,
  FORUM_THREAD_DETAILS_PAGE,
  AUTH_ACTION_PAGE,
  CONTACT_PAGE,
} from "constants/routes.constants";
import { RouteConfig } from "types";

export const routes: RouteConfig[] = [
  { ...LOGIN_PAGE, component: LoginPage },
  { ...LOGOUT_PAGE, component: LogoutPage },
  { ...REGISTER_PAGE, component: RegisterPage },
  { ...RESET_PASSWORD_PAGE, component: ResetPasswordPage },
  { ...AUTH_ACTION_PAGE, component: ActionPage },
  { ...LANDING_PAGE, component: LandingPage },
  { ...BLOG_PAGE, component: PostListPage },
  { ...DOCTORS_PAGE, component: DoctorsPage },
  { ...BLOG_CATEGORY_PAGE, component: CategoryPage },
  { ...BLOG_POST_PAGE, component: PostDetailsPage },
  { ...PRODUCT_LIST_PAGE, component: ProductListPage },
  { ...PRODUCT_DETAILS_PAGE, component: ProductDetailsPage },
  { ...CART_PAGE, component: CartPage },
  { ...CHECKOUT_PAGE, component: CheckoutPage },
  { ...USER_PROFILE_PAGE, component: UserProfilePage },
  { ...MY_MENO_PAGE, component: MyMenoPage },
  { ...FAVOURITE_PAGE, component: FavouritePage },
  { ...FAQ_PAGE, component: FaqPage },
  { ...FORUM_THREADS_PAGE, component: ForumThreadListPage },
  { ...FORUM_THREAD_DETAILS_PAGE, component: ForumThreadDetailsPage },
  { ...PAYMENT_SUMMARY_PAGE, component: PaymentSummaryPage },
  { ...CONTACT_PAGE, component: ContactPage },
];
