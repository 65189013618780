import React, { useMemo, useRef } from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import { Card, Grid, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ArticleFavourite } from "components";
import { extractImageUrl, getLinkPath } from "utils";
import { ArticleCardProps } from "./article-card.types";
import { BLOG_POST_PAGE } from "constants/routes.constants";
import { environment } from "config";

import styles from "./article-card.module.scss";

export const ArticleCard: React.FC<ArticleCardProps> = ({ article, reverse, className }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const image = extractImageUrl(article);
  const path = getLinkPath(BLOG_POST_PAGE.path, { articleId: article.id });
  const transformLinkUrl = (url: string) => {
    const urlPattern = /^((http|https|ftp):\/\/)/;

    if (urlPattern.test(url)) {
      return url;
    }

    return `${environment.serverUrl}${url}`;
  };

  const PADDING_WITH_GAP_HEIGHT = 48;

  const titleHeight = useMemo(() => {
    if (titleRef.current) return titleRef.current.clientHeight + PADDING_WITH_GAP_HEIGHT;
    return 42 + PADDING_WITH_GAP_HEIGHT;
  }, [titleRef.current]);

  return (
    <Card className={classNames(styles.container, className)}>
      <div className={styles.row} ref={titleRef}>
        <Link to={path} style={{ color: "#000" }}>
          <Typography variant="h4" fontWeight={600} sx={{ hyphens: "auto" }} className={styles.title}>
            {article.attributes.title}
          </Typography>
        </Link>
        <ArticleFavourite articleId={article.id} />
      </div>

      <div
        className={reverse ? styles.reverseContent : styles.content}
        style={{ height: `calc(100% - ${titleHeight}px)` }}
      >
        <div className={styles.textContainer}>
          <ReactMarkdown transformLinkUri={transformLinkUrl}>{article.attributes.text}</ReactMarkdown>
          <div className={styles.bottomBar} />
        </div>
        {image && <img src={image} alt="article card" className={reverse ? styles.imageLeftAlign : styles.image} />}
      </div>
      <Link to={path} style={{ color: "#000" }} className={styles.lookOnBlog}>
        <Grid container gap={2}>
          {t("home.lookOnTheBlog")}
          <ArrowForward />
        </Grid>
      </Link>
    </Card>
  );
};
