import { TFunction } from "i18next";

export const weekdaysShort = (t: TFunction) => [
  t("common.daysShort.monday"),
  t("common.daysShort.tuesday"),
  t("common.daysShort.wednesday"),
  t("common.daysShort.thursday"),
  t("common.daysShort.friday"),
  t("common.daysShort.saturday"),
  t("common.daysShort.sunday"),
];

export const weekdays = (t: TFunction) => [
  t("common.days.monday"),
  t("common.days.tuesday"),
  t("common.days.wednesday"),
  t("common.days.thursday"),
  t("common.days.friday"),
  t("common.days.saturday"),
  t("common.days.sunday"),
];

export const months = (t: TFunction) => [
  t("common.months.january"),
  t("common.months.february"),
  t("common.months.march"),
  t("common.months.april"),
  t("common.months.may"),
  t("common.months.june"),
  t("common.months.july"),
  t("common.months.august"),
  t("common.months.september"),
  t("common.months.october"),
  t("common.months.november"),
  t("common.months.december"),
];
