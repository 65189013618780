import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { PagesResultsProps } from "./pages-results.types";
import { SIDEBAR_SECTIONS } from "components/client/sidebar/sidebar.constants";
import { RootState } from "store";

import styles from "../global-search.module.scss";

export const PagesResults: React.FC<PagesResultsProps> = ({ searchValue, resetState }) => {
  const { t } = useTranslation();
  const { categories } = useSelector((state: RootState) => state.categories);

  const toLowerCase = (value: string) => value.toLowerCase();

  const sections = SIDEBAR_SECTIONS(t, categories);
  const filteredSections = sections.filter(({ sectionName }) =>
    toLowerCase(sectionName).includes(toLowerCase(searchValue)),
  );

  const showNoContent = filteredSections.length === 0;

  return (
    <div>
      <Typography className={styles.title}>{t("common.pages")}</Typography>
      {showNoContent && <Typography className={styles.sectionParagraph}>{t("common.noResults")}</Typography>}
      {filteredSections.map((section) => (
        <div key={section.sectionName}>
          {section.path && (
            <Link to={section.path} onClick={resetState} className={styles.link}>
              {section.sectionName}
            </Link>
          )}
          {!section.path && (
            <div className={styles.border}>
              <Typography variant="subtitle1" className={styles.sectionParagraph}>
                {t("common.section", { name: section.sectionName })}
              </Typography>
              <div className={styles.sectionWrapper}>
                {section.items.map((item) => (
                  <Link key={item.path} onClick={resetState} to={item.path} className={styles.innerLink}>
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
