import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, List, useTheme } from "@mui/material";
import { Logout, Login, Close } from "@mui/icons-material";

// import { LanguageSelect } from "components";
import { SidebarSection } from "./sidebar-section/sidebar-section";
import { SIDEBAR_SECTIONS } from "components/client/sidebar/sidebar.constants";
import { RootState, setMenuOpen } from "store";
import { useUser } from "hooks";
import { LANDING_PAGE, LOGIN_PAGE, LOGOUT_PAGE } from "constants/routes.constants";

import { ReactComponent as Logo } from "assets/icons/logo.svg";

import styles from "./sidebar.module.scss";

export const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { palette } = useTheme();
  const { menuOpen } = useSelector((state: RootState) => state.ui);
  const { isAuthenticated } = useUser();
  const { categories } = useSelector((state: RootState) => state.categories);

  const onLogoutClick = () => navigate(LOGOUT_PAGE.path);
  const onLoginClick = () => navigate(LOGIN_PAGE.path);

  const onMenuClose = () => dispatch(setMenuOpen(false));

  const classes = classNames({ [styles.boxOpened]: menuOpen }, { [styles.box]: !menuOpen });

  return (
    <Box className={classes} sx={{ backgroundColor: "background.paper" }}>
      <IconButton className={styles.closeIcon} onClick={onMenuClose}>
        <Close />
      </IconButton>
      <Link to={LANDING_PAGE.path} onClick={onMenuClose}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
        </div>
      </Link>
      {/* <ListItem disablePadding> */}
      {/*  <LanguageSelect sx={{ fontSize: 14, px: 2, color: "text.secondary", my: -1 }} fullWidth /> */}
      {/* </ListItem> */}
      <List className={styles.sidebarContainer}>
        {SIDEBAR_SECTIONS(t, categories).map((sectionProps) => (
          <SidebarSection key={sectionProps.sectionName} {...sectionProps} />
        ))}
      </List>
      <div className={styles.logoutContainer}>
        <Button
          onClick={isAuthenticated ? onLogoutClick : onLoginClick}
          sx={{ mb: 1, fontSize: "16px", color: palette.text.primary, fontWeight: 500, gap: 2 }}
        >
          {isAuthenticated ? <Logout /> : <Login />}
          {isAuthenticated ? t("pages.logOut") : t("pages.signIn")}
        </Button>
      </div>
    </Box>
  );
};
