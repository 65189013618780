export const specializationOptions = [
  { name: "Ginekolog", value: "Ginekolog" },
  { name: "Endokrynolog", value: "Endokrynolog" },
  { name: "Urolog", value: "Urolog" },
  { name: "Fizjoterapeuta", value: "Fizjoterapeuta" },
  { name: "Psychiatra", value: "Psychiatra" },
  { name: "Psychoterapeuta", value: "Psychoterapeuta" },
  { name: "Dermatolog", value: "Dermatolog" },
  { name: "Trycholog", value: "Trycholog" },
  { name: "Medycyna estetyczna", value: "Medycyna estetyczna" },
  { name: "Stomatolog", value: "Stomatolog" },
  { name: "Dietetyka", value: "Dietetyka" },
];
