export const checkout = {
  labels: {
    email: "Email*",
    firstName: "Imię*",
    lastName: "Nazwisko*",
    companyName: "Firma",
    phone: "Numer telefonu*",
    address1: "Adres*",
    address2: "Adres 2",
    city: "Miasto*",
    postalCode: "Kod pocztowy*",
    quantity: "Ilość:",
  },
  validation: {
    emailRequired: "Email jest wymagany",
    invalidEmail: "Email jest niepoprawny",
    required: "To pole jest wymagane",
  },
  details: {
    name: "Imię i nazwisko: {{firstName}} {{lastName}}",
    address: "Adres: {{postalCode}} {{city}}, {{streetAddress}}",
    phone: "Numer telefonu: {{phoneNumber}}",
    company: "Firma: {{companyName}}",
    country: "Kraj: {{country}}",

    subtotalPrice: "Cena częściowa:",
    tax: "Podatek:",
    deliveryPrice: "Cena przesyłki:",
    discount: "Zniżka:",
    totalPrice: "Cena końcowa:",
  },
  cart: {
    title: "Twój koszyk",
    goToCheckout: "Przejdź do kasy",
    noProductsTitle: "Nie dodałaś jeszcze żadnych przedmiotów",
    noProductsSubtitle: "Przejdź do sklepu i wybierz swoje produkty!",
    noProductsButton: "Przejdź do sklepu",
  },
  actions: {
    changeQuantitySuccess: "Ilość została zmieniona",
    changeQuantityError: "Nie udało się zmienić ilości produktu",

    removeProductSuccess: "Produkt został usunięty",
    removeProductError: "Nie udało się usunąć tego produktu",

    addressDataSuccess: "Zapisano",
    addressDataError: "Wystąpił problem podczas zapisywania danych",

    deliverySaveSuccess: "Zapisano sposób dostawy oraz metodę płatności",
    deliverySaveError: "Nie udało się zapisać sposobu dostawy oraz metody płatności",
  },
  payment: {
    getPaymentDetailsErrorTitle: "Nie można pobrać szczegółów płatności",
    getPaymentDetailsErrorSubtitle: "Stripe nie może zostać zainicjowany - brak konfiguracji",
    getCheckoutDataErrorTitle: "Nie można pobrać szczegółów zakupów",
    getCheckoutDataErrorSubtitle: "Nie można pobrać szczegółów transakcji - płatność nie może zostać zakończona",
    goBack: "Wróć do podsumowania",

    processing: "Twoje zamówienie jest procesowane",
    success: "Płatność zakończona sukcesem",
    successDescription: "Wszystkie szczegóły zamówienia zostaną wysłanę na twoją skrzynkę pocztową.",
    failed: "Płatność zakończona niepowodzeniem",
    failedDescription: "Wystąpił błąd podczas finalizacji twojej płatności.",
    incorrectData: "Nieprawidłowe dane",
    incorrectDataDescription: "URL lub podane dane nie zgadzają się.",

    enterCardDetails: "Wybierz formę płatności oraz wprowadź wymagane dane",
    cardDetailsDescription:
      "Podaj wszystkie wymagane pola dotyczące twojej karty. Dane te są wymagane do finalizacji procesu płatności",
    cardNumber: "Numer karty",
    cardExpiryDate: "Data ważności karty (MM / RR)",
    cvc: "CVC",

    createOrderError: "Tworzenie oferty nie powiodło się",
    completeCheckoutError: "Nie można zakończyć procesu transakcji",
    confirmPaymentError: "Nie można potwierdzić płatności",
    createPaymentObjectError: "Nie można utworzyć obiektu płatności",
    initializeCardError: "Element karty nie jest zainicjowana",

    pay: "Zapłać {{amount}} {{currency}}",

    orderSuccessfulTitle: "Zamówienie zostało złożone",
    orderNumber: "Numer twojego zamówienia: {{number}}",
    orderSuccessfulSubtitle: "Potwierdzenie oraz szczegóły transakcji zostaną wysłane na twój adres email - ",
    confirm: "Potwierdź i zamknij",

    error: "Wystąpił błąd podczas inicjalization płatności.",
    noPaymentIntentError: "Wystąpił błąd podczas inicjalizacji płatności. Wróć i spróbuj ponownie",

    cashOnDelivery: "Płatność przy odbiorze",
    fastBankTransfer: "Szybki przelew bankowy (Przelewy24)",
    payByCard: "Płatność kartą",
  },
  billingDetailsTitle: "Szczegóły płatności",
  shippingDetailsTitle: "Dane do wysyłki",
  summaryTitle: "Podsumowanie",
  useBillingDetails: "Użyj szczegółów płatności jako dane do wysyłki",
  sameAsBilling: "Takie same jak szczegóły płatności",

  addressDescription: "Uzupełnij wymagane pola - dane zostaną wykorzystane do potwierdzenia płatności",
  shippingMethodDescription:
    "Wybierz formę dostawy oraz metodę płatności. Aktualnie obsługujemy jedynie płatności online",
  summaryDescription: "Upewnij się, że wprowadzone dane są poprawne.",
  paymentDescription:
    "Wybierz formę płatności oraz uzupełnij wymagane dane. Po poprawnym wypełnieniu danych zostaniesz przekierowana na stronę banku.",

  paymentMethod: "Metoda płatności",
  noPaymentMethodsAvailable: "Brak dostępnych metod płatności",

  deliveryMethod: "Metoda dostawy",
  noDeliveryMethodAvailable: "Brak dostępnych metod płatności",
  deliveryPrice: "Cena: {{amount}} {{currency}}",
  deliveryTime: "Czas dostawy (dni) - min: {{min}} max: {{max}}",

  goToPayment: "Przejdź do płatności",
  fillAllData: "Wypełnij wszystkie wymagane dane!",
};
