import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";

import styles from "../login-form/login-form.module.scss";

interface Props {
  hideVerificationScreen: () => void;
}

export const LoginVerifyAccount: React.FC<Props> = ({ hideVerificationScreen }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.verifyContainer}>
      <div className={styles.verifyContent}>
        <Typography variant="h2" fontWeight={600} sx={{ textAlign: "center", mt: 8, mb: 3 }} className={styles.title}>
          {t("auth.login.verifyYourEmail")}
        </Typography>
        <Typography variant="body1">{t("auth.login.verifyEmailDescription")}</Typography>
        <Button type="button" variant="contained" onClick={hideVerificationScreen} sx={{ color: "#fff", mt: 4 }}>
          {t("auth.login.tryAgain")}
        </Button>
      </div>
    </div>
  );
};
