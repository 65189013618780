import { doc, getDoc, getDocs, query, setDoc, where, deleteDoc } from "firebase/firestore";

import { ReplyModel, ThreadModel, UserModel } from "models";
import { ReplyFirestoreValues, ThreadFirestoreValues, ThreadValues } from "./forum.types";
import { getCollectionRef, firestoreCollections } from "config/firebase.config";

export const getAllThreads = async (category?: string) => {
  const threadsRef = getCollectionRef<ThreadModel[]>(firestoreCollections.threads);

  const baseQuery = query(threadsRef);
  const categoryQuery = query(threadsRef, where("category", "==", category));

  // const queryRef = query(threadsRef);
  const queryRef = category ? categoryQuery : baseQuery;
  return getDocs(queryRef);
};

export const getSingleThread = (threadId: string) => {
  const collectionRef = getCollectionRef<ThreadModel>(firestoreCollections.threads);

  const documentRef = doc(collectionRef, threadId);

  return getDoc(documentRef);
};

export const getUserThreads = async (userId: string) => {
  const threadsRef = getCollectionRef<ThreadModel[]>(firestoreCollections.threads);

  const queryRef = query(threadsRef, where("createdBy", "==", userId));
  return getDocs(queryRef);
};

export const postThread = (user: UserModel, data: ThreadValues) => {
  const threadsRef = getCollectionRef(firestoreCollections.threads);

  const documentRef = doc(threadsRef);
  const values: ThreadFirestoreValues = {
    ...data,
    createdBy: user.id,
    creator: user,
    createdAt: new Date().toISOString(),
  };

  return setDoc(documentRef, values);
};

export const deleteThread = (threadId: string) => {
  const threadsRef = getCollectionRef<ThreadModel[]>(firestoreCollections.threads);
  const repliesRef = getCollectionRef<ReplyModel[]>(firestoreCollections.replies);

  const documentRef = doc(threadsRef, threadId);

  const repliesQuery = query(repliesRef, where("threadId", "==", threadId));

  getDocs(repliesQuery).then(async (snapshot) => {
    snapshot.docs.forEach((reply) => {
      deleteDoc(reply.ref);
    });
  });

  return deleteDoc(documentRef);
};

export const getThreadReplies = (threadId: string) => {
  const repliesRef = getCollectionRef<ReplyModel[]>(firestoreCollections.replies);

  const queryRef = query(repliesRef, where("threadId", "==", threadId));
  return getDocs(queryRef);
};

type ReplyArguments = { user: UserModel; threadId: string; text: string };

export const postReply = ({ user, threadId, text }: ReplyArguments) => {
  const repliesRef = getCollectionRef(firestoreCollections.replies);

  const documentRef = doc(repliesRef);
  const values: ReplyFirestoreValues = {
    createdBy: user.id,
    creator: user,
    text,
    threadId,
    createdAt: new Date().toISOString(),
  };

  return setDoc(documentRef, values);
};
