import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FormTextField } from "components";
import { inputStyles } from "../address.constants";
import { CART_PAGE } from "constants/routes.constants";
import { CheckoutTitle } from "pages/checkout";

import styles from "../address.module.scss";

export const BillingAddress: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const goBackToCart = () => navigate(CART_PAGE.path);

  return (
    <div className={styles.column}>
      <CheckoutTitle
        title={t("checkout.billingDetailsTitle")}
        description={t("checkout.addressDescription")}
        goBack={goBackToCart}
      />
      <FormTextField
        name="email"
        type="email"
        label={t("checkout.labels.email")}
        fullWidth
        inputProps={{ style: inputStyles }}
      />
      <div className={styles.grid}>
        <FormTextField
          name="billingAddress.firstName"
          label={t("checkout.labels.firstName")}
          fullWidth
          inputProps={{ style: inputStyles }}
        />
        <FormTextField
          name="billingAddress.lastName"
          label={t("checkout.labels.lastName")}
          fullWidth
          inputProps={{ style: inputStyles }}
        />
      </div>
      <FormTextField
        name="billingAddress.companyName"
        label={t("checkout.labels.companyName")}
        inputProps={{ style: inputStyles }}
      />
      <FormTextField
        name="billingAddress.phone"
        label={t("checkout.labels.phone")}
        inputProps={{ style: inputStyles }}
      />
      <FormTextField
        name="billingAddress.streetAddress1"
        label={t("checkout.labels.address1")}
        inputProps={{ style: inputStyles }}
      />
      <FormTextField
        name="billingAddress.streetAddress2"
        label={t("checkout.labels.address2")}
        inputProps={{ style: inputStyles }}
      />
      <div className={styles.grid}>
        <FormTextField
          name="billingAddress.city"
          label={t("checkout.labels.city")}
          inputProps={{ style: inputStyles }}
        />
        <FormTextField
          name="billingAddress.postalCode"
          label={t("checkout.labels.postalCode")}
          inputProps={{ style: inputStyles }}
        />
      </div>
    </div>
  );
};
