import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

import { PaymentMethodProps } from "./payment-method.types";
import { PaymentMethod as PaymentMethodValues } from "pages/checkout/checkout.types";
import { PAYMENT_METHODS } from "pages/checkout/checkout.constants";
import { CheckoutContext } from "context";

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ checkout }) => {
  const { t } = useTranslation();
  const { paymentMethod, setPaymentMethod } = useContext(CheckoutContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setPaymentMethod(value as PaymentMethodValues);
  };

  const paymentMethods = [
    // { id: PAYMENT_METHODS.cash_on_delivery, name: t("checkout.payment.cashOnDelivery") },
    { id: PAYMENT_METHODS.p24, name: t("checkout.payment.fastBankTransfer") },
    { id: PAYMENT_METHODS.card, name: t("checkout.payment.payByCard") },
  ];

  const availableMethods = checkout?.availablePaymentGateways || [];

  const showNoContent = availableMethods.length === 0;
  const showContent = availableMethods.length > 0;

  return (
    <div>
      <Typography variant="h4" fontWeight={700} sx={{ mt: 4, mb: 2 }}>
        {t("checkout.paymentMethod")}
      </Typography>
      {showNoContent && <Typography>{t("checkout.noPaymentMethodsAvailable")}</Typography>}
      {showContent && (
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="paymentMethod"
          value={paymentMethod}
          onChange={handleChange}
        >
          {paymentMethods.map((method) => (
            <FormControlLabel key={method.id} value={method.id} label={method?.name} control={<Radio />} />
          ))}
        </RadioGroup>
      )}
    </div>
  );
};
