import React from "react";

import { ArticleCard, SingleCard } from "components";
import { extractImageUrl, getLinkPath } from "utils";
import { ArticlesProps } from "./articles.types";
import { BLOG_POST_PAGE } from "constants/routes.constants";

import styles from "./articles.module.scss";

export const Articles: React.FC<ArticlesProps> = ({ articles }) => {
  const [
    firstArticle,
    secondArticle,
    thirdArticle,
    fourthArticle,
    fifthArticle,
    sixthArticle,
    seventhArticle,
    eighthArticle,
  ] = articles;

  return (
    <section className={styles.content}>
      {firstArticle && <ArticleCard article={firstArticle} className={styles.mainArticle} />}
      {secondArticle && (
        <SingleCard
          path={getLinkPath(BLOG_POST_PAGE.path, { articleId: secondArticle.id })}
          image={extractImageUrl(secondArticle)}
          title={secondArticle.attributes.title}
          text={secondArticle.attributes.text}
          className={styles.secondArticle}
        />
      )}
      {thirdArticle && (
        <SingleCard
          path={getLinkPath(BLOG_POST_PAGE.path, { articleId: thirdArticle.id })}
          image={extractImageUrl(thirdArticle)}
          title={thirdArticle.attributes.title}
          text={thirdArticle.attributes.text}
          className={styles.thirdArticle}
        />
      )}
      {fourthArticle && <ArticleCard reverse article={fourthArticle} className={styles.fourthArticle} />}
      {fifthArticle && <ArticleCard article={fifthArticle} className={styles.fifthArticle} />}
      {sixthArticle && (
        <SingleCard
          path={getLinkPath(BLOG_POST_PAGE.path, { articleId: sixthArticle.id })}
          image={extractImageUrl(sixthArticle)}
          title={sixthArticle.attributes.title}
          text={sixthArticle.attributes.text}
          className={styles.sixthArticle}
        />
      )}
      {seventhArticle && (
        <SingleCard
          path={getLinkPath(BLOG_POST_PAGE.path, { articleId: seventhArticle.id })}
          image={extractImageUrl(seventhArticle)}
          title={seventhArticle.attributes.title}
          text={seventhArticle.attributes.text}
          className={styles.seventhArticle}
        />
      )}
      {eighthArticle && (
        <SingleCard
          path={getLinkPath(BLOG_POST_PAGE.path, { articleId: eighthArticle.id })}
          image={extractImageUrl(eighthArticle)}
          title={eighthArticle.attributes.title}
          text={eighthArticle.attributes.text}
          className={styles.eightArticle}
        />
      )}
    </section>
  );
};
