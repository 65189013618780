import { TFunction } from "i18next";
import {
  AttachFileOutlined,
  MenuBookOutlined,
  FavoriteOutlined,
  ForumOutlined,
  MedicationOutlined,
  ContactsOutlined,
} from "@mui/icons-material";

import {
  BLOG_CATEGORY_PAGE,
  BLOG_PAGE,
  CONTACT_PAGE,
  DOCTORS_PAGE,
  FAQ_PAGE,
  FAVOURITE_PAGE,
  FORUM_THREADS_PAGE,
  LANDING_PAGE,
  MY_MENO_PAGE,
  PRODUCT_LIST_PAGE,
} from "constants/routes.constants";
import { NavItem } from "types";
import { SidebarSection } from "./sidebar.types";
import { SidebarSectionProps } from "./sidebar-section/sidebar-section.types";
import { getLinkPath } from "utils";
import { CategoryModel } from "models";

import { ReactComponent as Home } from "assets/icons/home.svg";
import { ReactComponent as Shop } from "assets/icons/shop.svg";
import { ReactComponent as Attachment } from "assets/icons/attachment.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";

const BLOG_ITEMS = (t: TFunction, blogCategories: CategoryModel[]): NavItem[] => {
  const categories = blogCategories.map((category) => {
    return { name: category.attributes.name, path: getLinkPath(BLOG_CATEGORY_PAGE.path, { categoryId: category.id }) };
  });

  return [
    { name: t("pages.blogAll"), path: BLOG_PAGE.path, icon: AttachFileOutlined },
    ...[...categories].reverse(),
    { name: t("pages.favourites"), path: FAVOURITE_PAGE.path, icon: FavoriteOutlined },
  ];
};

const LEARN_ITEMS = (t: TFunction): NavItem[] => {
  return [{ name: t("pages.faq"), path: FAQ_PAGE.path, icon: MenuBookOutlined }];
};

const SIDEBAR_SECTION_ITEMS = (t: TFunction, blogCategories: CategoryModel[]): Record<SidebarSection, NavItem[]> => ({
  home: [],
  myMeno: [],
  blog: BLOG_ITEMS(t, blogCategories),
  forum: [],
  doctors: [],
  learn: LEARN_ITEMS(t),
  shop: [],
  contact: [],
});

const iconStyle = { width: "26px", height: "26px" };

export const SIDEBAR_SECTIONS = (t: TFunction, blogCategories: CategoryModel[]): SidebarSectionProps[] => {
  const sectionItems = SIDEBAR_SECTION_ITEMS(t, blogCategories);

  return [
    {
      sectionName: t("pages.home"),
      icon: <Home style={iconStyle} />,
      items: sectionItems.home,
      path: LANDING_PAGE.path,
    },
    {
      sectionName: t("pages.myMeno"),
      icon: <Heart style={iconStyle} />,
      items: sectionItems.myMeno,
      requireAuth: true,
      path: MY_MENO_PAGE.path,
    },
    {
      sectionName: t("pages.blog"),
      icon: <Attachment style={iconStyle} />,
      items: sectionItems.blog,
      requireAuth: true,
    },
    {
      sectionName: t("pages.doctors"),
      icon: <MedicationOutlined style={iconStyle} />,
      items: sectionItems.doctors,
      requireAuth: true,
      path: DOCTORS_PAGE.path,
    },
    {
      sectionName: t("pages.forum"),
      icon: <ForumOutlined style={iconStyle} />,
      path: FORUM_THREADS_PAGE.path,
      requireAuth: true,
      items: sectionItems.forum,
    },
    {
      sectionName: t("pages.shop"),
      path: PRODUCT_LIST_PAGE.path,
      icon: <Shop style={iconStyle} />,
      items: sectionItems.shop,
    },
    {
      sectionName: t("pages.contact"),
      path: CONTACT_PAGE.path,
      icon: <ContactsOutlined style={iconStyle} />,
      items: sectionItems.contact,
    },
  ];
};
