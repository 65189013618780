import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { useWindowSize } from "@better-typed/react-window-hooks";

import { getCurrentBrowser } from "./pwa-install-info.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

import styles from "./pwa-install-info.module.scss";

const MODAL_DURATION = 8000;

export const PwaInstallInfo: React.FC = () => {
  const [width] = useWindowSize();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [browser, setBrowser] = useState<string>("chrome");

  useDidMount(() => {
    let timer: NodeJS.Timer;

    setBrowser(getCurrentBrowser());

    const wasModalShown = localStorage.getItem(STORAGE_FIELDS.pwa_install_shown) === "true";

    if (width <= 700 && !wasModalShown) {
      setShowModal(true);

      timer = setTimeout(() => {
        setShowModal(false);
        localStorage.setItem(STORAGE_FIELDS.pwa_install_shown, "true");
      }, MODAL_DURATION);
    }

    return () => clearTimeout(timer);
  });

  return (
    <div className={showModal ? styles.container : styles.hidden}>
      {browser === "chrome" && (
        <Typography variant="body1">
          Aby dodać aplikację Mameno na swoje urządzenie, wejdź w opcję przeglądarki, a następnie wybierz &quot;Dodaj do
          ekranu głównego&quot;.
        </Typography>
      )}

      {browser === "safari" && (
        <Typography variant="body1">
          Aby dodać aplikację Mameno na swoje urządzenie, wejdź w opcje udostępniania, a następnie wybierz &quot;Do
          ekranu początkowego&quot;.
        </Typography>
      )}
    </div>
  );
};
