import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";

import { FetchingErrorProps } from "./fetching-error.types";

import styles from "./fetching-error.module.css";

export const FetchingError = memo(
  ({ title, subtitle, refreshText, refreshClick, size = "big", className = "", height }: FetchingErrorProps) => {
    const { t } = useTranslation();

    const refresh = refreshText || t("common.refresh");
    const titleText = title || t("common.fetchingError");
    const subtitleText = subtitle || t("common.fetchingErrorDescription");

    return (
      <div className={`${styles.container} ${styles[size]} ${className}`} style={{ height }}>
        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600, textAlign: "center" }}>
          {titleText}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 200, textAlign: "center" }}>
          {subtitleText}
        </Typography>
        {refreshClick && <Button onClick={refreshClick}>{refresh}</Button>}
      </div>
    );
  },
);
