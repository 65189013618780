import React from "react";
import { Pagination, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DoctorsHeaderProps } from "./doctors-header.types";

import styles from "./doctors-header.module.scss";

export const DoctorsHeader: React.FC<DoctorsHeaderProps> = ({ showPagination, count, page, onChange, children }) => {
  const { t } = useTranslation();

  return (
    <header className={styles.header}>
      <div className={styles.mainRow}>
        <Typography variant="h3" fontWeight={600} className={styles.title}>
          {t("pages.doctors")}
        </Typography>
        {showPagination && <Pagination count={count} page={page} onChange={onChange} sx={{ my: 2 }} />}
      </div>
      {children}
    </header>
  );
};
