import React from "react";
import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { SidebarSectionProps } from "./sidebar-section.types";

type Props = {
  handleToggle?: () => void;
} & Pick<SidebarSectionProps, "sectionName" | "icon">;

export const SidebarSectionItem: React.FC<Props> = ({ sectionName, icon, handleToggle }) => {
  return (
    <ListItem button onClick={handleToggle} sx={{ mb: 1 }}>
      <ListItemIcon sx={{ color: "text.primary", minWidth: "34px" }}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="h6" color="text.primary" fontWeight={500} sx={{ ml: 1 }}>
            {sectionName}
          </Typography>
        }
      />
    </ListItem>
  );
};
