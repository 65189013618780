export const myMeno = {
  brain: "Mózg",
  psyche: "Psychika",
  body: "Ciało",
  beauty: "Uroda",
  sex: "Seks",
  menstruation: "Miesiączka",
  energy: "Energia",
  brainFog: "Mgła mózgowa",
  concentrationProblems: "Problemy z koncentracją",
  memoryProblems: "Problemy z pamięcią",
  insomnia: "Bezsenność",
  headache: "Bóle głowy",
  anxiety: "Niepokój",
  depression: "Depresja",
  irritation: "Irytacja",
  tearfulness: "Płaczliwość",
  anger: "Wybuchy gniewu",
  muscleAches: "Bóle mięśni/stawów",
  weightGain: "Tycie",
  flatulence: "Wzdęcia",
  waterRetention: "Zatrzymanie wody",
  itching: "Świąd skóry",
  alopecia: "Łysienie",
  brittleHair: "Łamliwe włosy",
  brittleNails: "Łamliwe paznokcie",
  skinDryness: "Suchość skóry",
  libido: "Brak libido",
  vaginalDryness: "Suchość pochwy",
  vaginitis: "Stany zapalne pochwy",
  urinaryIncontinence: "Nietrzymanie moczu",
  noMenstruation: "Nie mam od ponad roku",
  menstruationCalendar: "Kalendarzyk miesiączek",
  noPower: "Nie mam na nic siły",
  exhausted: "Jestem wyczerpana",
  tired: "Jestem zmęczona",
  ok: "Jest OK",
  weekOfYear: "{{week}} tydzień roku",
  age: "Wiek",
  height: "Wzrost",
  weight: "Waga",
  heightLabel: "Wzrost (cm)",
  weightLabel: "Waga (kg)",
  updateDetailsTitle: "Zaktualizuj swój profil",
  updateDetailsDescription: "Uzupełnij swoje informacje abyśmy mogli poznać Cię lepiej.",

  profileUpdateSuccess: "Profil został zaktualizowany",
  profileUpdateError: "Nie udało się zaktualizować twojego profilu",

  createReportSuccess: "Raport został zapisany",
  createReportError: "Nie udało się zapisać raportu",

  validation: {
    required: "To pole jest wymagane",

    weightMin: "Waga powinna być większa niż 30kg",
    weightMax: "Waga powinna być mniejsza niż 260kg",

    heightMin: "Wzrost powinien być większy niż 110cm",
    heightMax: "Wzrost powinien być mniejszy niż 220cm",

    ageMin: "Wiek powinien być większy niż 0",
    ageMax: "Wiek powinien być mniejszy niż 110",
  },

  todayTooltip: "Dzisiaj - {{date}}",
  completedReportTooltip: "Uzupełniony raport - {{date}}",
  selectedDayReport: "Wybrany dzień - {{date}}",
  menstruationCompleted: "Uzupełnione dane o krwawieniu - {{date}}: {{intensity}} - {{color}}",
};
