import { TFunction } from "i18next";

import { FaqType } from "./faq.types";

export const faq = (t: TFunction): FaqType[] => [
  { question: t("faq.question1"), answer: t("faq.answer1") },
  { question: t("faq.question2"), answer: t("faq.answer2") },
  { question: t("faq.question3"), answer: t("faq.answer3") },
  { question: t("faq.question4"), answer: t("faq.answer4") },
  { question: t("faq.question5"), answer: t("faq.answer5") },
  { question: t("faq.question6"), answer: t("faq.answer6") },
  { question: t("faq.question7"), answer: t("faq.answer7") },
  { question: t("faq.question8"), answer: t("faq.answer8") },
];
