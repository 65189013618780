import React from "react";
import { useParams } from "react-router-dom";

import { Content, FetchingError, Loader, NoContent } from "components";
import { Thread } from "./thread/thread";
import { Replies } from "./replies/replies";
import { useFirebaseFetch, useShowContent } from "hooks";
import { getSingleThread } from "firestore";

import styles from "./forum-thread-details.module.scss";

export const ForumThreadDetailsPage: React.FC = () => {
  const { threadId } = useParams<{ threadId: string }>();

  const threadData = useFirebaseFetch(() => getSingleThread(threadId || ""));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(threadData);
  const { data: thread } = threadData;

  return (
    <Content>
      {showNoContent && (
        <div className={styles.stateContainer}>
          <NoContent />
        </div>
      )}
      {showError && (
        <div className={styles.stateContainer}>
          <FetchingError />
        </div>
      )}
      {showLoader && <Loader height="100%" />}
      {showContent && thread && (
        <>
          <Thread thread={thread} />
          <Replies />
        </>
      )}
    </Content>
  );
};
