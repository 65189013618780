export const environment = {
  frontUrl: process.env.REACT_APP_FRONT_URL || "http://localhost:3000",
  serverUrl: process.env.REACT_APP_STRAPI_URL || "http://localhost:1337",
  saleorApiUrl: process.env.REACT_APP_SALEOR_API_URL,

  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.REACT_APP_AUTH_DOMAIN,
  firebaseProjectId: process.env.REACT_APP_PROJECT_ID,
  firebaseStorageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  firebaseMessagingSender: process.env.REACT_APP_MESSAGING_SENDER,
  firebaseAppId: process.env.REACT_APP_FIREBASE_ID,
  firebaseMeasurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
