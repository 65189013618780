import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";

import { Content } from "components";

import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as Mail } from "assets/icons/mail.svg";
import { ReactComponent as Facebook } from "assets/icons/facebook.svg";

import styles from "./contact.module.scss";

export const FACEBOOK_URL = "https://www.facebook.com/mamenolife/";
export const INSTAGRAM_URL = "https://www.instagram.com/mamenolife/";

export const ContactPage: React.FC = () => {
  return (
    <Content>
      <Typography variant="h3" fontWeight={600} sx={{ mb: 3 }}>
        Kontakt
      </Typography>

      <Typography>FemTechnology Group</Typography>
      <Typography variant="body2">ul. Bobrowiecka 8</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        00-728, Warszawa
      </Typography>
      <Typography>
        Email:{" "}
        <a href="mailto:kontakt@mamenolife.com" className={styles.link}>
          kontakt@mamenolife.com
        </a>
      </Typography>
      <div className={styles.row}>
        <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
          <Instagram className={styles.icon} />
        </a>
        <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
          <Facebook className={classNames(styles.icon, styles.fb)} />
        </a>
        <a href="mailto:kontakt@mamenolife.com" target="_blank" rel="noopener noreferrer">
          <Mail className={styles.icon} />
        </a>
      </div>
    </Content>
  );
};
