export const auth = {
  support: "support",
  in: "in",
  change: "change",
  login: {
    title: "Welcome back!",
    signUp: "Sign up",
    button: "Sign in",
    pageDescription: "Don't have an account?",
    register: "Register!",
    googleLogin: "Log in with Google",
    error: "Could not log in.",
    tokenError: "Could not get authorize this user.",
    forgotPassword: "Forgot password?",
    reset: "Reset!",
    google: "Log in with Google",
    verifyYourEmail: "We sent you a verification link to your inbox",
    verifyEmailDescription: "Check your inbox to activate your account",
    tryAgain: "Try again",
  },
  register: {
    title: "Create an account",
    login: "Log in!",
    alreadyHaveAccount: "Already have an account?",
    button: "Sign up",
    error: "Could not register",
    tokenError: "Could not get authorize this user.",
    verifyYourEmail: "Verify your email",
    verifyEmailDescription:
      "We have sent an activation link to your inbox. Follow the instructions in the email to activate your account.",
    goToLogin: "Go to login",
    linkSent: "Activation link has been sent",
    linkError: "Error occurred while sending the activation link",
  },

  resetPassword: {
    title: "Reset your password",
    description: "Reset email will be sent to your inbox",
    send: "Send!",
    rememberPassword: "Remember your password?",
    logIn: "Log in!",

    resetSuccess: "Email has been sent to your inbox",
    resetError: "Could not sent email to your inbox",
  },
  labels: {
    email: "Email",
    password: "Password",
    name: "Name",
    surname: "Surname",
  },
  validation: {
    nameRequired: "Name is required",
    surnameRequired: "Surname is required",
    invalidEmail: "Email is invalid",
    emailRequired: "Email is required",
    passwordRequired: "Password is required",
    passwordRules: "Password must contain A-Z letter, a-z letter, 0-9 number and symbol from @$_!%*?&",
    passwordShouldMatch: "Passwords should match",
    nameMin2: "Name should have at least 2 characters",
    passwordMin6: "Password needs to include at least 6 characters",
    passwordUpperLower: "Password needs to include both upper and lowercase alphabetic characters.",
    passwordNumeric: "Password needs to include at least one numerical character.",
    passwordSpecial: "Password needs to include at least one special character.",
  },
  error: {
    emailNotFound: "Account with this e-mail doesn't exist",
    invalidPassword: "You provided invalid password",
    emailExists: "Account with this e-mail already exists",
  },
};
