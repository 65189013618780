import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import { ServiceCheckboxProps } from "./service-checkbox.types";

import styles from "./service-checkbox.module.scss";

export const ServiceCheckbox: React.FC<ServiceCheckboxProps> = ({ checked, onChange, children }) => {
  const handleChange = (event: React.SyntheticEvent, value: boolean) => {
    onChange(value);
  };

  return (
    <Grid className={styles.row}>
      <FormControlLabel
        control={<Checkbox checked={checked} />}
        label=""
        name="acceptTerms"
        value={checked}
        onChange={handleChange}
      />
      {children}
    </Grid>
  );
};
