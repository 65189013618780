import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CheckoutDetails } from "graphql/checkout";

import styles from "./pricing.module.scss";

interface Props {
  checkout: CheckoutDetails;
}

export const Pricing: React.FC<Props> = ({ checkout }) => {
  const { t } = useTranslation();

  const { totalPrice, subtotalPrice, shippingPrice, discount } = checkout;

  return (
    <div className={styles.container}>
      <Typography>
        {t("checkout.details.subtotalPrice")}{" "}
        <b>
          {subtotalPrice?.net.amount} {subtotalPrice?.net.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.tax")}{" "}
        <b>
          {subtotalPrice?.tax.amount} {subtotalPrice?.tax.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.deliveryPrice")}{" "}
        <b>
          {shippingPrice?.gross.amount} {shippingPrice?.gross.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.discount")}{" "}
        <b>
          {discount?.amount} {discount?.currency}
        </b>
      </Typography>
      <Typography>
        {t("checkout.details.totalPrice")}{" "}
        <b>
          {totalPrice?.gross.amount} {totalPrice?.gross.currency}
        </b>
      </Typography>
    </div>
  );
};
