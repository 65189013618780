import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Products } from "./products/products";
import { Pricing } from "./pricing/pricing";
import { Address } from "./address/address";
import { Buttons, ButtonValues, CheckoutTitle } from "pages/checkout";
import { SummaryProps } from "./summary.types";
import { CART_PAGE } from "constants/routes.constants";
import { CheckoutContext } from "context";
import { PAYMENT_METHODS } from "pages/checkout/checkout.constants";

import checkoutStyles from "../checkout.module.scss";
import styles from "./summary.module.scss";

export const Summary: React.FC<SummaryProps> = ({ checkout, changeStep }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { paymentMethod } = useContext(CheckoutContext);

  // const [createOrderFromCheckout] = useMutation(CREATE_ORDER_FROM_CHECKOUT);
  // const [completeCheckout] = useMutation<CompleteCheckoutResponse>(COMPLETE_CHECKOUT);

  const disabled = !checkout.billingAddress || !checkout.shippingAddress || !checkout.shippingMethod;

  if (!checkout.billingAddress || !checkout.shippingAddress || !checkout.shippingMethod) {
    enqueueSnackbar(t("checkout.fillAllData"), { variant: "error" });

    return <Navigate to={CART_PAGE.path} />;
  }

  const handleSubmit = async () => {
    if (paymentMethod === PAYMENT_METHODS.cash_on_delivery) {
      // todo: complete checkout without payment

      // await completeCheckout({
      //   variables: {
      //     checkoutId: checkout.id,
      //   },
      // });
      changeStep("payment");
    } else {
      changeStep("payment");
    }
  };

  const goBackToDelivery = () => changeStep("delivery_payment");

  const backButton: ButtonValues = { disabled, action: goBackToDelivery };
  const confirmButton: ButtonValues = { disabled, action: handleSubmit, label: t("checkout.goToPayment") };

  return (
    <div className={checkoutStyles.container}>
      <div className={checkoutStyles.content}>
        <CheckoutTitle
          title={t("checkout.summaryTitle")}
          description={t("checkout.summaryDescription")}
          goBack={goBackToDelivery}
        />
        <div className={styles.productsContainer}>
          <Products products={checkout.lines || []} />
          <Pricing checkout={checkout} />
        </div>
        <Typography variant="h5" fontWeight={700} sx={{ mb: 2 }}>
          {t("checkout.billingDetailsTitle")}
        </Typography>
        <Address details={checkout.billingAddress} />

        <Typography variant="h5" fontWeight={700} sx={{ mt: 4, mb: 2 }}>
          {t("checkout.shippingDetailsTitle")}
        </Typography>
        <Address details={checkout.shippingAddress} />
      </div>

      <Buttons confirm={confirmButton} back={backButton} />
    </div>
  );
};
