import React from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { FetchingError, Loader, NoContent } from "components";
import { Articles } from "../articles/articles";
import { useShowContent } from "hooks";
import { RootState } from "store";
import { getArticles } from "server";
import { getPaginationParams } from "utils";

const ARTICLES_TO_DISPLAY = 8;

export const NewArticles: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useSelector((state: RootState) => state.app);

  const articlesData = useFetch(
    getArticles.setQueryParams({
      populate: "*",
      sort: "createdAt:desc",
      locale,
      ...getPaginationParams({ page: 1, pageSize: ARTICLES_TO_DISPLAY }),
    }),
    { dependencies: [locale] },
  );
  const { showLoader, showContent, showNoContent, showError } = useShowContent(articlesData);
  const { data: articles } = articlesData;

  return (
    <div>
      <Typography variant="h3" fontWeight={600} sx={{ mb: 3 }} color="text.primary">
        {t("home.whatsNew")}
      </Typography>
      {showLoader && <Loader height="500px" />}
      {showNoContent && <NoContent />}
      {showError && <FetchingError />}
      {showContent && articles && <Articles articles={articles.data} />}
    </div>
  );
};
