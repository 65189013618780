import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";

import { UserDetailsModel } from "models";
import { firestoreCollections, getCollectionRef } from "config/firebase.config";
import { deleteUserMenstruation, deleteUserConversation, deleteUserReports } from "firestore";

export const getUserDetails = (userId: string) => {
  const collectionRef = getCollectionRef<UserDetailsModel>(firestoreCollections.users);

  const documentRef = doc(collectionRef, userId);

  return getDoc(documentRef);
};

export const updateUserDetails = (userId: string, data: UserDetailsModel) => {
  const collectionRef = getCollectionRef<UserDetailsModel>(firestoreCollections.users);

  const documentRef = doc(collectionRef, userId);

  return setDoc(documentRef, data);
};

export const deleteUser = (userId: string) => {
  const collectionRef = getCollectionRef<UserDetailsModel>(firestoreCollections.users);

  const documentRef = doc(collectionRef, userId);

  return deleteDoc(documentRef);
};

export const removeUserAccount = async (userId: string, email: string) => {
  const promises = [
    deleteUserReports(userId),
    deleteUserMenstruation(userId),
    deleteUserConversation(email),
    deleteUser(userId),
  ];

  return Promise.all(promises);
};
