import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

import { AccordionProps } from "./question-accordion.types";

export const QuestionAccordion: React.FC<AccordionProps> = ({ isExpanded, toggleExpand, question, answer }) => {
  return (
    <Accordion expanded={isExpanded} onChange={toggleExpand}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body2" color="text.primary" fontWeight={600}>
          {question}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Typography variant="body2" color="text.primary">
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
