import React, { useState } from "react";
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { applyActionCode } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button, Typography } from "@mui/material";

import { Loader } from "components";
import { AuthLayout } from "../auth-layout/auth-layout";
import { AuthModeParams } from "../auth.types";
import { useQueryParams } from "hooks";
import { auth } from "config/firebase.config";
import { LOGIN_PAGE } from "constants/routes.constants";

import styles from "./verify-email.module.scss";

export const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();

  const { query } = useQueryParams<AuthModeParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);

  useDidUpdate(
    () => {
      if (!query?.oobCode) {
        navigate(LOGIN_PAGE.path);
        enqueueSnackbar("Nieprawidłowy adres linku.", { variant: "error" });
      } else {
        applyActionCode(auth, query?.oobCode)
          .then(() => {
            enqueueSnackbar("Twój adres email został potwierdzony.", { variant: "success" });
            setVerified(true);
            setLoading(false);
          })
          .catch(() => {
            enqueueSnackbar("Kod wygasł lub jest nieprawidłowy.", { variant: "error" });
            setVerified(false);
            setLoading(false);
          });
      }
    },
    [query],
    true,
  );

  const showLoader = loading;
  const showSuccess = !loading && verified;
  const showError = !loading && !verified;

  return (
    <AuthLayout variant="reset-password">
      <div className={styles.container}>
        {showLoader && <Loader />}
        {showSuccess && (
          <div>
            <Typography variant="body1">Twój adres email został potwierdzony.</Typography>
            <Typography variant="body1">Wróć do logowania, aby korzystać z aplikacji.</Typography>
            <Link to={LOGIN_PAGE.path}>
              <Button variant="contained" sx={{ color: "#fff" }}>
                Wróć do logowania.
              </Button>
            </Link>
          </div>
        )}
        {showError && (
          <div>
            <Typography variant="body1">Nie udało się potwierdzić twojego adresu email.</Typography>
            <Typography variant="body1">
              Istnieje szansa, że twój kod jest wygasł. Spróbuj wysłać kod ponownie.
            </Typography>
          </div>
        )}
      </div>
    </AuthLayout>
  );
};
