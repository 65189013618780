import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { Content, CheckoutProduct, Loader } from "components";
import { Summary } from "./summary/summary";
import { NoContent } from "./no-content/no-content";
import { CheckoutContext } from "context";
import { PRODUCT_LIST_PAGE } from "constants/routes.constants";

import styles from "./cart.module.scss";

export const CartPage: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { checkout, loading } = useContext(CheckoutContext);

  const goBackToShop = () => navigate(PRODUCT_LIST_PAGE.path);

  const products = checkout?.lines || [];

  const showNoContent = (!checkout || checkout.lines?.length === 0) && !loading;
  const showContent = !showNoContent;

  return (
    <Content>
      {loading && <Loader height="90%" />}
      {showNoContent && <NoContent />}
      {showContent && checkout && (
        <>
          <div className={styles.row}>
            <IconButton onClick={goBackToShop}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h4" fontWeight={700}>
              {t("checkout.cart.title")}
            </Typography>
          </div>
          <div className={styles.container}>
            <div>
              {products.map((product) => product && <CheckoutProduct key={product.id} product={product} editable />)}
            </div>
            <Summary checkout={checkout} />
          </div>
        </>
      )}
    </Content>
  );
};
