import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AddressDetails } from "graphql/checkout";

interface Props {
  details: AddressDetails;
}

export const Address: React.FC<Props> = ({ details }) => {
  const { t } = useTranslation();

  const { firstName, lastName, streetAddress1, streetAddress2, postalCode, city, phone, companyName, country } =
    details;

  return (
    <div>
      <Typography>{t("checkout.details.name", { firstName, lastName })}</Typography>
      <Typography variant="body1" fontSize={16}>
        {t("checkout.details.address", {
          streetAddress: `${streetAddress1} ${streetAddress2 || ""}`,
          postalCode,
          city,
        })}
      </Typography>
      <Typography>{t("checkout.details.phone", { phoneNumber: phone })}</Typography>
      <Typography>{t("checkout.details.company", { companyName })}</Typography>
      <Typography>{t("checkout.details.country", { country: country.code })}</Typography>
    </div>
  );
};
