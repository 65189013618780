import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar } from "@mui/material";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { updateProfile } from "firebase/auth";

import { useUser } from "hooks";
import { setUser } from "store";
import { auth, getStorageRef } from "config/firebase.config";

import styles from "./profile-avatar.module.scss";

const BYTE = 1_000_000;
const MAX_FILE_SIZE = 3 * BYTE;

export const ProfileAvatar: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (user && auth.currentUser) {
      const storageRef = getStorageRef(user.email || user.id);

      try {
        const uploadTask = uploadBytesResumable(storageRef, acceptedFiles[0]);

        uploadTask.on(
          "state_changed",
          () => {
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            enqueueSnackbar("Przetwarzanie pliku", { variant: "info" });
          },
          () => {
            enqueueSnackbar("Błąd podczas wrzucania pliku", { variant: "error" });
          },
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

            if (auth.currentUser) {
              updateProfile(auth.currentUser, { photoURL: downloadUrl }).then(() => {
                dispatch(setUser({ ...user, photoUrl: downloadUrl }));
              });
            }

            enqueueSnackbar("Plik został załadowany", { variant: "success" });
          },
        );
      } catch (error) {
        enqueueSnackbar("Błąd podczas wrzucania pliku", { variant: "error" });
      }
    }
  }, []);

  const onDropRejected = () => {
    enqueueSnackbar("Błąd wrzucania pliku. Sprawdź, czy twój plik jest mniejszy niż 3MB.", { variant: "error" });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onDrop,
    multiple: false,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onDropRejected,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Avatar
        src={user?.photoUrl || ""}
        sx={{ width: "120px", height: "120px", cursor: "pointer" }}
        className={styles.avatar}
      />
    </div>
  );
};
