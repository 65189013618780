import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useFetch } from "@better-typed/react-hyper-fetch";
import { IconButton, Pagination, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";

import { ArticleCard, Content, FetchingError, Loader, NoContent } from "components";
import { usePagination, useQueryParams, useShowContent } from "hooks";
import { RootState } from "store";
import { getPaginationParams } from "utils";
import { getArticles, getCategory } from "server";
import { BLOG_PAGE } from "constants/routes.constants";

import styles from "./category.module.scss";

type Params = { categoryId: string };

const PAGE_SIZE = 10;

export const CategoryPage: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { page, pageSize, onPageChange } = usePagination(PAGE_SIZE);
  const { updateQueryParams } = useQueryParams<{ page: string }>();
  const { categoryId } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();
  const { locale } = useSelector((state: RootState) => state.app);

  const articlesData = useFetch(
    getArticles.setQueryParams({
      "filters[category]": categoryId || "-",
      sort: "createdAt:desc",
      populate: "*",
      locale,
      ...getPaginationParams({ page, pageSize }),
    }),
    {
      dependencies: [categoryId, locale, page],
    },
  );
  const { showContent, showNoContent, showError, showLoader } = useShowContent(articlesData);
  const { data: articles } = articlesData;

  const { data: category, onFinished, loading } = useFetch(getCategory.setParams({ categoryId: categoryId || "" }));
  onFinished(([, error]) => {
    if (error) {
      enqueueSnackbar(t("blog.fetchCategoryError"), { variant: "error" });
      navigate(BLOG_PAGE.path);
    }
  });

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
    updateQueryParams({ page: value.toString() });
  };

  const goBack = () => navigate(-1);

  return (
    <Content>
      {Boolean(category?.data) && (
        <div className={styles.header}>
          <div className={styles.row}>
            <IconButton onClick={goBack} sx={{ width: "40px", height: "40px" }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h3" fontWeight={600}>
              {category?.data?.attributes?.name}
            </Typography>
          </div>
          {showContent && articles && (
            <Pagination count={articles.meta.pagination.pageCount} page={page} onChange={handlePaginationChange} />
          )}
        </div>
      )}
      {(showLoader || loading) && <Loader height="100%" loaderSize="50px" />}
      {showNoContent && (
        <div className={styles.emptyStateWrapper}>
          <NoContent />
        </div>
      )}
      {showError && (
        <div className={styles.emptyStateWrapper}>
          <FetchingError />
        </div>
      )}
      {showContent && articles && (
        <section className={styles.content}>
          {articles.data.map((article, index) => (
            <ArticleCard key={article.id} article={article} reverse={index % 2 === 0} />
          ))}
        </section>
      )}
    </Content>
  );
};
