import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Buttons, ButtonValues } from "pages/checkout";
import { BillingAddress } from "./billing-address/billing-address";
import { ShippingAddress } from "./shipping-address/shipping-address";
import { AddressProps, CheckoutAddressValues } from "./address.types";
import {
  CheckoutBillingResponse,
  CheckoutBillingVariables,
  CheckoutEmailVariables,
  CheckoutShippingResponse,
  CheckoutShippingVariables,
  EmailUpdateResponse,
  UPDATE_CHECKOUT_BILLING_ADDRESS,
  UPDATE_CHECKOUT_EMAIL,
  UPDATE_CHECKOUT_SHIPPING_ADDRESS,
} from "graphql/checkout";
import { RootState } from "store";
import { DEFAULT_PL_LOCALE } from "constants/app.constants";
import { addressValuesSchema, initialValues } from "./address.constants";
import { CheckoutContext } from "context";
import { CART_PAGE } from "constants/routes.constants";

import styles from "./address.module.scss";
import checkoutStyles from "../checkout.module.scss";

export const Address: React.FC<AddressProps> = ({ checkout, changeStep }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { useBillingAsShipping } = useContext(CheckoutContext);
  const { user } = useSelector((state: RootState) => state.auth);

  const [isSaving, setSaving] = useState<boolean>(false);

  const [updateCheckoutEmail] = useMutation<EmailUpdateResponse, CheckoutEmailVariables>(UPDATE_CHECKOUT_EMAIL);
  const [updateBillingAddress] = useMutation<CheckoutBillingResponse, CheckoutBillingVariables>(
    UPDATE_CHECKOUT_BILLING_ADDRESS,
  );
  const [updateShippingAddress] = useMutation<CheckoutShippingResponse, CheckoutShippingVariables>(
    UPDATE_CHECKOUT_SHIPPING_ADDRESS,
  );

  const handleSubmit = async (values: CheckoutAddressValues) => {
    if (!checkout) return;
    setSaving(true);

    const emailUpdate = updateCheckoutEmail({
      variables: { token: checkout.token, email: values.email, locale: DEFAULT_PL_LOCALE },
    });
    const billingUpdate = updateBillingAddress({
      variables: { token: checkout.token, address: values.billingAddress, locale: DEFAULT_PL_LOCALE },
    });
    const shippingAddress = useBillingAsShipping ? values.billingAddress : values.shippingAddress;
    const shippingUpdate = updateShippingAddress({
      variables: { token: checkout.token, address: shippingAddress, locale: DEFAULT_PL_LOCALE },
    });

    Promise.all([emailUpdate, billingUpdate, shippingUpdate])
      .then(([emailResponse, billingResponse, shippingResponse]) => {
        const emailUpdateErrors = emailResponse.data?.checkoutEmailUpdate?.errors || [];
        const billingUpdateErrors = billingResponse.data?.checkoutBillingAddressUpdate?.errors || [];
        const shippingUpdateErrors = shippingResponse.data?.checkoutShippingAddressUpdate?.errors || [];

        const showError =
          emailUpdateErrors.length !== 0 || billingUpdateErrors.length !== 0 || shippingUpdateErrors.length !== 0;

        if (showError) {
          const errors = [...emailUpdateErrors, ...billingUpdateErrors, ...shippingUpdateErrors];
          const message = errors.map((error) => `${error.field} - ${error.message}`);
          enqueueSnackbar(message, { variant: "error" });
          setSaving(false);
        } else {
          enqueueSnackbar(t("checkout.actions.addressDataSuccess"), { variant: "success" });
          changeStep("delivery_payment");
          setSaving(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("checkout.actions.addressDataError"), { variant: "error" });
        setSaving(false);
      });
  };

  const navigateToCart = () => navigate(CART_PAGE.path);

  const buttonValues: ButtonValues = {
    disabled: isSaving,
    loading: isSaving,
  };

  return (
    <Formik
      initialValues={initialValues({ checkout, user })}
      onSubmit={handleSubmit}
      validationSchema={addressValuesSchema(t)}
    >
      <Form className={styles.form}>
        <div className={checkoutStyles.container}>
          <div className={checkoutStyles.content}>
            <BillingAddress />
            <ShippingAddress checkout={checkout} />
          </div>
          <Buttons confirm={buttonValues} back={{ ...buttonValues, loading: false, action: navigateToCart }} />
        </div>
      </Form>
    </Formik>
  );
};
