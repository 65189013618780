import { builder } from "server/builder";
import { ArticleModel } from "models";
import { StrapiData } from "types";

export const getArticles = builder.createCommand<StrapiData<ArticleModel[]>>()({
  method: "GET",
  endpoint: "/api/articles",
});

export const getArticle = builder.createCommand<StrapiData<ArticleModel>>()({
  method: "GET",
  endpoint: "/api/articles/:articleId",
});
