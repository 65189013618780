import React from "react";
import ReactDOM from "react-dom";

import { Providers } from "components";
import { App } from "app";
import { reportWebVitals } from "reportWebVitals";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
