import React from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SelectTypes } from "./select.types";

import styles from "./select.module.css";

export const Select = ({ name, options, showClear, ...rest }: SelectTypes) => {
  const { t } = useTranslation();

  const itemsList = options.map(({ name: optionName, value }) => (
    <MenuItem key={value} value={value}>
      <Typography variant="body2">{optionName}</Typography>
    </MenuItem>
  ));

  return (
    <TextField {...rest} className={styles.textInput} id={name} select>
      {showClear && (
        <MenuItem sx={{ color: "warning.main" }} value="">
          <Typography variant="body2" color="text.secondary">
            {t("common.clear")}
          </Typography>
        </MenuItem>
      )}

      {itemsList}
    </TextField>
  );
};
