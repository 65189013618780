import React from "react";
import { Typography, Avatar } from "@mui/material";
import { useSelector } from "react-redux";

import { RootState } from "store";

import { ReactComponent as Logo } from "assets/icons/mameno-letter.svg";

import styles from "./message.module.scss";

interface Props {
  message: string | number;
  sender: "user" | "bot";
}

export const Message: React.FC<Props> = ({ message, sender }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  if (sender === "user") {
    return (
      <div className={styles.containerReverse}>
        <div className={styles.messageWrapper}>
          <Typography>{message}</Typography>
        </div>
        <Avatar className={styles.avatar} src={user?.photoUrl || ""} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.messageWrapper}>
        <Typography>{message}</Typography>
      </div>
    </div>
  );
};
