import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

import { TextInputProps } from "./form-text-field.types";

import styles from "./form-text-field.module.scss";

export const FormTextField = ({ name, type = "text", ...rest }: TextInputProps) => {
  const [field, { touched, error }] = useField(name);

  return (
    <TextField
      id={name}
      {...field}
      error={touched && Boolean(error)}
      helperText={touched && error}
      inputProps={{ maxLength: 100 }}
      FormHelperTextProps={{ className: styles.helper }}
      type={type}
      {...rest}
    />
  );
};
